import React, { useEffect, useRef, useState } from 'react';
import LandingPage from './landing/LandingPage';
import PBLearning from './pbLearning/PBLearning';
import './styleHomePage.css';
import Feature1 from './features/feature1/Feature1';
import Feature2 from './features/feature2/Feature2';
import Feature3 from './features/feature3/Feature3';
import Feature4 from './features/feature4/Feature4';
import Feature5 from './features/feature5/Feature5';
import Feature6 from './features/feature6/Feature6';
import Feature7 from './features/feature7/Feature7';
import Feature8 from './features/feature8/Feature8';
import Testimonial from './testimonial/Testimonial';
import FAQ from './FAQ/FAQ';
import AppDownload from './appDownload/AppDownload';
import { useDispatch, useSelector } from 'react-redux';
import { setHomePagePos, setIsActiveSticky, setLocation, setScrollPos } from '../../incubStore/actions';
import { useLocation } from 'react-router-dom';

const HomePage = () => {

    const scrollableDivRef = useRef(null);
    const location = useSelector((state) => state.data.location)
    const dispatch = useDispatch();
    const isStickyScroll = useSelector((state) => state.data.isStickyScroll);
    const homePagePos = useSelector((state) => state.data.homePagePos);
    useEffect(() => {
        dispatch(setLocation('/'))
    }, [location])



    useEffect(() => {

        scrollableDivRef.current.scrollTop = window.sessionStorage.getItem("homePagePos")

    }, [scrollableDivRef])



    const handleScroll = () => {
        const scrollTop = scrollableDivRef.current.scrollTop;
        dispatch(setScrollPos(scrollTop));
        dispatch(setHomePagePos(scrollableDivRef.current.scrollTop))
        window.sessionStorage.setItem('homePagePos', scrollableDivRef.current.scrollTop)
        console.log((scrollableDivRef.current.getBoundingClientRect().top - isStickyScroll.current.getBoundingClientRect().top) > 0)
        dispatch(setIsActiveSticky(scrollableDivRef.current.getBoundingClientRect().top - isStickyScroll.current.getBoundingClientRect().top > 0))
    };

    const locationS = useLocation();
    const scrollPositions = {};

    useEffect(() => {
        if (scrollPositions[locationS.pathname]) {
            scrollableDivRef.scrollTo(0, scrollPositions[locationS.pathname]);
        }
        return () => {
            scrollPositions[locationS.pathname] = scrollableDivRef.scrollY;
        };
    }, [locationS]);
    useEffect(() => {

    }, [])
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.setItem('is_refreshed', true);
        };

        const handleLoad = () => {
            if (sessionStorage.getItem('is_refreshed')) {
                console.log('Page was refreshed');
                window.sessionStorage.setItem('homePagePos', 0);
                dispatch(setHomePagePos(0))
                sessionStorage.removeItem('is_refreshed')
                scrollableDivRef.current.scrollTop = 0
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('load', handleLoad);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return (
        <div className='INCHomepage' ref={scrollableDivRef} onScroll={handleScroll}>
            <LandingPage />
            <PBLearning />
            <Feature1 />
            <Feature2 />
            <Feature3 />
            <Feature4 />
            <Feature5 />
            <Feature6 />
            <Feature7 />
            <Feature8 />
            <Testimonial />
            {/* <Events /> */}
            <FAQ />
            <AppDownload />
        </div>
    );
};

export default HomePage;
