// actionTypes.js
export const SCROLLPOS = 'SCROLLPOS';
export const ISFORM = 'ISFORM';
export const ISNAVCOLOR = 'ISNAVCOLOR';
export const LOCATION = 'LOCATION';
export const ISCOURSENAVCOLOR = 'ISCOURSENAVCOLOR'
export const SETPROJECTDATA = 'SETPROJECTDATA';
export const SETISCOURSEENQUIRY = 'SETISCOURSEENQUIRY';
export const SETOBSERVERELEMENT = 'SETOBSERVERELEMENT';
export const SETFLUTTERSCROLLTO = "SETFLUTTERSCROLLTO";
export const SETISSTICKYSCROLL = 'SETISSTICKYSCROLL';
export const SETISACTIVESTICKY = 'SETISACTIVESTICKY';
export const SETHOMEPAGEPOS = 'SETHOMEPAGEPOS';
export const SETFLUTTERSCROLLDIV = 'SETFLUTTERSCROLLDIV';
export const SETCOURSESCROLLPOS = 'SETCOURSESCROLLPOS';
export const SETMAINCOURSESCROLL = 'SETMAINCOURSESCROLL'



