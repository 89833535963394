import './App.css';
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import RoutingComponent from './routingComponent';
import Navbar from './components/layouts/Navbar';
import { useEffect, useState } from 'react';
import LoadingNavbar from './components/layouts/LoadingNavbar';
import MobileNavbar from './components/layouts/MobileNavber';
import "./components/globals/variables.css";
import { useSelector } from 'react-redux';
import FlutterPageNavbar from './components/layouts/courseNavbar/FlutterPageNavbar';
import GetInTouch from './components/layouts/GetInTouch';
import NoDetailNavbar from './components/layouts/NoDetailNavbar';

function App() {
  // Constants
  const HOME_PATH = "/";
  const FLUTTER_ROADMAP_PATH = "/programs/flutter/roadmap";
  const PLACEMENT_PATH = '/placement';
  const ABOUT_PATH = '/about';
  const MOBILE_VIEW_WIDTH = 836;
  const NAVBAR_DELAY = 3000;
  const homePagePos = useSelector((state) => state.data.homePagePos);

  // State to control the visibility of the Navbar and LoadingNavbar
  const [showNavbar, setShowNavbar] = useState(false);
  const location = useSelector((state) => state.data.location);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isForm = useSelector((state) => state.data.isForm);
  // Effect to handle the delayed display of the Navbar
  useEffect(() => {
    if (location === HOME_PATH) {
      setShowNavbar(false);
      const timer = setTimeout(() => {
        setShowNavbar(true);
      }, NAVBAR_DELAY);

      return () => clearTimeout(timer);
    }
  }, [location]);

  // Effect to handle window resize events and update the windowWidth state
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderNavbar = () => {
    if (windowWidth < MOBILE_VIEW_WIDTH && location !== "/programs/flutter") {
      if (location === "/enquiry") {
        return <NoDetailNavbar />
      }
      return <MobileNavbar />;
    } else {
      if (location === HOME_PATH || PLACEMENT_PATH === location) {
        return showNavbar ? <Navbar /> : (location === HOME_PATH && homePagePos < 30 ? <LoadingNavbar /> : <Navbar />);
      } else if (location === FLUTTER_ROADMAP_PATH) {
        return null;
      } if (location === ABOUT_PATH) {
        return <Navbar />;
      } else if (location === '/enquiry') {
        return <NoDetailNavbar />
      } else {
        return <FlutterPageNavbar />;
      }
    }
  };
  useEffect(() => {
    if (isForm) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isForm]);



  return (
    <div className="App" style={{ userSelect: 'none' }}>
      <BrowserRouter>
        {renderNavbar()}
        {isForm && <GetInTouch />}
        <RoutingComponent />
      </BrowserRouter>
    </div>
  );
}

export default App;
