import React, { useEffect, useRef, useState } from 'react'
import "./styleTestimonial.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import { useDispatch } from 'react-redux';
import { setIsStickyScroll } from '../../../incubStore/actions';
function Testimonial() {
    const refTestimonialDiv = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setIsStickyScroll(refTestimonialDiv))
    }, [refTestimonialDiv.current])
    const testmonialData = [
        {
            name: "Omkar Machale",
            role: "Flutter Developer at PRYSOM",
            testimonialHead: "I would like to heartfelt thanks to Shashi sir and the Core2web family for their guidance and for supporting me.",
            testimonial: "Sir taught concepts very deeply with real-time examples and also made me write code from scratch. This helped me in the interview because the interviewer asks to write code on notepad.",
            testimonialFoot: "Once again thank You so much, Sir and Core2web family.",

        },
        {
            name: "Rugved Sachin Belkundkar",
            role: "Flutter Developer Intern at DAccess IT Infra Pvt Ltd",
            testimonialHead: "I want to sincerely thank Shashi Sir, Akshay Sir, Sachin Sir, and the entire Core2Web team for their consistent help and support. Your guidance has played a crucial role in my journey, and I truly appreciate it.",
            testimonial: "This opportunity came my way through the Flutter batch. Coming from a non-IT background, I was unfamiliar with even the basics of coding. However, Shashi Sir's friendly nature and his methodical approach to teaching, starting from the basics, proved very helpful. The practice sessions conducted after class had an impact on improving my skills and gaining hands-on experience. Moreover, sir gave me the confidence I needed to pursue this path.",
            testimonialFoot: "I am truly grateful to be a part of the Core2Web family, and I look forward to continuing this journey of learning and growth with your invaluable guidance.",

        },
        {
            name: "Khushbu Agrawal",
            role: "Software Developer at PTC Inc.",
            testimonialHead: "I would like to express my deepest gratitude to Shashi Sir and the Core2Web family for their exceptional teachings and guidance.",
            testimonial: "The way Sir explained each concept was the most effective approach to programming, from basics to advanced levels.",
            testimonialFoot: "All thanks to Shashi Sir and the Core2Web Family.",

        },
        {
            name: "Pranav Suresh Dumbre",
            role: "Software Developer at PTC Inc.",
            testimonialHead: "Huge thanks to Shashi sir & Core2web Family.",
            testimonial: "Shashi sir’s ability to map real-time examples with coding concepts really helped me. he instilled in us away to learn things down to the core. I’m truly grateful for all the support you have provided.",
            testimonialFoot: "“Once again thank you so much, Sir !”",

        }, {
            name: "Priyank Bhikaji Naik",
            role: "Software Developer at Open Infotech",
            testimonialHead: "Dear Shashi sir and Core2Web family,",
            testimonial: "Your impact on my programming journey is immeasurable. Shashi sir, you are truly the G.O.A.T of the programming world, and your leadership has been a beacon of inspiration. You're not just mentors; you're the pillars of wisdom that have sculpted my path to success. With deep gratitude, I thank you for shaping the first chapter of my life. Your guidance has been instrumental, and I'm forever indebted to your brilliance.",
            testimonialFoot: "Thank you once again for everything.",

        },
        {
            name: "Tejas Rajendra Thonge",
            role: "Software Developer at Farmicon",
            testimonialHead: "",
            testimonial: "Thanks to Shashi Sir's exceptional mentorship in Core2web, I've landed a placement opportunity. His expertise and guidance have been instrumental in shaping my skills and paving the way for this achievement. Grateful for his invaluable support throughout the journey.",
            testimonialFoot: "",

        },
    ];
    const [testimonial, setTestimonial] = useState({
        name: "Omkar Machale",
        role: "Flutter Developer at PRYSOM",
        testimonialHead: "I would like to heartfelt thanks to Shashi sir and the Core2web family for their guidance and for supporting me.",
        testimonial: "Sir taught concepts very deeply with real-time examples and also made me write code from scratch. This helped me in the interview because the interviewer asks to write code on notepad.",
        testimonialFoot: "Once again thank You so much, Sir and Core2web family.",

    })
    useEffect(() => {
        setTestimonial({
            name: "Omkar Machale",
            role: "Flutter Developer at PRYSOM",
            testimonialHead: "I would like to heartfelt thanks to Shashi sir and the Core2web family for their guidance and for supporting me.",
            testimonial: "Sir taught concepts very deeply with real-time examples and also made me write code from scratch. This helped me in the interview because the interviewer asks to write code on notepad.",
            testimonialFoot: "Once again thank You so much, Sir and Core2web family.",

        })
    }, [])
    const [fadeIn, setFadeIn] = useState(true);

    const handleTestimonialChange = (e) => {
        setFadeIn(false); // Trigger fade-out animation
        setTimeout(() => {
            setTestimonial(testmonialData[e.realIndex])
            setFadeIn(true); // Trigger fade-in animation
        }, 800);
    };
    return (
        <div className='INCtestimonials' ref={refTestimonialDiv}>
            <div className='INCtestimonials_container'>
                <div className='testimonial_content_container'>
                    <div className='INCBackgroundTestimonials_holder'>
                        <svg className='INCTestimonial_background_svg1' width="114" height="94" viewBox="0 0 114 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="20.1781" cy="3.66656" rx="2.5" ry="2.62469" transform="rotate(14.4844 20.1781 3.66656)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="9.22695" cy="46.0611" rx="2.5" ry="2.62469" transform="rotate(14.4844 9.22695 46.0611)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="56.3793" cy="13.0181" rx="2.5" ry="2.62469" transform="rotate(14.4844 56.3793 13.0181)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="45.4281" cy="55.4127" rx="2.5" ry="2.62469" transform="rotate(14.4844 45.4281 55.4127)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="92.5824" cy="22.3697" rx="2.5" ry="2.62469" transform="rotate(14.4844 92.5824 22.3697)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="81.6312" cy="64.7642" rx="2.5" ry="2.62469" transform="rotate(14.4844 81.6312 64.7642)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="14.7035" cy="24.8619" rx="2.5" ry="2.62469" transform="rotate(14.4844 14.7035 24.8619)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="3.75234" cy="67.2564" rx="2.5" ry="2.62469" transform="rotate(14.4844 3.75234 67.2564)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="50.9047" cy="34.2134" rx="2.5" ry="2.62469" transform="rotate(14.4844 50.9047 34.2134)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="39.9535" cy="76.608" rx="2.5" ry="2.62469" transform="rotate(14.4844 39.9535 76.608)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="87.1059" cy="43.565" rx="2.5" ry="2.62469" transform="rotate(14.4844 87.1059 43.565)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="76.1547" cy="85.9595" rx="2.5" ry="2.62469" transform="rotate(14.4844 76.1547 85.9595)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="38.2797" cy="8.34234" rx="2.5" ry="2.62469" transform="rotate(14.4844 38.2797 8.34234)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="27.3285" cy="50.7369" rx="2.5" ry="2.62469" transform="rotate(14.4844 27.3285 50.7369)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="74.4809" cy="17.6939" rx="2.5" ry="2.62469" transform="rotate(14.4844 74.4809 17.6939)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="63.5297" cy="60.0884" rx="2.5" ry="2.62469" transform="rotate(14.4844 63.5297 60.0884)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="110.682" cy="27.0455" rx="2.5" ry="2.62469" transform="rotate(14.4844 110.682 27.0455)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="99.7309" cy="69.44" rx="2.5" ry="2.62469" transform="rotate(14.4844 99.7309 69.44)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="32.8031" cy="29.5377" rx="2.5" ry="2.62469" transform="rotate(14.4844 32.8031 29.5377)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="21.852" cy="71.9322" rx="2.5" ry="2.62469" transform="rotate(14.4844 21.852 71.9322)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="69.0062" cy="38.8892" rx="2.5" ry="2.62469" transform="rotate(14.4844 69.0062 38.8892)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="58.0551" cy="81.2837" rx="2.5" ry="2.62469" transform="rotate(14.4844 58.0551 81.2837)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="105.207" cy="48.2408" rx="2.5" ry="2.62469" transform="rotate(14.4844 105.207 48.2408)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="94.2562" cy="90.6353" rx="2.5" ry="2.62469" transform="rotate(14.4844 94.2562 90.6353)" fill="white" fillOpacity="0.1" />
                        </svg>
                        <svg className='INCTestimonial_background_svg2' width="114" height="94" viewBox="0 0 114 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="19.5023" cy="3.66656" rx="2.5" ry="2.62469" transform="rotate(14.4844 19.5023 3.66656)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="8.55117" cy="46.0608" rx="2.5" ry="2.62469" transform="rotate(14.4844 8.55117 46.0608)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="55.7035" cy="13.0181" rx="2.5" ry="2.62469" transform="rotate(14.4844 55.7035 13.0181)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="44.7523" cy="55.4124" rx="2.5" ry="2.62469" transform="rotate(14.4844 44.7523 55.4124)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="91.9066" cy="22.3697" rx="2.5" ry="2.62469" transform="rotate(14.4844 91.9066 22.3697)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="80.9555" cy="64.764" rx="2.5" ry="2.62469" transform="rotate(14.4844 80.9555 64.764)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="14.0277" cy="24.8619" rx="2.5" ry="2.62469" transform="rotate(14.4844 14.0277 24.8619)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="3.07656" cy="67.2562" rx="2.5" ry="2.62469" transform="rotate(14.4844 3.07656 67.2562)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="50.2289" cy="34.2134" rx="2.5" ry="2.62469" transform="rotate(14.4844 50.2289 34.2134)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="39.2777" cy="76.6077" rx="2.5" ry="2.62469" transform="rotate(14.4844 39.2777 76.6077)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="86.4301" cy="43.5648" rx="2.5" ry="2.62469" transform="rotate(14.4844 86.4301 43.5648)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="75.4789" cy="85.9593" rx="2.5" ry="2.62469" transform="rotate(14.4844 75.4789 85.9593)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="37.6039" cy="8.34234" rx="2.5" ry="2.62469" transform="rotate(14.4844 37.6039 8.34234)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="26.6527" cy="50.7366" rx="2.5" ry="2.62469" transform="rotate(14.4844 26.6527 50.7366)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="73.8051" cy="17.6939" rx="2.5" ry="2.62469" transform="rotate(14.4844 73.8051 17.6939)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="62.8539" cy="60.0882" rx="2.5" ry="2.62469" transform="rotate(14.4844 62.8539 60.0882)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="110.006" cy="27.0455" rx="2.5" ry="2.62469" transform="rotate(14.4844 110.006 27.0455)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="99.0551" cy="69.4398" rx="2.5" ry="2.62469" transform="rotate(14.4844 99.0551 69.4398)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="32.1273" cy="29.5377" rx="2.5" ry="2.62469" transform="rotate(14.4844 32.1273 29.5377)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="21.1762" cy="71.9319" rx="2.5" ry="2.62469" transform="rotate(14.4844 21.1762 71.9319)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="68.3305" cy="38.889" rx="2.5" ry="2.62469" transform="rotate(14.4844 68.3305 38.889)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="57.3793" cy="81.2835" rx="2.5" ry="2.62469" transform="rotate(14.4844 57.3793 81.2835)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="104.532" cy="48.2405" rx="2.5" ry="2.62469" transform="rotate(14.4844 104.532 48.2405)" fill="white" fillOpacity="0.1" />
                            <ellipse cx="93.5805" cy="90.6351" rx="2.5" ry="2.62469" transform="rotate(14.4844 93.5805 90.6351)" fill="white" fillOpacity="0.1" />
                        </svg>

                    </div>
                    <div className='INCTestimonials_content_holder'>
                        <div className='INCTestiminials_heading'>
                            Alumni <span>Success Stories</span> Showcasing Their <br /> Achievements
                        </div>
                        <div className='INCTestimonial_text_holder' style={{ width: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "100%" : null }}>
                            <div className='INCTestimonial_quote_svg'>
                                <svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.5 34.2C22 34.2 20.25 31.2 20.25 25.2C20.25 19.2 21.8 14 24.9 9.6C27.5 5.9 29.95 3.1 32.25 1.2L33.6 0C35.2 0 36 0.349998 36 1.05C36 1.65 35.55 2.4 34.65 3.3C30.95 8.7 29.1 12.55 29.1 14.85C29.1 17.05 30.35 19 32.85 20.7C35.45 22.3 36.75 23.95 36.75 25.65C36.75 27.25 35.5 29.1 33 31.2C30.6 33.2 28.1 34.2 25.5 34.2ZM5.85 34.2C3.75 34.2 2.25 33.5 1.35 32.1C0.45 30.7 0 28 0 24C0 19.9 1.1 15.85 3.3 11.85C5.6 7.75 7.85 4.75 10.05 2.85L13.35 0C14.95 0 15.75 0.349998 15.75 1.05C15.75 1.65 15.3 2.4 14.4 3.3C10.7 8.7 8.85 12.55 8.85 14.85C8.85 17.05 10.1 19 12.6 20.7C15.2 22.3 16.5 23.95 16.5 25.65C16.5 27.25 15.25 29.1 12.75 31.2C10.35 33.2 8.05 34.2 5.85 34.2Z" fill="#00C2FF" />
                                    <path d="M25.5 34.2C22 34.2 20.25 31.2 20.25 25.2C20.25 19.2 21.8 14 24.9 9.6C27.5 5.9 29.95 3.1 32.25 1.2L33.6 0C35.2 0 36 0.349998 36 1.05C36 1.65 35.55 2.4 34.65 3.3C30.95 8.7 29.1 12.55 29.1 14.85C29.1 17.05 30.35 19 32.85 20.7C35.45 22.3 36.75 23.95 36.75 25.65C36.75 27.25 35.5 29.1 33 31.2C30.6 33.2 28.1 34.2 25.5 34.2ZM5.85 34.2C3.75 34.2 2.25 33.5 1.35 32.1C0.45 30.7 0 28 0 24C0 19.9 1.1 15.85 3.3 11.85C5.6 7.75 7.85 4.75 10.05 2.85L13.35 0C14.95 0 15.75 0.349998 15.75 1.05C15.75 1.65 15.3 2.4 14.4 3.3C10.7 8.7 8.85 12.55 8.85 14.85C8.85 17.05 10.1 19 12.6 20.7C15.2 22.3 16.5 23.95 16.5 25.65C16.5 27.25 15.25 29.1 12.75 31.2C10.35 33.2 8.05 34.2 5.85 34.2Z" fill="url(#paint0_linear_2833_8013)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_2833_8013" x1="18.3209" y1="14.0538" x2="18.3209" y2="49.9092" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#EE4661" />
                                            <stop offset="1" stopColor="#92161A" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                            <div className={`INCtestimonial_text ${fadeIn ? 'fade-in' : 'fade-out'} ${testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "wide" : (testimonial && testimonial.name === "Priyank Bhikaji Naik" ? "mini-wide" : null)}`} style={{ width: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "85%" : null, fontSize: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "18px" : null, gap: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "10px" : null }}>
                                <span style={{ fontSize: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "20px" : null, lineHeight: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "30px" : null }}>{testimonial ? testimonial.testimonialHead : ""}</span><br />
                                {testimonial ? testimonial.testimonial : null}<br />
                                <span style={{ fontSize: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "20px" : null, lineHeight: testimonial && testimonial.name === "Rugved Sachin Belkundkar" ? "30px" : null }}>{testimonial ? testimonial.testimonialFoot : ""}</span>
                            </div>
                            <div className='INCTestimonial_quote_svg'>
                                <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.0664 0.800001C15.5664 0.800001 17.3164 3.8 17.3164 9.8C17.3164 15.8 15.7664 21 12.6664 25.4C10.0664 29.1 7.6164 31.9 5.3164 33.8L3.96641 35C2.36641 35 1.56641 34.65 1.56641 33.95C1.56641 33.35 2.01641 32.6 2.91641 31.7C6.61641 26.3 8.46641 22.45 8.46641 20.15C8.46641 17.95 7.21641 16 4.71641 14.3C2.11641 12.7 0.816405 11.05 0.816405 9.35C0.816405 7.75 2.0664 5.9 4.56641 3.8C6.96641 1.8 9.46641 0.800001 12.0664 0.800001ZM31.7164 0.800003C33.8164 0.800003 35.3164 1.5 36.2164 2.9C37.1164 4.3 37.5664 7 37.5664 11C37.5664 15.1 36.4664 19.15 34.2664 23.15C31.9664 27.25 29.7164 30.25 27.5164 32.15L24.2164 35C22.6164 35 21.8164 34.65 21.8164 33.95C21.8164 33.35 22.2664 32.6 23.1664 31.7C26.8664 26.3 28.7164 22.45 28.7164 20.15C28.7164 17.95 27.4664 16 24.9664 14.3C22.3664 12.7 21.0664 11.05 21.0664 9.35C21.0664 7.75 22.3164 5.9 24.8164 3.8C27.2164 1.8 29.5164 0.800002 31.7164 0.800003Z" fill="#00C2FF" />
                                    <path d="M12.0664 0.800001C15.5664 0.800001 17.3164 3.8 17.3164 9.8C17.3164 15.8 15.7664 21 12.6664 25.4C10.0664 29.1 7.6164 31.9 5.3164 33.8L3.96641 35C2.36641 35 1.56641 34.65 1.56641 33.95C1.56641 33.35 2.01641 32.6 2.91641 31.7C6.61641 26.3 8.46641 22.45 8.46641 20.15C8.46641 17.95 7.21641 16 4.71641 14.3C2.11641 12.7 0.816405 11.05 0.816405 9.35C0.816405 7.75 2.0664 5.9 4.56641 3.8C6.96641 1.8 9.46641 0.800001 12.0664 0.800001ZM31.7164 0.800003C33.8164 0.800003 35.3164 1.5 36.2164 2.9C37.1164 4.3 37.5664 7 37.5664 11C37.5664 15.1 36.4664 19.15 34.2664 23.15C31.9664 27.25 29.7164 30.25 27.5164 32.15L24.2164 35C22.6164 35 21.8164 34.65 21.8164 33.95C21.8164 33.35 22.2664 32.6 23.1664 31.7C26.8664 26.3 28.7164 22.45 28.7164 20.15C28.7164 17.95 27.4664 16 24.9664 14.3C22.3664 12.7 21.0664 11.05 21.0664 9.35C21.0664 7.75 22.3164 5.9 24.8164 3.8C27.2164 1.8 29.5164 0.800002 31.7164 0.800003Z" fill="url(#paint0_linear_2833_8014)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_2833_8014" x1="19.2455" y1="20.9462" x2="19.2455" y2="-14.9092" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#EE4661" />
                                            <stop offset="1" stopColor="#92161A" />
                                        </linearGradient>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                        <div className='INCTestimonials_images_info_holder'>
                            <div className='INCtestimonials_images_holder'>
                                <svg className='INCtestimonials_images_holder_svg' width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M102.5 52C102.5 79.8904 79.8904 102.5 52 102.5C24.1096 102.5 1.5 79.8904 1.5 52C1.5 24.1096 24.1096 1.5 52 1.5C79.8904 1.5 102.5 24.1096 102.5 52Z" stroke="#00C2FF" strokeWidth="3" />
                                </svg>

                                <Swiper
                                    modules={[Autoplay]}
                                    spaceBetween={50}
                                    slidesPerView={5}
                                    onSlideChange={(e) => {
                                        handleTestimonialChange(e)
                                    }}
                                    loop={true}
                                    speed={1500}
                                    autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                                    centeredSlides={true}
                                ><SwiperSlide>
                                        <div className='INCtestimonials_images'>
                                            <div className='INCtestimonials_images'>
                                                <img src='/assets/images/testimonials/om.png' alt='/' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='INCtestimonials_images'>
                                            <div className='INCtestimonials_images'>
                                                <img src='/assets/images/testimonials/Mask grouprugved.png' alt='/' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='INCtestimonials_images'>
                                            <div className='INCtestimonials_images'>
                                                <img src='/assets/images/testimonials/khooshbu.png' alt='/' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='INCtestimonials_images'>
                                            <div className='INCtestimonials_images'>
                                                <img src='/assets/images/testimonials/pranav png.png' alt='/' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='INCtestimonials_images'>
                                            <div className='INCtestimonials_images'>
                                                <img src='/assets/images/testimonials/priyank.png' alt='/' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='INCtestimonials_images'>
                                            <div className='INCtestimonials_images'>
                                                <img src='/assets/images/testimonials/tejas.png' alt='/' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div className={`INCTestimonials_info_holder ${fadeIn ? 'fade-in' : 'fade-out'}`}>
                                <div className='INCTestimonials_name'>{testimonial ? testimonial.name : ""}</div>
                                <div className='INCTestimonials_role'>{testimonial ? testimonial.role : ""}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial
