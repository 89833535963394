import React, { useEffect, useRef, useState } from 'react';
import "./styleFlutterProjects.css";
import { useDispatch, useSelector } from 'react-redux';
import { setObserverElement, setProjectData } from '../../../incubStore/actions';
import ProjectDetail from './ProjectDetail';

function FlutterProjects() {
    const [isLoadMore, setIsLoadMore] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); //variable for getting width of window
    const projectData = useSelector((state) => state.data.projectData)
    const projectsInfoData = [{
        pName: "Food delivery App",
        pDescriptrion: `
Food delivery App is a cutting-edge food delivery application developed using Flutter, incorporating advanced routing and state management capabilities with the GetX library. 
	The app will contain the real-time data fetched from the API’s which will allow us to perform various activities throughout the application such as adding the product to the cart , placing the order, tracking the order, adding products to favourites  etc. `,
        shortDesc: "Food delivery App is a cutting-edge food delivery application developed...  ",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects1_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/swiggy logo.png",
        pImg2: "/assets/images/flutter/flutterprojects/zomato logo.png"
    },
    {
        pName: "Chat App",
        pDescriptrion: `In this project, you'll develop a Chat Application using Flutter, enabling users to interact with each other in real-time. The core feature of this app is Firebase integration, which will be used to store and manage chat messages.
You will learn how to set up and configure Firebase for your Flutter app, including authentication and real-time database services. This project will guide you through the process of enabling users to send and receive messages, ensuring seamless and instant communication.
By the end of this project, you'll have a solid understanding of Firebase integration in Flutter, as well as experience in building a real-time chat application. This project will showcase your ability to create interactive and data-driven mobile applications.`,
        shortDesc: "In this project, you'll develop a Chat Application using Flutter...",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects2_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/wpLogomain.png",
        pImg2: "/assets/images/flutter/flutterprojects/messenger logo.png"
    },
    {
        pName: "Expense Manager App",
        pDescriptrion: "In this project, you'll create an Expense Manager App using Flutter. You'll learn advanced state management with the Provider library and implement navigation between screens. The app will interact with real APIs to perform CRUD operations on expense data, allowing users to add, update, and remove expenses. By the end of this project, you'll have a robust understanding of state management, navigation, and API integration in Flutter, resulting in a fully functional and user-friendly expense tracking application.",
        shortDesc: "In this project, you'll create an Expense Manager App using Flutter...",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects3_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/khatabook logo.png",
        pImg2: "/assets/images/flutter/flutterprojects/rupee logo.png"
    },
    {
        pName: "Task Manager app",
        pDescriptrion: "Task Manager is a to-do app built with Flutter and powered by SQLite database. It offers CRUD operations for tasks, ensuring users can Create, Read, Update, and Delete tasks seamlessly. User authentication is handled securely, with hashed and salted credentials stored in the SQLite database. The UI is developed with Flutter widgets following Material Design guidelines, providing a responsive and intuitive experience across platforms",
        shortDesc: "Task Manager is a to-do app built with Flutter and powered by SQLite...",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects4_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/todo logo.png",
        pImg2: "/assets/images/flutter/flutterprojects/todo logo2.png"
    },
    {
        pName: "E-Commerce App",
        pDescriptrion: `In this project, you'll build a comprehensive E-Commerce App using Flutter, with a focus on state management using the GetX library. This app will enable users to browse products, add items to a wishlist, and place orders. Additionally, users will be able to sign up and log in using Google authentication.
You will learn how to implement GetX for efficient state management, making your app more scalable and easier to maintain. The project will also cover integrating real APIs to handle product data and user interactions, ensuring a seamless shopping experience.
By the end of this project, you'll have a solid grasp of GetX state management, Google authentication, and API integration, culminating in a fully functional E-Commerce App that demonstrates your advanced Flutter development skills.`,
        shortDesc: "In this project, you'll build a comprehensive E-Commerce App using Flutter...",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects5_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/amazon logo.png",
        pImg2: "/assets/images/flutter/flutterprojects/myntra logo.png"
    },
    {
        pName: "Coffee App",
        pDescriptrion: `In this project, you'll craft a sleek and modern Coffee App using Flutter, featuring advanced UI design and state management with GetX. Users will enjoy browsing a variety of coffee products, adding items to their cart, and completing purchases seamlessly.
You'll dive into creating a sophisticated user interface, incorporating custom animations, intricate layouts, and responsive design principles to ensure an engaging experience across devices. The GetX library will empower you to manage app state efficiently, enhancing performance and maintainability.
Integrating real APIs, you'll handle product data and user interactions effortlessly, delivering a robust shopping experience. By the project's end, you'll have developed a Coffee App that not only boasts a refined UI but also demonstrates your expertise in leveraging GetX for advanced state management in Flutter applications.`,
        shortDesc: "In this project, you'll craft a sleek and modern Coffee App using Flutter,...",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects6_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/startbucks logo.png",
        pImg2: "/assets/images/flutter/flutterprojects/cofee logo.png"
    },
    {
        pName: "Quiz App",
        pDescriptrion: `In this project, you'll create a dynamic Quiz App using Flutter. This app will allow users to take quizzes, view explanations for answers, and see their results. You'll learn the basics of state management with setState, enabling you to update the UI based on user interactions.
 By building this app, you'll enhance your understanding of state management in Flutter and improve your logical thinking and problem-solving skills. The project will result in a functional and interactive quiz application that demonstrates your foundational knowledge in Flutter development.`,
        shortDesc: "In this project, you'll create a dynamic Quiz App using ...",
        thumbnail: "/assets/images/flutter/flutterprojects/fProjects7_thumbnail.png",
        pImg1: "/assets/images/flutter/flutterprojects/quiz logo1.png",
        pImg2: "/assets/images/flutter/flutterprojects/quiz logo2.png"
    }];          //this variable for all projects info
    // const projectData = useSelector((state)=>state.data.projectData)


    //Function for getting updated window value
    const dispatch = useDispatch();
    const resourceDivRef = useRef();
    const observerElement = useSelector((state) => state.data.observerElement);

    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp7 !== resourceDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                comp7: resourceDivRef.current.offsetTop,
                // comp7: resourceDivRef.current.getBoundingClientRect().top,

            };
            dispatch(setObserverElement(updatedMap));

        }
    }, [dispatch, observerElement]);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="INCFlutterProjects" ref={resourceDivRef}>
            {projectData ? <ProjectDetail /> : null}
            <div className='INCFlutterProjects_container'>
                <div class="INCFlutterProjects_uppar_part">
                    <div class="INCFlutterProjects_pagename">Projects</div>
                    <div class="INCFlutterProjects_heading">Real-time <span>Industry Projects</span> as part of the Internship Program.</div>
                </div>
                <div className='INCFlutterProjects_lower_part'>
                    {!isLoadMore ? <div className="INCFlutterProject_list_container">
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects1.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Food delivery App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/swiggy logo.png' alt='swiggy_logo' />
                                    <img src='/assets/images/flutter/flutterprojects/zomato logo.png' alt='zomato_logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>Food delivery App is a cutting-edge food delivery application developed...  <span onClick={() => dispatch(setProjectData(projectsInfoData[0]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects2.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Chat App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/wpLogomain.png' alt='wplogo' />
                                    <img src='/assets/images/flutter/flutterprojects/messenger logo.png' alt='messenger logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll develop a Chat Application using Flutter...<span onClick={() => dispatch(setProjectData(projectsInfoData[1]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects3.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Expense Manager App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/khatabook logo.png' alt='khatabook logo' />
                                    <img src='/assets/images/flutter/flutterprojects/rupee logo.png' alt='rupee logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll create an Expense Manager App using Flutter...<span onClick={() => dispatch(setProjectData(projectsInfoData[2]))}><u>Read more</u></span></div>
                        </div>
                        {windowWidth < 1025 ? <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects4.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Task Manager app</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/todo logo.png' alt='todo logo' />
                                    <img src='/assets/images/flutter/flutterprojects/todo logo2.png' alt='todo logo2' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>Task Manager is a to-do app built with Flutter and powered by SQLite...<span onClick={() => dispatch(setProjectData(projectsInfoData[3]))}><u>Read more</u></span></div>
                        </div> : null

                        }
                    </div> : null}
                    {isLoadMore ? <div className="INCFlutterProject_list_container">
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects1.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Food delivery App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/swiggy logo.png' alt='swiggy logo' />
                                    <img src='/assets/images/flutter/flutterprojects/zomato logo.png' alt='zomato logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>Food delivery App is a cutting-edge food delivery application developed...  <span onClick={() => dispatch(setProjectData(projectsInfoData[0]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects2.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Chat App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/wpLogomain.png' alt='wplogo_logo' />
                                    <img src='/assets/images/flutter/flutterprojects/messenger logo.png' alt='messenger_logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll develop a Chat Application using Flutter...<span onClick={() => dispatch(setProjectData(projectsInfoData[1]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects3.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Expense Manager App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/khatabook logo.png' alt='khatabook_logo' />
                                    <img src='/assets/images/flutter/flutterprojects/rupee logo.png' alt='rupee_logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll create an Expense Manager App using Flutter...<span onClick={() => dispatch(setProjectData(projectsInfoData[2]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects4.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Task Manager app</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/todo logo.png' alt='todo_logo' />
                                    <img src='/assets/images/flutter/flutterprojects/todo logo2.png' alt='todo logo2' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>Task Manager is a to-do app built with Flutter and powered by SQLite...<span onClick={() => dispatch(setProjectData(projectsInfoData[3]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects5.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>E-Commerce App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/amazon logo.png' alt='amazon logo' />
                                    <img src='/assets/images/flutter/flutterprojects/myntra logo.png' alt='myntra logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll build a comprehensive E-Commerce App using Flutter...<span onClick={() => dispatch(setProjectData(projectsInfoData[4]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects6.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Coffee App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/startbucks logo.png' alt='startbucks logo' />
                                    <img src='/assets/images/flutter/flutterprojects/cofee logo.png' alt='cofee logo' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll craft a sleek and modern Coffee App using Flutter,...<span onClick={() => dispatch(setProjectData(projectsInfoData[5]))}><u>Read more</u></span></div>
                        </div>
                        <div className='INCFlutterProject_info_container'>
                            <img src='/assets/images/flutter/flutterprojects/fProjects7.png' alt='fproject1' />
                            <div className='INCFlutterProject_info'>
                                <div className='INCFlutterProject_name'>Quiz App</div>
                                <div className='INCFlutterProject_logo_similar'>
                                    <img src='/assets/images/flutter/flutterprojects/quiz logo1.png' alt='quiz logo1.png' />
                                    <img src='/assets/images/flutter/flutterprojects/quiz logo2.png' alt='quiz logo2.png' />
                                </div>

                            </div>
                            <div className='INCFlutterProject_textinfo'>In this project, you'll create a dynamic Quiz App using ...<span onClick={() => dispatch(setProjectData(projectsInfoData[6]))}><u>Read more</u></span></div>
                        </div>
                    </div> : null}
                </div>
                <div className='INCFlutterProjects_bottom_part'>{!isLoadMore ?
                    <button className='INCFlutterProjects_load_more' onClick={() => setIsLoadMore(!isLoadMore)}>Load More</button> : <button className='INCFlutterProjects_load_more' onClick={() => setIsLoadMore(!isLoadMore)}>Collapse</button>
                }

                </div>
            </div>
        </div>
    )
}

export default FlutterProjects
