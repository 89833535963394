import React from 'react'
import { useSelector } from 'react-redux'
function Feature3() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky)
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                    </div>

                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box feature3'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#F15B50" />
                            </svg>

                        </div>
                    </div>

                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container'>

                            <div className='INCFeature_asset_container'>
                                <img src='/assets/images/features/GIF 3 final.gif' alt='feature1'></img>
                            </div>
                            <div className='INCFeature_text_content'>
                                <div className='INCFeature_text_content_heading'><span>Digital Logbook :</span> your companion in internship journey</div>
                                <div className='INCFeatureList_litext_feature2'>
                                    Track yourself with digital logbook in the internship program. Effortlessly know your progress, reflect on challenges, and collaborate with mentors, all in one centralized platform.
                                </div>
                                {/* <div className='INCLogbook_btn'>Show Demo Logbook</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature3
