import React from 'react'
import { useSelector } from 'react-redux';
function Feature6() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky);
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                    </div>

                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box feature6'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6Z" fill="#94C748" />
                            </svg>



                        </div>
                    </div>

                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container'>
                            <div className='INCFeature_text_content feature2'>
                                <div className='INCFeature_text_content_heading'>Interview Related <br /><span>Guidance</span></div>
                                <div className='INCFeatureList_litext_feature2'>
                                    Comprehensive interview preparation and insights from placed students. Benefit from tailored guidance, mock interviews, and real-world experiences to excel in your career opportunities.
                                </div>
                            </div>
                            <div className='INCFeature_asset_container'>
                                <img className='img1' src='/assets/images/features/feature7 assets.png' alt='feature1'></img>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature6
