import React from 'react';
import "./styleWhatIsIncubator.css"
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import { EffectFlip, Autoplay } from 'swiper/modules';
function WhatIsIncubator() {
  return (
    <div className='INCWhatisincubator'>
      <div className='INCWhatisincubator_container'>
        <div className='INCWhatisincubator_boxes'>
          <div className='INCWhatisincubator_box1'>
            <div className='INCWhatisincubator_text_feilds_container'>
              <div className='INCWhatisincubator_heading'>What is&nbsp;<svg width="233" height="37" viewBox="0 0 233 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.61934 36.2188C2.7392 36.2188 2.00574 35.9697 1.41898 35.4715C0.86482 34.9401 0.587738 34.1928 0.587738 33.2296V13.9993C0.587738 13.0361 0.86482 12.3054 1.41898 11.8072C2.00574 11.309 2.7392 11.0599 3.61934 11.0599C4.49948 11.0599 5.23293 11.309 5.81969 11.8072C6.40645 12.3054 6.69983 13.0361 6.69983 13.9993V33.2296C6.69983 34.1928 6.40645 34.9401 5.81969 35.4715C5.23293 35.9697 4.49948 36.2188 3.61934 36.2188ZM3.61934 6.626C2.51101 6.626 1.63087 6.32708 0.978912 5.72925C0.326955 5.0982 0.000976562 4.28448 0.000976562 3.28809C0.000976562 2.2917 0.326955 1.49458 0.978912 0.896752C1.63087 0.298917 2.51101 0 3.61934 0C4.69507 0 5.55891 0.298917 6.21087 0.896752C6.89542 1.49458 7.2377 2.2917 7.2377 3.28809C7.2377 4.28448 6.91172 5.0982 6.25976 5.72925C5.60781 6.32708 4.72766 6.626 3.61934 6.626Z" fill="url(#paint0_linear_7001_15548)" />
                <path d="M26.8896 10.9603C29.7582 10.9603 31.8934 11.7906 33.2951 13.4513C34.6968 15.1119 35.3977 17.6195 35.3977 20.974V33.2296C35.3977 34.1596 35.1206 34.8903 34.5664 35.4217C34.0448 35.9531 33.3114 36.2188 32.3661 36.2188C31.4207 36.2188 30.671 35.9531 30.1168 35.4217C29.5626 34.8903 29.2856 34.1596 29.2856 33.2296V21.3228C29.2856 19.4296 28.927 18.0513 28.2098 17.1877C27.5253 16.3242 26.4333 15.8924 24.9337 15.8924C23.1735 15.8924 21.7555 16.457 20.6797 17.5863C19.6366 18.7155 19.115 20.2267 19.115 22.1199V33.2296C19.115 34.1596 18.8379 34.8903 18.2838 35.4217C17.7296 35.9531 16.9799 36.2188 16.0345 36.2188C15.0892 36.2188 14.3394 35.9531 13.7853 35.4217C13.2637 34.8903 13.0029 34.1596 13.0029 33.2296V13.9495C13.0029 13.0859 13.28 12.3885 13.8342 11.857C14.3883 11.3256 15.1381 11.0599 16.0834 11.0599C16.931 11.0599 17.6155 11.3256 18.1371 11.857C18.6913 12.3552 18.9683 13.0195 18.9683 13.8498V15.195C19.7833 13.8332 20.8753 12.787 22.2444 12.0563C23.6135 11.3256 25.1619 10.9603 26.8896 10.9603Z" fill="url(#paint1_linear_7001_15548)" />
                <path d="M52.2162 36.3184C49.8366 36.3184 47.734 35.8036 45.9086 34.774C44.1157 33.7444 42.7303 32.2831 41.7523 30.3899C40.7744 28.4968 40.2854 26.2881 40.2854 23.7639C40.2854 21.2397 40.7907 19.0145 41.8012 17.0881C42.8444 15.1285 44.295 13.6173 46.153 12.5545C48.0111 11.4917 50.1463 10.9603 52.5585 10.9603C53.8298 10.9603 55.1012 11.143 56.3725 11.5083C57.6764 11.8737 58.8173 12.3718 59.7952 13.0029C60.8384 13.7004 61.3599 14.5805 61.3599 15.6433C61.3599 16.374 61.1806 16.9718 60.8221 17.4368C60.4961 17.8686 60.056 18.0845 59.5019 18.0845C59.1433 18.0845 58.7684 18.0015 58.3772 17.8354C57.986 17.6693 57.5949 17.47 57.2037 17.2376C56.4865 16.8058 55.802 16.4737 55.15 16.2412C54.4981 15.9755 53.7483 15.8426 52.9008 15.8426C50.8797 15.8426 49.315 16.5235 48.2067 17.8852C47.131 19.2137 46.5931 21.1401 46.5931 23.6643C46.5931 26.1552 47.131 28.0816 48.2067 29.4433C49.315 30.7719 50.8797 31.4361 52.9008 31.4361C53.7157 31.4361 54.4329 31.3199 55.0522 31.0874C55.7042 30.8217 56.4214 30.4729 57.2037 30.0412C57.6927 29.7423 58.1164 29.5264 58.475 29.3935C58.8336 29.2275 59.1922 29.1444 59.5507 29.1444C60.0723 29.1444 60.5124 29.3769 60.871 29.8419C61.2295 30.3069 61.4088 30.8881 61.4088 31.5856C61.4088 32.1502 61.2784 32.6484 61.0177 33.0802C60.7895 33.4787 60.3983 33.8441 59.8441 34.1762C58.8336 34.8405 57.6601 35.3719 56.3236 35.7704C54.9871 36.1358 53.6179 36.3184 52.2162 36.3184Z" fill="url(#paint2_linear_7001_15548)" />
                <path d="M84.3552 11.0599C85.3005 11.0599 86.0503 11.3256 86.6044 11.857C87.1586 12.3885 87.4357 13.1025 87.4357 13.9993V33.3293C87.4357 34.1928 87.1423 34.8903 86.5555 35.4217C86.0014 35.9531 85.2679 36.2188 84.3552 36.2188C83.475 36.2188 82.7742 35.9697 82.2526 35.4715C81.731 34.9733 81.4703 34.2924 81.4703 33.4289V32.1834C80.7205 33.5119 79.71 34.5415 78.4387 35.2722C77.1673 35.9697 75.733 36.3184 74.1357 36.3184C68.2681 36.3184 65.3343 32.9639 65.3343 26.2549V13.9993C65.3343 13.1025 65.6114 12.3885 66.1656 11.857C66.7197 11.3256 67.4532 11.0599 68.3659 11.0599C69.3113 11.0599 70.061 11.3256 70.6152 11.857C71.1693 12.3885 71.4464 13.1025 71.4464 13.9993V26.3047C71.4464 28.0318 71.7887 29.3105 72.4733 30.1408C73.1578 30.9711 74.2335 31.3863 75.7004 31.3863C77.3955 31.3863 78.7483 30.8217 79.7589 29.6924C80.802 28.53 81.3236 27.0022 81.3236 25.109V13.9993C81.3236 13.1025 81.6006 12.3885 82.1548 11.857C82.709 11.3256 83.4424 11.0599 84.3552 11.0599Z" fill="url(#paint3_linear_7001_15548)" />
                <path d="M107.537 10.9603C109.623 10.9603 111.465 11.4751 113.063 12.5047C114.66 13.5343 115.899 14.9957 116.779 16.8888C117.691 18.782 118.148 20.9906 118.148 23.5148C118.148 26.039 117.691 28.2809 116.779 30.2404C115.899 32.1668 114.644 33.6614 113.014 34.7242C111.416 35.787 109.591 36.3184 107.537 36.3184C105.81 36.3184 104.261 35.9365 102.892 35.1726C101.555 34.4087 100.529 33.3459 99.8115 31.9841V33.2296C99.8115 34.1264 99.5344 34.8405 98.9803 35.3719C98.4261 35.9033 97.6927 36.169 96.7799 36.169C95.8672 36.169 95.1337 35.9033 94.5796 35.3719C94.058 34.8405 93.7972 34.1264 93.7972 33.2296V3.43755C93.7972 2.57401 94.0743 1.87653 94.6285 1.34513C95.2152 0.813718 95.965 0.548014 96.8777 0.548014C97.7905 0.548014 98.5239 0.797111 99.0781 1.29531C99.6322 1.7935 99.9093 2.47437 99.9093 3.33791V15.0953C100.626 13.7668 101.653 12.7538 102.99 12.0563C104.326 11.3256 105.842 10.9603 107.537 10.9603ZM105.924 31.4859C107.847 31.4859 109.346 30.7885 110.422 29.3935C111.498 27.9986 112.036 26.039 112.036 23.5148C112.036 21.0238 111.498 19.1141 110.422 17.7856C109.379 16.457 107.879 15.7928 105.924 15.7928C103.968 15.7928 102.452 16.4737 101.376 17.8354C100.333 19.1639 99.8115 21.0903 99.8115 23.6145C99.8115 26.1386 100.333 28.0816 101.376 29.4433C102.452 30.8051 103.968 31.4859 105.924 31.4859Z" fill="url(#paint4_linear_7001_15548)" />
                <path d="M133.53 10.9603C136.855 10.9603 139.316 11.8072 140.913 13.5011C142.543 15.1617 143.358 17.7025 143.358 21.1235V33.2794C143.358 34.1762 143.098 34.8903 142.576 35.4217C142.054 35.9199 141.337 36.169 140.424 36.169C139.577 36.169 138.876 35.9033 138.322 35.3719C137.8 34.8405 137.54 34.143 137.54 33.2794V32.1834C136.985 33.4787 136.105 34.4917 134.899 35.2224C133.726 35.9531 132.357 36.3184 130.792 36.3184C129.195 36.3184 127.744 35.9863 126.44 35.322C125.136 34.6578 124.109 33.7444 123.36 32.582C122.61 31.4195 122.235 30.1242 122.235 28.696C122.235 26.9025 122.675 25.491 123.555 24.4614C124.468 23.4318 125.935 22.6845 127.956 22.2195C129.977 21.7545 132.764 21.522 136.317 21.522H137.54V20.3762C137.54 18.7487 137.197 17.5697 136.513 16.839C135.828 16.0751 134.72 15.6931 133.188 15.6931C132.242 15.6931 131.281 15.8426 130.303 16.1415C129.325 16.4072 128.168 16.8058 126.831 17.3372C125.984 17.769 125.364 17.9848 124.973 17.9848C124.386 17.9848 123.897 17.769 123.506 17.3372C123.148 16.9054 122.968 16.3408 122.968 15.6433C122.968 15.0787 123.099 14.5971 123.36 14.1986C123.653 13.7668 124.126 13.3682 124.778 13.0029C125.918 12.3718 127.271 11.8737 128.836 11.5083C130.433 11.143 131.998 10.9603 133.53 10.9603ZM132.014 31.8347C133.644 31.8347 134.964 31.2867 135.975 30.1906C137.018 29.0614 137.54 27.6166 137.54 25.8563V24.8101H136.659C134.475 24.8101 132.78 24.9098 131.574 25.109C130.368 25.3083 129.504 25.6571 128.983 26.1552C128.461 26.6534 128.2 27.3343 128.2 28.1978C128.2 29.2607 128.559 30.1408 129.276 30.8383C130.026 31.5025 130.939 31.8347 132.014 31.8347Z" fill="url(#paint5_linear_7001_15548)" />
                <path d="M161.99 31.5856C163.62 31.6852 164.435 32.4325 164.435 33.8275C164.435 34.6246 164.109 35.239 163.457 35.6708C162.837 36.0693 161.941 36.2354 160.767 36.169L159.447 36.0693C153.971 35.6708 151.233 32.6816 151.233 27.1018V16.2412H148.788C147.908 16.2412 147.223 16.0419 146.734 15.6433C146.278 15.2448 146.05 14.6635 146.05 13.8996C146.05 13.1357 146.278 12.5545 146.734 12.156C147.223 11.7574 147.908 11.5581 148.788 11.5581H151.233V6.97473C151.233 6.07798 151.51 5.3639 152.064 4.83249C152.618 4.30108 153.368 4.03538 154.313 4.03538C155.226 4.03538 155.959 4.30108 156.513 4.83249C157.068 5.3639 157.345 6.07798 157.345 6.97473V11.5581H161.501C162.381 11.5581 163.049 11.7574 163.506 12.156C163.995 12.5545 164.239 13.1357 164.239 13.8996C164.239 14.6635 163.995 15.2448 163.506 15.6433C163.049 16.0419 162.381 16.2412 161.501 16.2412H157.345V27.5502C157.345 30.008 158.453 31.3199 160.67 31.4859L161.99 31.5856Z" fill="url(#paint6_linear_7001_15548)" />
                <path d="M178.524 36.3184C176.079 36.3184 173.928 35.8036 172.069 34.774C170.244 33.7444 168.826 32.2664 167.815 30.3401C166.805 28.4137 166.3 26.1719 166.3 23.6145C166.3 21.0571 166.805 18.8318 167.815 16.9386C168.826 15.0123 170.244 13.5343 172.069 12.5047C173.928 11.4751 176.079 10.9603 178.524 10.9603C180.969 10.9603 183.104 11.4751 184.929 12.5047C186.787 13.5343 188.205 15.0123 189.183 16.9386C190.194 18.8318 190.699 21.0571 190.699 23.6145C190.699 26.1719 190.194 28.4137 189.183 30.3401C188.205 32.2664 186.787 33.7444 184.929 34.774C183.104 35.8036 180.969 36.3184 178.524 36.3184ZM178.475 31.4859C180.463 31.4859 181.979 30.8217 183.022 29.4932C184.066 28.1646 184.587 26.2051 184.587 23.6145C184.587 21.0571 184.066 19.1141 183.022 17.7856C181.979 16.4238 180.48 15.743 178.524 15.743C176.568 15.743 175.052 16.4238 173.976 17.7856C172.933 19.1141 172.412 21.0571 172.412 23.6145C172.412 26.2051 172.933 28.1646 173.976 29.4932C175.02 30.8217 176.519 31.4859 178.475 31.4859Z" fill="url(#paint7_linear_7001_15548)" />
                <path d="M209.094 11.0599C209.941 10.9935 210.61 11.1762 211.099 11.6079C211.588 12.0397 211.832 12.6874 211.832 13.5509C211.832 14.4477 211.62 15.1119 211.196 15.5437C210.773 15.9755 210.007 16.2578 208.898 16.3906L207.431 16.5401C205.508 16.7394 204.09 17.4036 203.177 18.5329C202.297 19.6621 201.857 21.0737 201.857 22.7675V33.2296C201.857 34.1928 201.564 34.9401 200.977 35.4715C200.39 35.9697 199.657 36.2188 198.777 36.2188C197.897 36.2188 197.163 35.9697 196.576 35.4715C196.022 34.9401 195.745 34.1928 195.745 33.2296V13.9495C195.745 13.0195 196.022 12.3054 196.576 11.8072C197.163 11.309 197.88 11.0599 198.728 11.0599C199.575 11.0599 200.26 11.309 200.781 11.8072C201.303 12.2722 201.564 12.9531 201.564 13.8498V15.8426C202.183 14.3812 203.096 13.252 204.302 12.4549C205.541 11.6578 206.91 11.2094 208.409 11.1098L209.094 11.0599Z" fill="url(#paint8_linear_7001_15548)" />
                <path d="M222.879 36.3184C219.326 36.3184 216.458 35.6044 214.274 34.1762C213.23 33.5451 212.709 32.6484 212.709 31.4859C212.709 30.8217 212.888 30.2903 213.247 29.8917C213.605 29.4599 214.045 29.2441 214.567 29.2441C215.121 29.2441 215.887 29.5098 216.865 30.0412C217.908 30.5394 218.854 30.9213 219.701 31.187C220.581 31.4527 221.69 31.5856 223.026 31.5856C224.395 31.5856 225.455 31.3531 226.204 30.8881C226.987 30.4231 227.378 29.7755 227.378 28.9451C227.378 28.3805 227.215 27.9321 226.889 27.6C226.595 27.2679 226.041 26.969 225.226 26.7033C224.444 26.4043 223.238 26.0722 221.608 25.7069C218.707 25.109 216.621 24.2621 215.349 23.1661C214.111 22.07 213.491 20.5589 213.491 18.6325C213.491 17.1711 213.915 15.8592 214.762 14.6968C215.61 13.5011 216.767 12.5877 218.234 11.9567C219.734 11.2924 221.429 10.9603 223.319 10.9603C224.689 10.9603 226.009 11.143 227.28 11.5083C228.551 11.8404 229.692 12.3386 230.703 13.0029C231.746 13.6672 232.268 14.5473 232.268 15.6433C232.268 16.3076 232.088 16.8722 231.73 17.3372C231.371 17.769 230.931 17.9848 230.409 17.9848C230.051 17.9848 229.692 17.9184 229.334 17.7856C229.008 17.6195 228.584 17.387 228.062 17.0881C227.117 16.5899 226.302 16.2246 225.618 15.9921C224.933 15.7264 224.085 15.5935 223.075 15.5935C221.869 15.5935 220.907 15.8426 220.19 16.3408C219.505 16.839 219.163 17.5199 219.163 18.3834C219.163 19.2137 219.522 19.8448 220.239 20.2765C220.956 20.7083 222.341 21.1401 224.395 21.5719C226.579 22.0368 228.291 22.5682 229.529 23.1661C230.768 23.7639 231.648 24.5112 232.17 25.408C232.724 26.3047 233.001 27.4506 233.001 28.8455C233.001 31.104 232.072 32.9141 230.214 34.2758C228.388 35.6376 225.944 36.3184 222.879 36.3184Z" fill="url(#paint9_linear_7001_15548)" />
                <defs>
                  <linearGradient id="paint0_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint3_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint4_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint5_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint6_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint7_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint8_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                  <linearGradient id="paint9_linear_7001_15548" x1="116.158" y1="14.9243" x2="116.158" y2="53.0007" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE4661" />
                    <stop offset="1" stop-color="#92161A" />
                  </linearGradient>
                </defs>
              </svg>

                &nbsp;?</div>
              <div className='INCWhatisincubator_text'>
                Incubators nurturing tech talent from the ground up. Just like an incubator supports early-stage startups, we provide the ideal environment for learning and growth in programming. Our focus is on cultivating skills, fostering creativity, and preparing students to thrive in the dynamic world of technology.
              </div>
            </div>
          </div>
          <div className='INCWhatisincubator_box2'>
            <Swiper
              effect={'flip'}
              grabCursor={true}
              modules={[EffectFlip, Autoplay]}
              className="mySwiper"
              loop={true}
              watchSlidesProgress={true}
              speed={1500}
              autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
            >
              <SwiperSlide>
                <img src="/assets/images/about/whatisincubators/flipimage1.png" alt='/' />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/images/about/whatisincubators/flipimage2.png" alt='/' />
              </SwiperSlide>
              <SwiperSlide>
                <img src="/assets/images/about/whatisincubators/flipimage3.png" alt='/' />
              </SwiperSlide>

            </Swiper>
          </div>
        </div>
      </div>
    </div >
  )
}

export default WhatIsIncubator
