import React, { useEffect, useRef, useState } from 'react'
import "./styleFlutterYoutube.css"
import { useDispatch, useSelector } from 'react-redux';
import { setObserverElement } from '../../../incubStore/actions';

function FlutterYoutube() {
    const [isFrame, setIsFrame] = useState(false)
    const dispatch = useDispatch();
    const resourceDivRef = useRef();
    const observerElement = useSelector((state) => state.data.observerElement);
    const scrollPos = useSelector((state) => state.data.scrollPos)
    const [posVal, setPosVal] = useState(0);
    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp8 !== resourceDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                comp8: resourceDivRef.current.offsetTop,
            };
            dispatch(setObserverElement(updatedMap));
        }
    }, [observerElement, posVal]);
    let count = 0;
    useEffect(() => {
        count++;
        if (count < 5) {
            setPosVal(count)
        }
    }, [resourceDivRef && resourceDivRef.current && resourceDivRef.current.offsetTop])
    return (
        <div className='INCFlutterYoutube' ref={resourceDivRef}>
            <div className='INCFlutterYoutube_container'>
                <div class="INCFlutterYoutube_uppar_part">
                    <div class="INCFlutterYoutube_pagename">YouTube Learning</div>
                    <div class="INCFlutterYoutube_heading"><span>Flutter videos</span> And Playlist</div>
                    {/* <button className='INCFlutterYoutube_view_more_button' onClick={() => window.open("https://youtube.com/playlist?list=PLQI9GijW7SEoST_F9tnhkSG5qAJPnYFSK&si=JFad4GkzyOBUAaqR", "_blank")}>View All</button> */}
                </div>
                <div className='INCFlutterYoutube_lower_part'>
                    <div className="INCFlutterYoutube_list_container">
                        <div className='INCFlutterYoutube_info_container' onClick={() => { setIsFrame("https://www.youtube.com/embed/ICE5yZ9rV18?si=34UgY62_W5jfdTT2") }}>
                            <img src='/assets/images/flutter/FlutterYoutube/ytImage1.png' alt='fproject1' />
                            <div className='INCFlutterYoutube_info'>
                                <div className='INCFlutterYoutube_name'>Flutter Demo Lecture-01</div>

                            </div>
                            <div className='INCFlutterYoutube_textinfo'>This video specifies the detailed course overview and the structure of the course. </div>
                        </div>
                        <div className='INCFlutterYoutube_info_container' onClick={() => setIsFrame("https://www.youtube.com/embed/XCONMMxmwtQ?si=JisCDQHFbIcj_79H")}>
                            <img src='/assets/images/flutter/FlutterYoutube/ytImage2.png' alt='fproject1' />
                            <div className='INCFlutterYoutube_info'>
                                <div className='INCFlutterYoutube_name'>Flutter Demo Lecture-02</div>

                            </div>
                            <div className='INCFlutterYoutube_textinfo'>How the code gets compiled, how the android code gets compiled and how mobile application works internally is explained in this video.</div>
                        </div>
                        <div className='INCFlutterYoutube_info_container' onClick={() => setIsFrame("https://www.youtube.com/embed/n5H87CNjUWo?si=YfUDNWU-MktoMsE0")}>
                            <img src='/assets/images/flutter/FlutterYoutube/ytImage3.png' alt='fproject1' />
                            <div className='INCFlutterYoutube_info'>
                                <div className='INCFlutterYoutube_name'>Flutter Demo Lecture-03</div>


                            </div>
                            <div className='INCFlutterYoutube_textinfo'>This video takes you on a journey from the earliest mobile communication devices to the sophisticated smartphones we use today. </div>
                        </div>
                    </div>

                </div>
            </div>{
                isFrame ? <div className='INCYoutubeFrame_container'>
                    <div className='INCYoutubeFrame_blur_container'></div>
                    <div className='INCYoutubeFrame_holder'>
                        <svg onClick={() => setIsFrame(false)} width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <iframe width="70%" height="70%" src={`${isFrame}?rel=0`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div> : null
            }

        </div>
    )
}

export default FlutterYoutube
