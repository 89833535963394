import React, { useEffect, useState } from 'react';
import "./stylePlacementLandingPage.css"

function PlacementLandingPage() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className="INCPlacelan firstContainer">
            <div className='INCPlacelan_container'>
                <div className='INCPlacelan_part part1'>
                    <div className='INCPlacelan_background_highlight' />
                    <div className='INCPlacelan_text_container'>
                        <div className='INCPlacelan_heading heading1'>Placement </div>
                        <div className='INCPlacelan_heading heading2'>Assistance for your</div>
                        <div className='INCPlacelan_heading heading3'>Great Career</div>
                        <div className='INCPlacelan_text'>Hiring the best developers, lead to the best apps and websites</div>
                    </div>
                    {windowWidth > 1024 ?
                        <div className='INCPlacelan_downarrow_container'>
                            <div class="arrow-container">
                                <div class="arrow"></div>
                                <div class="arrow"></div>
                                <div class="arrow"></div>
                            </div>



                            <div className='INCPlacelan_scroll_text'>Continue scrolling</div>
                        </div> : null
                    }
                </div>
                <div className='INCPlacelan_part part2'>
                    <img src='/assets/images/placement/landing/pathGuidance.gif' alt='pathGuidance' />
                </div>
            </div>
        </div>
    )
}

export default PlacementLandingPage
