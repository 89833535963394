// initialState.js
const initialState = {
  scrollPos: window.screenTop,
  isNavColor: false, //for navbar animation
  isForm: false, //for equiry form visibility
  location: '/', // for store a location for routing and navbar purpose
  isCourseNavColor: false, //for navbar animation on flutter page or course page
  projectData: null,
  isCourseEnq: '', //for auto fill feild for area of intrest
  observerElement: {
    comp1: 0,
    comp2: 0,
    comp3: 0,
    comp4: 0,
    comp5: 0,
    comp6: 0,
    comp7: 0,
    comp8: 0,
    comp9: 0,
    comp10: 0,
    comp11: 0,
  },
  flutterScrollTo: 1,
  isStickyScroll: false,
  isActiveSticky: false,
  homePagePos: 0,
  flutterScrollDivRef: null,
  courseScrollPos: 0,
  mainCourseScrollPos: 'box1',
};
export default initialState;
