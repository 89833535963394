import React from 'react'
import { useSelector } from 'react-redux';
function Feature7() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky);
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                    </div>

                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box feature7'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#4BCE97" />
                            </svg>

                        </div>
                    </div>

                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container'>
                            <div className='INCFeature_asset_container'>
                                <img style={{ width: "80%" }} src='/assets/images/features/Gif 4 Final.gif' alt='feature1'></img>
                            </div>

                            <div className='INCFeature_text_content'>
                                <div className='INCFeature_text_content_heading'><span>Internship</span> Offer letter & Completion Certificate </div>
                                <div className='INCFeatureList_litext_feature2'>
                                    After completing the internship, participants will receive an offer letter acknowledging your achievements and a completion letter highlighting your contributions and gained experience, setting them on a path for future success.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature7
