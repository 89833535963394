import React from 'react'
import "./styleFeatures.css"
import { useSelector } from 'react-redux'
function Feature1() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky)
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                        Our Features
                    </div>
                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#8F7EE7" />
                            </svg>
                        </div>
                    </div>
                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container    '>
                            <div className='INCFeature_asset_container'>
                                <img src='/assets/images/features/Gif 5 Final.gif' alt='feature1'></img>
                            </div>
                            <div className='INCFeature_text_content feature1'>
                                <div className='INCFeature_text_content_heading feature1' >Hands on <span>Coding</span> Experience</div>
                                <div className='INCFeature_text_content_list'>
                                    <div className='INCFeature_text_conent_list_li'>
                                        <div className='INCFeature_svg_container'>
                                            <img src='/assets/images/features/feature1 png1.svg' alt='/' />


                                        </div>

                                        <div className='INCFeatureList_litext'>
                                            Real world projects & Quiz based practice
                                        </div>
                                    </div>
                                    <div className='INCFeature_text_conent_list_li'>
                                        <div className='INCFeature_svg_container'>
                                            <img src='/assets/images/features/feature1 png2.png' alt='/' />
                                        </div>
                                        <div className='INCFeatureList_litext'>
                                            Daily practice programs & In class hands on programs
                                        </div>

                                    </div>
                                    <div className='INCFeature_text_conent_list_li'>
                                        <div className='INCFeature_svg_container'>
                                            <img src='/assets/images/features/feature1 png3.png' alt='/' />
                                        </div>
                                        <div className='INCFeatureList_litext'>
                                            Doubt solving sessions, Regular practical's & daily flash
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature1
