import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import FlutterPage from './pages/Flutterpage/FlutterPage';
import FlutterRoadmap from './pages/Flutterpage/FlutterComponent/FlutterRoadmap';
import PlacementPage from './pages/placementpage/PlacementPage';

import Enquiry from './components/layouts/enquiry/Enquiry';
import About from './pages/about/About';
import AboutLanding from './pages/about/AboutLanding';
function RoutingComponent() {

    return (
        <Routes>
            <Route path="/" Component={HomePage} />
            <Route path='/programs/flutter' Component={FlutterPage} />
            <Route path='/programs/flutter/roadmap' Component={FlutterRoadmap} />
            <Route path='/placement' Component={PlacementPage} />
            <Route path='/enquiry' Component={Enquiry} />
            <Route path='/about' Component={AboutLanding} />
        </Routes>
    )
}

export default RoutingComponent
