import React, { useEffect, useRef, useState } from 'react'
import "./styleEnquiry.css";
import { useDispatch, useSelector } from 'react-redux';
import { setIsForm, setLocation } from '../../../incubStore/actions';
import Select from "react-dropdown-select";
import BottomNavbar from "../BottomNavbar"
import { degreeOptions, branchOptions, areaOfIntrestOptions, collegesOptions } from '../../globals/FormOptionData';

function Enquiry() {
    const dispatch = useDispatch();

    const [stdData, setStdData] = useState({
        timestamp: "",
        name: "",
        mobileNo: "",
        emailId: "",
        college: "",
        branch: "",
        degree: "",
        area_of_interest: "",
        referenced_by: "",
        message: ""
    });
    const inputOuterDivRef = useRef(null);
    const inputOuterDivRefPart = useRef(null);
    const [branchOption, setBranchOption] = useState(branchOptions);
    const [degreeValues, setDegreeValues] = useState();
    const [branchValues, setBranchValues] = useState();
    const [areaOfInterestValues, setAreaOfInterestValues] = useState();
    const [collegeValues, setCollegeValues] = useState();
    const [isError, setIsError] = useState(false)
    const [buttonText, setButtonText] = useState("Submit");
    const isForm = useSelector((state) => state.data.isForm);
    const isCourseEnq = useSelector((state) => state.data.isCourseEnq)

    useEffect(() => {
        dispatch(setLocation("/enquiry"))
        if (isCourseEnq === 'flutter') {
            setAreaOfInterestValues([
                {
                    "id": "1",
                    "name": "Flutter app Development"
                }
            ])
            setStdData({ ...stdData, ["area_of_interest"]: "Flutter app Development" });
        }
        if (isCourseEnq === 'frontend') {
            setAreaOfInterestValues([
                {
                    "id": "2",
                    "name": "Web Development"
                }
            ])
            setStdData({ ...stdData, ["area_of_interest"]: "Web Development" });
        }
        if (isCourseEnq === 'backend') {
            setAreaOfInterestValues([
                {
                    "id": "3",
                    "name": "Backend Development"
                }
            ])
            setStdData({ ...stdData, ["area_of_interest"]: "Backend Development" });
        }
    }, [isCourseEnq])

    const handleChange = (e) => {

        const { name, value } = e.target;
        setStdData({ ...stdData, [name]: value });
        if (name === "referenced_by" && stdData.timestamp === "") {
            const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });;
            setStdData({ ...stdData, ["timestamp"]: timestamp });
            setStdData({ ...stdData, [name]: value });
        }
    };

    useEffect(() => {
        if (inputOuterDivRef) {
            const targetElement = document.querySelectorAll(".INCGetintouch_inputfeilds_single")[1];
            if (targetElement) {
                const childElement = targetElement.childNodes[0];
                if (childElement && childElement.childNodes[0] && childElement.childNodes[0].style) {
                    childElement.childNodes[0].style.width = `${inputOuterDivRef.current.clientWidth - 43}px`;

                }
            }
        }
    }, [inputOuterDivRef]);
    useEffect(() => {
        if (inputOuterDivRefPart) {
            const dropdownElements = document.querySelectorAll('.react-dropdown-select');
            dropdownElements.forEach((element) => {
                const grandparent = element.closest('.INCGetintouch_inputfeild_division_part');
                if (grandparent) {
                    element.style.width = `${inputOuterDivRefPart.current.clientWidth - 42}px`;
                }
            });
        }
    }, [inputOuterDivRefPart]);
    function isValidName(name) {
        // Regular expression for validating names
        const regex = /^[a-zA-Z\s'-]+$/;

        // Check if the name matches the regex pattern and has a reasonable length
        return regex.test(name) && name.length > 0 && name.length <= 100;
    }


    const handleStdName = (e) => {
        if (e.target.value.length > 20) {
            return
        }
        handleChange(e);
    };

    /*checking indian mobile number validity*/
    function isValidIndianMobileNumber(number) {
        // Remove any non-digit characters from the input
        const cleaned = number.replace(/\D/g, '');

        // Regular expression to validate Indian mobile numbers
        const regex = /^(?:\+91|91)?([56789]\d{9})$/;
        // Check if the cleaned number matches the regex pattern
        return regex.test(cleaned);
    }

    const handleMobileNo = (e) => {
        if (e.target.value.length > 10) {
            return
        }
        handleChange(e);
    };
    function isValidEmail(email) {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check for the presence of adjacent dots
        if (email.includes('..')) {
            return false;
        }

        // Validate the email against the regex
        return emailRegex.test(email);
    }

    const handleEmailId = (e) => {
        if (e.target.value.length > 50) {
            return
        }
        handleChange(e);
    };

    const handleReferencedBy = (e) => {
        if (e.target.value.length > 20) {

            return
        }
        console.log(e)
        handleChange(e);
    };
    const handleMessege = (e) => {
        handleChange(e);
    };

    /*for checking all feild is valid*/
    function validateStdData(stdData) {
        // Exclude the message field from required field checks
        const { message, ...requiredFields } = stdData;

        // Check if all required fields are not empty
        const allRequiredFieldsFilled = Object.values(requiredFields).every(value => value.trim() !== '');

        // Validate specific fields
        const nameValid = isValidName(stdData.name);
        const emailValid = isValidEmail(stdData.emailId);
        const mobileValid = isValidIndianMobileNumber(stdData.mobileNo);

        // Return combined result
        return allRequiredFieldsFilled && nameValid && emailValid && mobileValid;
    }
    const [isClicked, setIsClicked] = useState();
    const handleSubmit = async (e) => {

        setIsClicked(true)
        if (!isValidName(stdData.name)) {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (!isValidIndianMobileNumber(stdData.mobileNo)) {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (!isValidEmail(stdData.emailId)) {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (stdData.college === '') {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (stdData.degree === '') {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (stdData.branch === '') {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (stdData.area_of_interest === '') {
            setIsError(true)
            setIsClicked(false)
            return
        }
        if (stdData.referenced_by === '') {
            setIsError(true)
            setIsClicked(false)
            return
        }


        e.preventDefault();
        setButtonText("Thanks");
        document.querySelector("#btn").classList.add("active");
        const formData = new FormData();

        Object.keys(stdData).forEach(key => {
            formData.append(key, stdData[key]);
        });
        setIsError(false)
        try {
            const response = await fetch('https://script.google.com/macros/s/AKfycby8Xy_X9sQJkI8ZzQuaPX6-9K3s1qMt_BHFI1boDytW2YivCk0pvEUd8K20nUSXwew-/exec', {
                method: 'POST',
                body: formData
            });

            const data = response;

            // Optionally reset form fields after successful submission
            setStdData({
                timestamp: "",
                name: "",
                mobileNo: "",
                emailId: "",
                college: "",
                branch: "",
                degree: "",
                area_of_interest: "",
                referenced_by: "",
                message: ""
            });
            setDegreeValues()
            setBranchValues()
            setAreaOfInterestValues()
            setCollegeValues()
            setButtonText("Submit");
            const timer = setTimeout(() => {
                e.preventDefault();
                document.querySelector("#btn").classList.remove("active");
                setIsClicked(false)
            }, 3000);
            return () => clearTimeout(timer);
        } catch (error) {
            alert("Not Submitted, Please Try Again")
        }
    };

    return (
        <div className='INCGetInTouch_enquiry_page'>
            <div className='INCGetInTouch enquiry'>
                <div className='INCGetInTouch_blurDiv' />
                <div className='INCGetintouch_container' onClick={() => dispatch(setIsForm(false))}>
                    <div className='INCGetintouch_form_container' onClick={(e) => e.stopPropagation()}>
                        <div className='INCGetintouch_asset_container'>
                            <svg className='INCGetintouch_asset_container_dot_background' width="326" height="166" viewBox="0 0 326 166" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="320" y="79" width="6" height="5" rx="2.5" fill="white" />
                                <rect y="84" width="6" height="5" rx="2.5" fill="#00C2FF" />
                                <rect x="277" y="158" width="8" height="8" rx="4" fill="#014CA5" />
                                <rect x="49" width="8" height="8" rx="4" fill="#014CA5" />
                                <rect x="157" y="37" width="8" height="8" rx="4" fill="white" />
                                <rect x="232" y="14" width="7" height="7" rx="3.5" fill="#FFCC00" />
                            </svg>

                            <div className='INCGetintouch_heading_container'>Share your <span>details</span> with us</div>
                            <div className='INCGetintouch_heading_info_content_container'>
                                <div className='NCGetintouch_heading_info_content'>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20.4473" cy="20.4473" r="20.4473" />
                                        <circle cx="20.4473" cy="20.4473" r="20.4473" fill="url(#paint0_linear_6975_33030)" />
                                        <path d="M27.6454 13.0859H13.2505C12.2609 13.0859 11.4602 13.9141 11.4602 14.9262L11.4512 25.9677C11.4512 26.9799 12.2609 27.808 13.2505 27.808H27.6454C28.6351 27.808 29.4448 26.9799 29.4448 25.9677V14.9262C29.4448 13.9141 28.6351 13.0859 27.6454 13.0859ZM27.2855 16.9965L20.9248 21.0634C20.6369 21.2475 20.259 21.2475 19.9711 21.0634L13.6104 16.9965C13.5202 16.9447 13.4412 16.8747 13.3782 16.7908C13.3152 16.7069 13.2695 16.6107 13.2439 16.5082C13.2182 16.4057 13.2132 16.299 13.2291 16.1944C13.245 16.0899 13.2814 15.9897 13.3363 15.9C13.3911 15.8102 13.4631 15.7328 13.5481 15.6724C13.633 15.612 13.729 15.5698 13.8304 15.5485C13.9317 15.5271 14.0362 15.527 14.1376 15.5482C14.2389 15.5693 14.3351 15.6113 14.4201 15.6715L20.448 19.5268L26.4758 15.6715C26.5609 15.6113 26.657 15.5693 26.7584 15.5482C26.8597 15.527 26.9643 15.5271 27.0656 15.5485C27.1669 15.5698 27.2629 15.612 27.3479 15.6724C27.4328 15.7328 27.5049 15.8102 27.5597 15.9C27.6145 15.9897 27.651 16.0899 27.6669 16.1944C27.6828 16.299 27.6777 16.4057 27.6521 16.5082C27.6265 16.6107 27.5808 16.7069 27.5178 16.7908C27.4548 16.8747 27.3758 16.9447 27.2855 16.9965Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_6975_33030" x1="0" y1="0" x2="49.361" y2="20.0256" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#313843" />
                                                <stop offset="1" stop-color="#101926" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <div className='NCGetintouch_heading_info'>
                                        <div className='NCGetintouch_heading_info_heading'>Email ID</div>
                                        <div className='NCGetintouch_heading_info_text'>incubatorsinfo@gmail.com</div>
                                    </div>
                                </div>
                                <div className='NCGetintouch_heading_info_content'>
                                    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20.4473" cy="21.2363" r="20.4473" />
                                        <circle cx="20.4473" cy="21.2363" r="20.4473" fill="url(#paint0_linear_6975_33033)" />
                                        <path d="M26.3655 23.9144L24.2839 23.6768C24.0391 23.648 23.791 23.6751 23.5582 23.756C23.3254 23.8369 23.114 23.9695 22.9398 24.1439L21.4319 25.6519C19.1054 24.4686 17.2143 22.5776 16.0311 20.2511L17.5473 18.7349C17.8997 18.3825 18.0718 17.8908 18.0144 17.3909L17.7767 15.3256C17.7303 14.9258 17.5384 14.5571 17.2377 14.2896C16.9369 14.0221 16.5483 13.8745 16.1458 13.875H14.728C13.8019 13.875 13.0316 14.6454 13.0889 15.5715C13.5233 22.5704 19.1208 28.1597 26.1115 28.594C27.0375 28.6514 27.8079 27.881 27.8079 26.9549V25.5371C27.8161 24.7094 27.1932 24.0128 26.3655 23.9144Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_6975_33033" x1="0" y1="0.789062" x2="49.361" y2="20.8146" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#313843" />
                                                <stop offset="1" stop-color="#101926" />
                                            </linearGradient>
                                        </defs>
                                    </svg>


                                    <div className='NCGetintouch_heading_info'>
                                        <div className='NCGetintouch_heading_info_heading'>Contact</div>
                                        <div className='NCGetintouch_heading_info_text'>+91 9730435300 </div>
                                    </div>
                                </div>
                                <div className='NCGetintouch_heading_info_content'>
                                    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20.4473" cy="20.4473" r="20.4473" />
                                        <circle cx="20.4473" cy="20.4473" r="20.4473" fill="url(#paint0_linear_6975_33048)" />
                                        <path d="M20.0385 13.0859C21.6654 13.0859 23.2256 13.7243 24.376 14.8606C25.5264 15.9968 26.1727 17.538 26.1727 19.1449C26.1727 21.7052 24.3513 24.4954 20.7557 27.5454C20.5558 27.715 20.3009 27.8082 20.0373 27.808C19.7737 27.8078 19.519 27.7141 19.3194 27.5442L19.0815 27.3403C15.6451 24.3699 13.9043 21.648 13.9043 19.1449C13.9043 17.538 14.5506 15.9968 15.701 14.8606C16.8513 13.7243 18.4116 13.0859 20.0385 13.0859ZM20.0385 16.8145C19.4128 16.8145 18.8127 17.06 18.3702 17.4971C17.9277 17.9341 17.6792 18.5268 17.6792 19.1449C17.6792 19.7629 17.9277 20.3557 18.3702 20.7927C18.8127 21.2297 19.4128 21.4753 20.0385 21.4753C20.6642 21.4753 21.2643 21.2297 21.7068 20.7927C22.1492 20.3557 22.3978 19.7629 22.3978 19.1449C22.3978 18.5268 22.1492 17.9341 21.7068 17.4971C21.2643 17.06 20.6642 16.8145 20.0385 16.8145Z" fill="white" />
                                        <defs>
                                            <linearGradient id="paint0_linear_6975_33048" x1="0" y1="0" x2="49.361" y2="20.0256" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#313843" />
                                                <stop offset="1" stop-color="#101926" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    <div className='NCGetintouch_heading_info'>
                                        <div className='NCGetintouch_heading_info_heading'>Incubators Systems Pvt. Ltd. </div>
                                        <div className='NCGetintouch_heading_info_text'>3rd floor, Walhekar Properties, Narhe, Pune 411041, Maharashtra.</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='INCGetintouch_form_layout'>{isForm ? <svg onClick={() => dispatch(setIsForm(false))} className='INCGetintouch_form_cancel' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg> : null
                        }


                            <div className='INCGetintouch_form_layout_container' >

                                <div className='INCGetintouch_form_layout_container_scroll' >
                                    {/* <div className='INCGetintouch_form_heading_container'>{
                                    isCourseEnq === null || isCourseEnq === '' ? "Get In Touch" : "Register Now"}
                                </div> */}
                                    <div className='INCGetintouch_input_feild_container'>
                                        <div className={`INCGetintouch_inputfeilds_single ${isError && !isValidName(stdData.name) ? "errorGredient" : null}`}>
                                            <input className='INCGetintouch_input name' name='name' type='text' maxLength={25} placeholder='Full Name' value={stdData.name} onChange={handleStdName} />
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.6673 17.5V15.8333C16.6673 14.9493 16.3161 14.1014 15.691 13.4763C15.0659 12.8512 14.218 12.5 13.334 12.5H6.66732C5.78326 12.5 4.93542 12.8512 4.31029 13.4763C3.68517 14.1014 3.33398 14.9493 3.33398 15.8333V17.5" stroke="white" strokeOpacity="0.4" stroke-width="1.61722" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M9.99935 9.16569C11.8403 9.16569 13.3327 7.67331 13.3327 5.83236C13.3327 3.99141 11.8403 2.49902 9.99935 2.49902C8.1584 2.49902 6.66602 3.99141 6.66602 5.83236C6.66602 7.67331 8.1584 9.16569 9.99935 9.16569Z" stroke="white" strokeOpacity="0.4" stroke-width="1.61722" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className='INCGetintouch_inputfeilds_part'>
                                            <div className={`INCGetintouch_inputfeild_division_part ${isError && (!isValidIndianMobileNumber(stdData.mobileNo)) ? "errorGredient" : null}`}>
                                                <input className={`INCGetintouch_input mobile `} maxLength={10} name='mobileNo' type='number' inputMode="numeric"
                                                    pattern="[0-9]*" placeholder='Mobile Number' value={stdData.mobileNo} onChange={handleMobileNo} />
                                                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M17.3039 13.8377V16.3377C17.3048 16.5698 17.2573 16.7995 17.1643 17.0122C17.0713 17.2248 16.935 17.4157 16.764 17.5726C16.5929 17.7295 16.391 17.8489 16.1712 17.9233C15.9513 17.9977 15.7184 18.0253 15.4872 18.0044C12.9229 17.7257 10.4597 16.8495 8.29557 15.446C6.28209 14.1666 4.57501 12.4595 3.29557 10.446C1.88721 8.27205 1.01077 5.79687 0.737233 3.22104C0.716408 2.9906 0.743795 2.75834 0.81765 2.53906C0.891504 2.31978 1.01021 2.11829 1.1662 1.9474C1.3222 1.77651 1.51207 1.63997 1.72373 1.54648C1.93538 1.45299 2.16418 1.4046 2.39557 1.40438H4.89557C5.29999 1.4004 5.69206 1.54361 5.9987 1.80732C6.30534 2.07103 6.50563 2.43725 6.56223 2.83771C6.66775 3.63777 6.86344 4.42332 7.14557 5.17938C7.25769 5.47765 7.28195 5.80181 7.21549 6.11344C7.14903 6.42508 6.99462 6.71114 6.77057 6.93771L5.71223 7.99604C6.89853 10.0823 8.62594 11.8097 10.7122 12.996L11.7706 11.9377C11.9971 11.7137 12.2832 11.5593 12.5948 11.4928C12.9065 11.4263 13.2306 11.4506 13.5289 11.5627C14.285 11.8448 15.0705 12.0405 15.8706 12.146C16.2754 12.2032 16.6451 12.407 16.9093 12.719C17.1736 13.0309 17.314 13.429 17.3039 13.8377Z" stroke="white" strokeOpacity="0.4" stroke-width="1.24818" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                            <div className={`INCGetintouch_inputfeild_division_part ${isError && (!isValidEmail(stdData.emailId)) ? "errorGredient" : null}`}>
                                                <input className='INCGetintouch_input email' type='text' name='emailId' placeholder='Email Id' value={stdData.emailId} onChange={handleEmailId} />
                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_2405_34268)">
                                                        <path d="M4.31706 4.10352H17.6504C18.5671 4.10352 19.3171 4.85352 19.3171 5.77018V15.7702C19.3171 16.6868 18.5671 17.4368 17.6504 17.4368H4.31706C3.40039 17.4368 2.65039 16.6868 2.65039 15.7702V5.77018C2.65039 4.85352 3.40039 4.10352 4.31706 4.10352Z" stroke="#6F7379" stroke-width="1.72214" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19.3171 5.77148L10.9837 11.6048L2.65039 5.77148" stroke="#6F7379" stroke-width="1.72214" stroke-linecap="round" stroke-linejoin="round" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2405_34268">
                                                            <rect width="20" height="20" fill="white" transform="translate(0.980469 0.771484)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </div>
                                        </div>
                                        <div className={`INCGetintouch_inputfeilds_single ${isError && stdData.college === '' ? "errorGredient" : null}`} ref={inputOuterDivRef}>
                                            {/* <input className='INCGetintouch_input college' name='college' type='text' placeholder='Enter College' value={stdData.college} onChange={handleCollegeName} />
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.6673 17.5V15.8333C16.6673 14.9493 16.3161 14.1014 15.691 13.4763C15.0659 12.8512 14.218 12.5 13.334 12.5H6.66732C5.78326 12.5 4.93542 12.8512 4.31029 13.4763C3.68517 14.1014 3.33398 14.9493 3.33398 15.8333V17.5" stroke="white" strokeOpacity="0.4" stroke-width="1.61722" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.99935 9.16569C11.8403 9.16569 13.3327 7.67331 13.3327 5.83236C13.3327 3.99141 11.8403 2.49902 9.99935 2.49902C8.1584 2.49902 6.66602 3.99141 6.66602 5.83236C6.66602 7.67331 8.1584 9.16569 9.99935 9.16569Z" stroke="white" strokeOpacity="0.4" stroke-width="1.61722" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg> */}
                                            <Select
                                                options={collegesOptions}
                                                labelField="name"
                                                valueField="id"
                                                values={collegeValues}
                                                searchable={true}
                                                searchBy='name'
                                                direction='ltr'
                                                sortBy='name'
                                                color='#ffffff'
                                                create={true}
                                                dropdownHeight='200px'
                                                clearable={false}
                                                placeholder='Select College...'

                                                onChange={(values) => {
                                                    if (values.length > 0) {
                                                        setStdData({ ...stdData, ["college"]: values[0].name });
                                                    } else {
                                                        setStdData({ ...stdData, ["college"]: "" });
                                                    }
                                                    setCollegeValues(values)
                                                }}
                                                dropdownPosition='auto'

                                            />
                                        </div>
                                        <div className='INCGetintouch_inputfeilds_part'>
                                            <div className={`INCGetintouch_inputfeild_division_part ${isError && stdData.degree === '' ? "errorGredient" : null}`} ref={inputOuterDivRefPart}>
                                                {/* <select placeholder='Degree' className='degree' name='degree' value={stdData.degree} onChange={handleDegree}>
                                                <option className='jspm' style={{ width: "10px" }}>JSPM</option>
                                                <option>Sinhgad</option>
                                                <option>Zeel</option>
                                            </select> */}
                                                <Select
                                                    options={degreeOptions}
                                                    labelField="name"
                                                    valueField="id"
                                                    values={degreeValues}
                                                    searchable={true}
                                                    searchBy='name'
                                                    direction='ltr'
                                                    sortBy='name'
                                                    color='#ffffff'
                                                    create={true} //for include a other than listed colleges
                                                    dropdownHeight='200px'
                                                    clearable={false}
                                                    placeholder='Select Degree...'
                                                    onChange={(values) => {
                                                        if (values.length > 0) {
                                                            setStdData({ ...stdData, ["degree"]: values[0].name });
                                                        } else {
                                                            setStdData({ ...stdData, ["degree"]: "" });
                                                        }
                                                        setDegreeValues(values)
                                                    }}
                                                    dropdownPosition='auto'
                                                />
                                            </div>
                                            <div className={`INCGetintouch_inputfeild_division_part ${isError && stdData.branch === '' ? "errorGredient" : null}`} >
                                                {/* <select placeholder='Branch' className='branch' name='branch' value={stdData.branch} onChange={handleBranch}>
                                                <option className='jspm' style={{ width: "10px" }}>JSPM</option>
                                                <option>Sinhgad</option>
                                                <option>Zeel</option>
                                            </select> */}
                                                <Select
                                                    options={branchOption}
                                                    labelField="name"
                                                    valueField="id"
                                                    values={branchValues}
                                                    searchable={true}
                                                    searchBy='name'
                                                    direction='ltr'
                                                    sortBy='id'
                                                    color='#ffffff'
                                                    dropdownHeight='200px'
                                                    create={true}
                                                    clearable={false}
                                                    placeholder='Select Branch...'
                                                    onChange={(values) => {
                                                        if (values.length > 0) {
                                                            setStdData({ ...stdData, ["branch"]: values[0].name });
                                                        } else {
                                                            setStdData({ ...stdData, ["branch"]: "" });
                                                        }
                                                        setBranchValues(values)
                                                    }}
                                                    dropdownPosition='auto'
                                                />
                                            </div>
                                        </div>
                                        <div className='INCGetintouch_inputfeilds_part'>
                                            <div className={`INCGetintouch_inputfeild_division_part ${isError && stdData.area_of_interest === '' ? "errorGredient" : null}`}>
                                                {/* <select placeholder='Area of intrest' name='area_of_intrest' className='area_of_intrest' value={stdData.area_of_intrest} onChange={handleAreaOfIntrest}>
                                                <option>Flutter App Development</option>
                                                <option>Sinhgad</option>
                                                <option>Zeel</option>
                                            </select> */}
                                                <Select
                                                    options={areaOfIntrestOptions}
                                                    labelField="name"
                                                    valueField="id"
                                                    values={areaOfInterestValues}
                                                    searchable={true}
                                                    searchBy='name'
                                                    direction='ltr'
                                                    sortBy='name'
                                                    color='#ffffff'
                                                    dropdownHeight='200px'
                                                    clearable={false}
                                                    placeholder='Area of Interest'
                                                    create={true}
                                                    onChange={(values) => {
                                                        if (values.length > 0) {
                                                            setStdData({ ...stdData, ["area_of_interest"]: values[0].name });
                                                        } else {
                                                            setStdData({ ...stdData, ["area_of_interest"]: "" });
                                                        }
                                                        console.log(values)
                                                        setAreaOfInterestValues(values)
                                                    }}
                                                    dropdownPosition='auto'
                                                />
                                            </div>
                                            <div className={`INCGetintouch_inputfeild_division_part ${isError && stdData.referenced_by === '' ? "errorGredient" : null}`}>
                                                <input className='INCGetintouch_input acadamic_year' name='referenced_by' type='text' placeholder='Reference by' value={stdData.referenced_by} onChange={handleReferencedBy} />
                                            </div>
                                        </div>
                                        <div className='INCGetintouch_inputfeilds_single extract'>
                                            <textarea className='INCGetintouch_input message' placeholder='Message (Optional)' name='message' value={stdData.message} onChange={handleMessege} />
                                        </div>

                                    </div>
                                    <div className={`INCGetintouch_submit_container `}>
                                        {/* <div className={`INCGetintouch_submit_btn `} onClick={handleSubmit}>
                                        Submit
                                    </div> */}
                                        <button className={`button ${validateStdData(stdData) ? "btnColor" : null}`} id="btn" onClick={isClicked ? null : handleSubmit}>
                                            <p id="btnText">{buttonText}</p>
                                            <div className="check-box">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                    <path fill="transparent" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                </svg>
                                            </div>
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomNavbar />
        </div>
    )
}

export default Enquiry
