import React from 'react'
import { useSelector } from 'react-redux';
function Feature5() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky);
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                    </div>

                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box feature5'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#F5CD47" />
                            </svg>



                        </div>
                    </div>

                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container'>
                            <div className='INCFeature_asset_container'>
                                <img className='img1' src='/assets/images/features/Gif 2 final.gif' alt='feature1'></img>
                            </div>
                            <div className='INCFeature_text_content'>
                                <div className='INCFeature_text_content_heading'><span>Placement assistance</span> is available to all participants.</div>
                                <div className='INCFeatureList_litext_feature2'>
                                    Receive expert assistance in securing placements through dedicated placement assistance program. We connect interns with industry partners, providing guidance, resources, and support to help you kickstart your career journey with confidence.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature5
