import React from "react"; // Add this line
import "./styleCampusTour.css";
import PropTypes from 'prop-types'; // Add this line for prop type checking
import { useRef } from "react";
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    useMotionValue,
    useVelocity,
    useAnimationFrame,
    wrap // Use wrap from framer-motion
} from "framer-motion";

// Remove interface and use PropTypes for prop type checking
function ParallaxText({ children, baseVelocity = 1000 }) {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 5000], [0, 5], {
        clamp: false
    });

    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 8000);

        /**
         * This is what changes the direction of the scroll once we
         * switch scrolling directions.
         */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseX.set(baseX.get() + moveBy);
    });

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
        <div className="parallax">
            <motion.div className="scroller" style={{ x }}>
                <span>{children} </span>
                <span>{children} </span>
                <span>{children} </span>
                <span>{children} </span>
            </motion.div>
        </div>
    );
}

// Add prop type checking
ParallaxText.propTypes = {
    children: PropTypes.string.isRequired,
    baseVelocity: PropTypes.number
};

export default function ScrollFramer() {
    return (
        <section className="INCScrollFramer">
            <ParallaxText baseVelocity={-5}>
                <img src="/assets/images/about/campusTour/image5.png" alt="/" />
                <img src="/assets/images/about/campusTour/image7.png" alt="/" />
                <img src="/assets/images/about/campusTour/image9.png" alt="/" />
                <img src="/assets/images/about/campusTour/image11.png" alt="/" />
                <img src="/assets/images/about/campusTour/image13.png" alt="/" />
                <img src="/assets/images/about/campusTour/image15.png" alt="/" />
                <img src="/assets/images/about/campusTour/image17.png" alt="/" />
                <img src="/assets/images/about/campusTour/image19.png" alt="/" />
                <img src="/assets/images/about/campusTour/image21.png" alt="/" />
                <img src="/assets/images/about/campusTour/image23.png" alt="/" />

            </ParallaxText>
            <ParallaxText baseVelocity={5}>
                <img src="/assets/images/about/campusTour/image6.png" alt="/" />
                <img src="/assets/images/about/campusTour/image8.png" alt="/" />
                <img src="/assets/images/about/campusTour/image10.png" alt="/" />
                <img src="/assets/images/about/campusTour/image12.png" alt="/" />
                <img src="/assets/images/about/campusTour/image14.png" alt="/" />
                <img src="/assets/images/about/campusTour/image16.png" alt="/" />
                <img src="/assets/images/about/campusTour/image18.png" alt="/" />
                <img src="/assets/images/about/campusTour/image20.png" alt="/" />
                <img src="/assets/images/about/campusTour/image22.png" alt="/" />
            </ParallaxText>
        </section>
    );
}
