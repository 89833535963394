// reducer.js
import { ISCOURSENAVCOLOR, ISFORM, ISNAVCOLOR, LOCATION, SCROLLPOS, SETCOURSESCROLLPOS, SETFLUTTERSCROLLDIV, SETFLUTTERSCROLLTO, SETHOMEPAGEPOS, SETISCOURSEENQUIRY, SETISSTICKYSCROLL, SETMAINCOURSESCROLL, SETOBSERVERELEMENT, SETPROJECTDATA } from './actionTypes';
import initialState from './initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case SCROLLPOS:
      return { ...state, scrollPos: action.payload };
    case ISFORM:
      return { ...state, isForm: action.payload };
    case ISNAVCOLOR:
      return { ...state, isNavColor: action.payload };
    case LOCATION:
      return { ...state, location: action.payload };
    case ISCOURSENAVCOLOR:
      return { ...state, isCourseNavColor: action.payload };
    case SETPROJECTDATA:
      return { ...state, projectData: action.payload };
    case SETISCOURSEENQUIRY:
      return { ...state, isCourseEnq: action.payload };
    case SETOBSERVERELEMENT:
      return { ...state, observerElement: action.payload };
    case SETFLUTTERSCROLLTO:
      return { ...state, flutterScrollTo: action.payload };
    case SETISSTICKYSCROLL:
      return { ...state, isStickyScroll: action.payload };
    case SETHOMEPAGEPOS:
      return { ...state, homePagePos: action.payload };
    case SETFLUTTERSCROLLDIV:
      return { ...state, flutterScrollDivRef: action.payload };
    case SETCOURSESCROLLPOS:
      return { ...state, courseScrollPos: action.payload };
    case SETMAINCOURSESCROLL:
      return { ...state, mainCourseScrollPos: action.payload }
    default:
      return state;
  }
};
export default reducer;