import React, { useEffect, useState } from 'react';
import "./styleNavbar.css"
import { setIsCourseEnquiry, setIsForm } from '../../incubStore/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
function Navbar() {
    const dispatch = useDispatch();
    const scrollPos = useSelector((state) => state.data.scrollPos)
    const [isActive, setIsInactive] = useState(false);
    const isNavColor = useSelector((state) => state.data.isNavColor)
    const navigate = useNavigate();
    const location = useSelector((state) => state.data.location)

    useEffect(() => {
        if (scrollPos > 200) {
            setIsInactive(true);
        } else {
            setIsInactive(false);
        }
    }, [scrollPos])
    return (
        <div className={`incub_navbar ${isActive ? "landing_navbar" : null} ${isNavColor || location === '/about' ? "colorBack" : null}`} style={{ background: location === '/placement' ? '#030F1E' : null, position: location === '/placement' ? 'fixed' : null }}>
            <div className='inc_navbar_container'>
                <div className='inc_navbar_logo' onClick={() => {
                    window.sessionStorage.setItem("homePagePos", 0);
                    window.location.href = "/";

                }}>
                    {/* <img src='/assets/images/landingPage/incubatorNavLogo.png' alt='/' /> */}
                    <img src='/assets/images/landingPage/inc_logo.png' alt='/' onClick={() => {

                        window.location.href = "/";
                    }} />
                </div>
                <div className='inc_navbar_menu'>
                    <ul className="inc_navbar_items" type='none'>
                        <li onClick={() => navigate('/placement')} style={{ color: location === '/placement' ? "#FFFFFF" : null }}>Placement
                            {location === '/placement' ? <hr /> : null}
                        </li>
                        <li style={{ color: location === '/about' ? "#FFFFFF" : null }} onClick={() => navigate("/about")}>About Us
                            {location === '/about' ? <hr /> : null}</li>
                    </ul>
                </div>
                <div className='inc_navbar_menu'>
                    <button className='INCGetintouch_btn' onClick={() => {
                        dispatch(setIsForm(true))
                        dispatch(setIsCourseEnquiry(''))
                    }}>
                        Get in Touch
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.00391 11.3333L11.3372 3" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.00391 3H11.3372V11.3333" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </button>
                </div>
            </div>
        </div >
    )
}

export default Navbar
