const collegesOptions = [
    { id: 1, name: "Abasaheb Garware College" },
    { id: 2, name: "Abhinav Education Society’s Law College" },
    { id: 3, name: "A.K.K. New Law Academy" },
    { id: 4, name: "Annasaheb Magar College" },
    { id: 5, name: "Army Institute of Technology (AIT)" },
    { id: 6, name: "B.J. Government Medical College" },
    { id: 7, name: "Bharati Vidyapeeth Deemed University College of Engineering" },
    { id: 8, name: "Bharati Vidyapeeth Deemed University Dental College & Hospital" },
    { id: 9, name: "Bharati Vidyapeeth Medical College" },
    { id: 10, name: "Bharati Vidyapeeth New Law College" },
    { id: 11, name: "BMCC (Brihan Maharashtra College of Commerce)" },
    { id: 12, name: "Cummins College of Engineering for Women" },
    { id: 13, name: "Deccan College Post Graduate and Research Institute" },
    { id: 14, name: "DES Shri Navalmal Firodia Law College" },
    { id: 15, name: "Dr. D. Y. Patil Institute of Technology" },
    { id: 16, name: "Dr. D. Y. Patil Law College" },
    { id: 17, name: "Dr. D. Y. Patil Medical College" },
    { id: 18, name: "Film and Television Institute of India (FTII)" },
    { id: 19, name: "FLAME University" },
    { id: 20, name: "Fergusson College" },
    { id: 21, name: "Gokhale Institute of Politics and Economics" },
    { id: 22, name: "H.V. Desai College" },
    { id: 23, name: "ILS Law College" },
    { id: 24, name: "Indian Institute of Science Education and Research (IISER) Pune" },
    { id: 25, name: "Indira Institute of Management" },
    { id: 26, name: "International School of Business and Media (ISB&M)" },
    { id: 27, name: "ISB&M School of Business" },
    { id: 28, name: "JSPM's Bhivarabai Sawant Institute of Technology and Research (BSIOTR)" },
    { id: 29, name: "JSPM's Imperial College of Engineering and Research (ICOER)" },
    { id: 30, name: "JSPM's Jayawantrao Sawant College of Engineering (JSCOE)" },
    { id: 31, name: "JSPM's Jayawantrao Sawant Institute of Management and Research (JSIMR)" },
    { id: 32, name: "JSPM's Narhe Technical Campus (NTC)" },
    { id: 33, name: "JSPM's Rajarshi Shahu College of Engineering (RSCOE)" },
    { id: 34, name: "JSPM's Rajarshi Shahu School of Management and Research (RSSOMR)" },
    { id: 35, name: "JSPM's Rajarshi Shahu College of Pharmacy and Research (RSCOPR)" },
    { id: 36, name: "Maharashtra Institute of Dental Sciences & Research (Dental)" },
    { id: 37, name: "Maharashtra Institute of Medical Education and Research (MIMER)" },
    { id: 38, name: "Maharashtra Institute of Technology (MIT)" },
    { id: 39, name: "MIT Academy of Engineering (MITAOE)" },
    { id: 40, name: "MIT Arts, Commerce and Science College" },
    { id: 41, name: "MIT College of Management" },
    { id: 42, name: "MIT School of Business" },
    { id: 43, name: "Modern College of Arts, Science and Commerce" },
    { id: 44, name: "NBN Sinhgad School of Engineering (NBNSOE)" },
    { id: 45, name: "Ness Wadia College of Commerce" },
    { id: 46, name: "Nowrosjee Wadia College" },
    { id: 47, name: "Padmashree Dr. D. Y. Patil Law College" },
    { id: 48, name: "Pimpri Chinchwad College of Engineering (PCCOE)" },
    { id: 49, name: "Poona College of Arts, Science and Commerce" },
    { id: 50, name: "Pune Institute of Business Management (PIBM)" },
    { id: 51, name: "Pune Institute of Computer Technology (PICT)" },
    { id: 52, name: "Savitribai Phule Pune University (SPPU)" },
    { id: 53, name: "Shri Shahu Mandir Mahavidyalaya" },
    { id: 54, name: "Sinhgad College of Architecture" },
    { id: 55, name: "Sinhgad College of Arts and Commerce (SCAC)" },
    { id: 56, name: "Sinhgad College of Engineering (SCOE)" },
    { id: 57, name: "Sinhgad College of Nursing (SCON)" },
    { id: 58, name: "Sinhgad Dental College & Hospital (SDCH)" },
    { id: 59, name: "Sinhgad Institute of Management (SIM)" },
    { id: 60, name: "Sinhgad Institute of Management and Computer Application (SIMCA)" },
    { id: 61, name: "Sinhgad Institute of Pharmacy (SIOP)" },
    { id: 62, name: "Sinhgad Institute of Technology (SIT)" },
    { id: 63, name: "Sinhgad Institute of Technology and Science (SITS)" },
    { id: 64, name: "Sir Parashurambhau College (SP College)" },
    { id: 65, name: "Spicer Adventist University" },
    { id: 66, name: "St. Mira's College for Girls" },
    { id: 67, name: "Suryadatta Institute of Management" },
    { id: 68, name: "Symbiosis Centre for Management and Human Resource Development (SCMHRD)" },
    { id: 69, name: "Symbiosis College of Arts and Commerce" },
    { id: 70, name: "Symbiosis Institute of Business Management (SIBM)" },
    { id: 71, name: "Symbiosis Law School" },
    { id: 72, name: "Symbiosis Medical College for Women" },
    { id: 73, name: "Tilak Maharashtra Vidyapeeth" },
    { id: 74, name: "Vishwakarma Global Business School" },
    { id: 75, name: "Vishwakarma Institute of Information Technology (VIIT)" },
    { id: 76, name: "Vishwakarma Institute of Technology (VIT)" },
    { id: 77, name: "Zeal College of Commerce" },
    { id: 78, name: "Zeal College of Engineering and Research (ZCOER)" },
    { id: 79, name: "Zeal Institute of Business Administration, Computer Application and Research (ZIBACAR)" }
];


const degreeOptions = [
    {
        id: "1",
        deg: "BA",
        name: "BA-First Year"
    },
    {
        id: "2",
        deg: "BA",
        name: "BA-Second Year"
    },
    {
        id: "3",
        deg: "BA",
        name: "BA-Third Year"
    },
    {
        id: "4",
        deg: "BA",
        name: "BA-Passout"
    },
    {
        id: "5",
        deg: "BSc",
        name: "BSc-First Year"
    },
    {
        id: "6",
        deg: "BSc",
        name: "BSc-Second Year"
    },
    {
        id: "7",
        deg: "BSc",
        name: "BSc-Third Year"
    },
    {
        id: "8",
        deg: "BSc",
        name: "BSc-Passout"
    },
    {
        id: "9",
        deg: "BCom",
        name: "BCom-First Year"
    },
    {
        id: "10",
        deg: "BCom",
        name: "BCom-Second Year"
    },
    {
        id: "11",
        deg: "BCom",
        name: "BCom-Third Year"
    },
    {
        id: "12",
        deg: "BCom",
        name: "BCom-Passout"
    },
    {
        id: "13",
        deg: "BE",
        name: "BE-First Year"
    },
    {
        id: "14",
        deg: "BE",
        name: "BE-Second Year"
    },
    {
        id: "15",
        deg: "BE",
        name: "BE-Third Year"
    },
    {
        id: "16",
        deg: "BE",
        name: "BE-Fourth Year"
    },
    {
        id: "17",
        deg: "BE",
        name: "BE-Passout"
    },
    {
        id: "18",
        deg: "BTech",
        name: "BTech-First Year"
    },
    {
        id: "19",
        deg: "BTech",
        name: "BTech-Second Year"
    },
    {
        id: "20",
        deg: "BTech",
        name: "BTech-Third Year"
    },
    {
        id: "21",
        deg: "BTech",
        name: "BTech-Fourth Year"
    },
    {
        id: "22",
        deg: "BTech",
        name: "BTech-Passout"
    },
    {
        id: "23",
        deg: "MSc",
        name: "MSc-First Year"
    },
    {
        id: "24",
        deg: "MSc",
        name: "MSc-Second Year"
    },
    {
        id: "25",
        deg: "MSc",
        name: "MSc-Passout"
    },
    {
        id: "26",
        deg: "MCom",
        name: "MCom-First Year"
    },
    {
        id: "27",
        deg: "MCom",
        name: "MCom-Second Year"
    },
    {
        id: "28",
        deg: "MCom",
        name: "MCom-Passout"
    },
    {
        id: "29",
        deg: "MA",
        name: "MA-First Year"
    },
    {
        id: "30",
        deg: "MA",
        name: "MA-Second Year"
    },
    {
        id: "31",
        deg: "MA",
        name: "MA-Passout"
    },
    {
        id: "32",
        deg: "MBA",
        name: "MBA-First Year"
    },
    {
        id: "33",
        deg: "MBA",
        name: "MBA-Second Year"
    },
    {
        id: "34",
        deg: "MBA",
        name: "MBA-Passout"
    },
    {
        id: "35",
        deg: "MCA",
        name: "MCA-First Year"
    },
    {
        id: "36",
        deg: "MCA",
        name: "MCA-Second Year"
    },
    {
        id: "37",
        deg: "MCA",
        name: "MCA-Third Year"
    },
    {
        id: "38",
        deg: "MCA",
        name: "MCA-Passout"
    }
];


const branchOptions = [
    // BE Branches
    {
        id: "1",
        degree: "BE",
        name: "Computer Engineering"
    },
    {
        id: "2",
        degree: "BE",
        name: "Mechanical Engineering"
    },
    {
        id: "3",
        degree: "BE",
        name: "Civil Engineering"
    },
    {
        id: "4",
        degree: "BE",
        name: "Electrical Engineering"
    },
    {
        id: "5",
        degree: "BE",
        name: "Electronics & Telecommunication Engineering"
    },

    // BTech Branches
    {
        id: "6",
        degree: "BTech",
        name: "Information Technology"
    },

    // Other Degrees
    {
        id: "7",
        degree: "BA",
        name: "Economics"
    },
    {
        id: "8",
        degree: "BSc",
        name: "Physics"
    },
    {
        id: "9",
        degree: "BCom",
        name: "Commerce"
    },
    {
        id: "10",
        degree: "MBA",
        name: "Finance"
    },
    {
        id: "11",
        degree: "MBA",
        name: "Marketing"
    },
    {
        id: "12",
        degree: "",
        name: "Other"
    }

];


const areaOfIntrestOptions = [
    {
        id: "1",
        name: "Flutter app Development",
    },
    {
        id: "2",
        name: "Web Development",
    },
    {
        id: "3",
        name: "Backend Development",
    },

];

// Export options individually
export { degreeOptions, branchOptions, areaOfIntrestOptions, collegesOptions };
