import React, { useEffect, useRef } from 'react';
import "./styleFlutterCertificate.css";
import { useDispatch, useSelector } from 'react-redux';
import { setObserverElement } from '../../../incubStore/actions';
function FlutterCertificate() {
    const dispatch = useDispatch();
    const resourceDivRef = useRef();
    const observerElement = useSelector((state) => state.data.observerElement);

    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp9 !== resourceDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                // comp9: resourceDivRef.current.getBoundingClientRect().top,
                comp9: resourceDivRef.current.offsetTop,

            };
            dispatch(setObserverElement(updatedMap));
        }
    }, [dispatch, observerElement]);
    return (
        <div className='INCFlutterCertificate' ref={resourceDivRef}>
            <div className='INCFluttercertificate_container'>
                <div className='INCFluttercertificate_certificate_holder'>
                    <div className='INCFluttercertificate_blur_container'>
                        <svg className='svg1' width="199" height="353" viewBox="0 0 199 353" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.954156 135.722C4.02823 197.612 107.589 355.699 169.479 352.625C231.369 349.551 178.094 206.607 157.756 116.581C147.228 69.9828 182.191 -2.94662 120.301 0.127458C58.411 3.20153 -2.11992 73.8323 0.954156 135.722Z" fill="white" fillOpacity="0.1" />
                        </svg>
                        <svg className='svg2' width="211" height="211" viewBox="0 0 211 211" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_2405_18586)">
                                <circle cx="65.6488" cy="65.6488" r="65.6488" transform="matrix(1 0 0 -1 40.2734 171.593)" fill="url(#paint0_linear_2405_18586)" />
                            </g>
                            <defs>
                                <filter id="filter0_f_2405_18586" x="0.884148" y="0.905876" width="210.075" height="210.076" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="19.6946" result="effect1_foregroundBlur_2405_18586" />
                                </filter>
                                <linearGradient id="paint0_linear_2405_18586" x1="118.168" y1="141.801" x2="-12.2541" y2="116.832" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5D50C6" />
                                    <stop offset="1" stopColor="#F85E9F" />
                                </linearGradient>
                            </defs>
                        </svg>
                        <svg className='svg3' width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_f_2405_19148)">
                                <circle cx="14.9086" cy="14.9086" r="14.9086" transform="matrix(-1 0 0 1 36.8164 7.01855)" fill="#FACD49" />
                            </g>
                            <defs>
                                <filter id="filter0_f_2405_19148" x="0.0770335" y="0.0955882" width="43.6623" height="43.6632" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                    <feGaussianBlur stdDeviation="3.46148" result="effect1_foregroundBlur_2405_19148" />
                                </filter>
                            </defs>
                        </svg>

                    </div>
                    <img className='INFlutterCertificate_img' src='/assets/images/flutter/certificate/Certificate.png' alt='flutter certificate' />
                </div>
            </div>
        </div>
    )
}

export default FlutterCertificate
