import React, { useEffect, useRef } from 'react'; // Importing React library
import "./styleFlutterLanding.css" // Importing custom CSS for styling
import { Typewriter } from 'react-simple-typewriter'; // Importing Typewriter component from react-simple-typewriter
import { useDispatch, useSelector } from 'react-redux';
import { setIsCourseEnquiry, setIsCourseNavColor, setIsForm, setObserverElement, setScrollPos } from '../../../incubStore/actions';

// Functional component FlutterLanding
function FlutterLanding() {
    const dispatch = useDispatch();
    const scrollableInnerDivRef = useRef(null);
    const scrollPos = useSelector((state) => state.data.scrollPos)
    const observerElement = useSelector((state) => state.data.observerElement);

    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp1 !== scrollableInnerDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                // comp1: scrollableInnerDivRef.current.getBoundingClientRect().top,
                comp1: scrollableInnerDivRef.current.offsetTop,

            };
            dispatch(setObserverElement(updatedMap));
            console.log(scrollableInnerDivRef.current.offsetTop)
        }
    }, [dispatch, observerElement]);
    useEffect(() => {
        if (scrollPos > (scrollableInnerDivRef.current.clientHeight - scrollableInnerDivRef.current.clientHeight * (45 / 100))) {
            dispatch(setIsCourseNavColor(true))
        } else {
            dispatch(setIsCourseNavColor(false))
        }
    }, [scrollPos])
    useEffect(() => {
        dispatch(setScrollPos(0))
    }, [scrollableInnerDivRef.current])
    return (
        <div className='INCFlutter_landingpage' ref={scrollableInnerDivRef}>
            <div className='INCFlutter_landingpage_container'>
                {/* Heading section with Typewriter effect */}
                <div className='INCFlutter_landingpage_heading_container'>
                    <p>
                        Mobile App Development Training & Internship Program <br /> using
                        <span>

                            <Typewriter
                                words={[' < Flutter >']} // Words to type
                                loop={true} // Loop the typing effect
                                cursor // Display cursor
                                cursorStyle='' // Custom cursor style
                                typeSpeed={200} // Typing speed
                                deleteSpeed={0} // Deleting speed
                                delaySpeed={1000} // Delay before typing starts again
                            />

                        </span>
                    </p>
                </div>
                {/* Image section */}
                <div className='INCFlutter_landingpage_image_container'>
                    <img className='INCFlutter_landingpage_mobile_images_gropup' src='/assets/images/flutter/landingpage/fluttermobileLandingimg.png' alt='flutter_app_mobile_img' />
                </div>
                {/* Information section */}
                <div className='INCFlutter_landingpage_info_container'>
                    {/* Start Date information */}
                    <div className='INCFlutter_landingpage_info start_date'>
                        <div className='INCFlutter_landingpage_info_heading'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8333 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663C2.5 17.5868 3.24619 18.333 4.16667 18.333H15.8333C16.7538 18.333 17.5 17.5868 17.5 16.6663V4.99967C17.5 4.0792 16.7538 3.33301 15.8333 3.33301Z" stroke="#33EFA0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M13.334 1.66699V5.00033" stroke="#33EFA0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M6.66602 1.66699V5.00033" stroke="#33EFA0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M2.5 8.33301H17.5" stroke="#33EFA0" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> Start Date
                        </div>
                        <div className='INCFlutter_landingpage_info_text'>July 21, 2024</div>
                    </div>
                    {/* Duration information */}
                    <div className='INCFlutter_landingpage_info duration'>
                        <div className='INCFlutter_landingpage_info_heading'>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_4182_4655)">
                                    <path d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z" stroke="#8E81FD" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M10 5V10L13.3333 11.6667" stroke="#8E81FD" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_4182_4655">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg> Duration
                        </div>
                        <div className='INCFlutter_landingpage_info_text'>6 Months</div>
                    </div>
                    {/* Fees information */}
                    <div className='INCFlutter_landingpage_info fees'>
                        <div className='INCFlutter_landingpage_info_heading'>
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.416 13.1022V5.76888C17.416 4.76055 16.591 3.93555 15.5827 3.93555H2.74935C1.74102 3.93555 0.916016 4.76055 0.916016 5.76888V13.1022C0.916016 14.1105 1.74102 14.9355 2.74935 14.9355H15.5827C16.591 14.9355 17.416 14.1105 17.416 13.1022ZM15.5827 13.1022H2.74935V5.76888H15.5827V13.1022ZM9.16602 6.68555C7.64435 6.68555 6.41602 7.91388 6.41602 9.43555C6.41602 10.9572 7.64435 12.1855 9.16602 12.1855C10.6877 12.1855 11.916 10.9572 11.916 9.43555C11.916 7.91388 10.6877 6.68555 9.16602 6.68555ZM21.0827 6.68555V16.7689C21.0827 17.7772 20.2577 18.6022 19.2494 18.6022H3.66602V16.7689H19.2494V6.68555H21.0827Z" fill="#FACD49" />
                            </svg> Fees
                        </div>
                        <div className='INCFlutter_landingpage_info_text'>₹ 10,000 <span>&nbsp;+ 18% GST </span></div>
                    </div>
                </div>
                {/* Register Now button */}
                <div className='INCFlutter_landingpage_button_container'>
                    <div className='INCFlutter_landingpage_registerNow_btn' onClick={() => {
                        dispatch(setIsForm(true))
                        dispatch(setIsCourseEnquiry('flutter'))
                    }}>
                        Register Now
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlutterLanding; // Exporting the component for use in other parts of the application
