import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setIsCourseEnquiry, setIsForm } from '../../incubStore/actions';

function NoDetailNavbar() {
    const dispatch = useDispatch();
    const location = useSelector((state) => state.data.location)
    return (
        <div className={`incub_navbar couresNavbar roadmap`}>
            <div className='inc_navbar_container couresNavbar'>
                <div className='inc_navbar_logo couresNavbar'>
                    {/* <img src='/assets/images/landingPage/incubatorNavLogo.png' alt='/' /> */}
                    <div className='flutter_Navbar_upparpart roadmap'>
                        <img onClick={() => {
                            window.sessionStorage.setItem("homePagePos", 0);
                            window.location.href = "/";
                        }} src='/assets/images/landingPage/inc_logo.png' alt='/' />
                        {location !== "/enquiry" ? <div className='INCNavbar_course_info_container'>
                            Mobile App Development <span>Roadmap</span>
                        </div> : null}
                        {
                            location !== "/enquiry" ? <div className='INCNavbar_btn_container inactive roadmap' >
                                <div className='INCGetintouch_btn' onClick={() => {
                                    dispatch(setIsForm(true))
                                    dispatch(setIsCourseEnquiry('flutter'));
                                }}>
                                    Register Now
                                </div>
                            </div> : null
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoDetailNavbar
