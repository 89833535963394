import React, { useEffect, useState } from 'react';
import "./stylePblearning.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from '../../../incubStore/actions';

function PBLearning() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate()


    // useEffect to handle window resize events and update the windowWidth state
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        console.log(windowWidth)
        // Add the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const [isHovered, setIsHovered] = useState("third");

    const handleMouseEnter = (e) => {
        setIsHovered(e);
    };

    const handleMouseLeave = () => {
        setIsHovered("third");
    };


    const [fadeClass, setFadeClass] = useState('');

    const [currentUrl, setCurrentUrl] = useState("/assets/images/pbLearning/redbusCross mobile.png")
    const url = {
        first: "/assets/images/pbLearning/crossmobileZomato.png",
        second: "/assets/images/pbLearning/phonepeCross mobile.png",
        third: "/assets/images/pbLearning/redbusCross mobile.png",
        fourth: "/assets/images/pbLearning/meshoCross mobile.png",
        fifth: "/assets/images/pbLearning/hotstarCross mobile.png",
    };


    /*app name for specific url by hover*/

    const [currentData, setCurrentData] = useState({
        year: "2006",
        name: "Redbus"
    })
    const UrlData = {
        first: {
            year: "2006",
            name: "Zomato"
        }
        ,

        second: {
            year: "2015",
            name: "Phonepe"
        }
        ,

        third: {
            year: "2006",
            name: "Redbus"
        }
        ,

        fourth: {
            year: "2007",
            name: "Myntra"
        }
        ,

        fifth: {
            year: "2015",
            name: "Hotstar"
        }
    }
    useEffect(() => {
        if (isHovered) {
            setFadeClass('fade-out');
            const timeout = setTimeout(() => {
                setCurrentUrl(url[isHovered])
                setCurrentData(UrlData[isHovered])
            }, 500); // Match the duration of the transition in CSS
            const timeout2 = setTimeout(() => {
                setFadeClass('');
            }, 700);
            return () => {
                clearTimeout(timeout);
                clearTimeout(timeout2)
            };
        }
    }, [isHovered]);
    const [appData] = useState([
        {
            appName: "Myntra",
            year: "2018"

        },
        {
            appName: "Hotstar",
            year: "2015"

        },
        {
            appName: "Redbus",
            year: "2006"

        },
        {
            appName: "Zomato",
            year: "2006"

        },
        {
            appName: "PhonePe",
            year: "2015"

        }, {
            appName: "Dream11",
            year: "2008"

        }])

    const [app, setApp] = useState(appData[0])
    const handleAppChange = (e) => {
        setTimeout(() => {
            setApp(appData[e.realIndex])
        }, 800);
    };
    return (
        <div className='INCPBLearning'>
            <div className='INCPBLearning_container'>
                <div className="INCPBLearning_info">
                    <div className='INCProjectBased_learning_info_container'>
                        <div className='INCProjectbased_learning_heading'>
                            Project-Based{windowWidth > 1024 ? <br /> : " "}<span>Learning</span>
                        </div>
                        <div className='INCProjectbased_learning_moto'>Real-time Industry Projects as part of the Internship Program.</div>
                        <div className='INCProjectbased_learning_btn'>
                            <div className='INCProgram_btn'>
                                <div className='INCProgram_btn_text_holder' onClick={() => {
                                    navigate("/programs/flutter")
                                    window.sessionStorage.setItem("flutterPagePos", 0)
                                }}>
                                    <div className='INCBackground_hover'></div>
                                    <div className='INCProgram_btn_text'>Explore
                                        <svg width="44" height="12" viewBox="0 0 44 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M38.9051 0.889465C38.6462 0.630595 38.2265 0.630595 37.9676 0.889465C37.7087 1.14834 37.7087 1.56805 37.9676 1.82692L41.4761 5.33537L0.936317 5.33537C0.570219 5.33537 0.273437 5.63215 0.273438 5.99825C0.273438 6.36435 0.570219 6.66113 0.936317 6.66113L41.4763 6.66113L37.9676 10.1698C37.7087 10.4286 37.7087 10.8484 37.9676 11.1072C38.2265 11.3661 38.6462 11.3661 38.9051 11.1072L43.5452 6.46707C43.8041 6.2082 43.8041 5.78849 43.5452 5.52962L38.9051 0.889465Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                                <div className='INCPBFlutter_logo_container'>
                                    <img src='/assets/images/landingPage/INCFlutterIcon.png' className='INCPBLearning_flutter_logo' alt="/" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {windowWidth > 540 && windowWidth < 1041 ?


                    <div className='INCPBLearning_responsive'>
                        <div className='INCPBLearning_app_info'>
                            <div className='INCPBLearning_app_name_year'>
                                <div className='INCPBLearning_app_time'>2015</div>
                                <div className='INCPBLearning_app_name'>Phonepe</div>
                            </div>
                            <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.49992 9.16679C6.84278 9.16679 8.74204 7.26754 8.74204 4.9247C8.74204 2.58186 6.84278 0.682617 4.49992 0.682617C2.15707 0.682617 0.257812 2.58186 0.257812 4.9247C0.257812 7.26754 2.15707 9.16679 4.49992 9.16679Z" fill="#00C2FF" />
                            </svg>


                        </div>
                    </div> : null
                }
                <div className="INCPBLearning_assets">
                    {
                        windowWidth > 1024 ?

                            <div className='INCPBLearning_mobiles_container'>
                                <div className='INCPBLearning_mobiles'>
                                    <div className={`INCPBLearning_app_info_container ${isHovered === "first" ? 'fade' : null}`}
                                        onMouseEnter={(e) => handleMouseEnter("first")}
                                        onMouseLeave={handleMouseLeave}>
                                        <div className='INCPBLearning_app_info'>
                                            <div className='INCPBLearning_app_name_year'>
                                                <div className='INCPBLearning_app_time'>2006</div>
                                                <div className='INCPBLearning_app_name'>Zomato</div>
                                            </div>
                                            <svg width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.23633 0.648438V51.5534" stroke="#CCCCCC" strokeWidth="0.876426" strokeLinecap="round" />
                                                <path d="M5.09758 62.5838C7.44043 62.5838 9.33969 60.6845 9.33969 58.3417C9.33969 55.9989 7.44043 54.0996 5.09758 54.0996C2.75473 54.0996 0.855469 55.9989 0.855469 58.3417C0.855469 60.6845 2.75473 62.5838 5.09758 62.5838Z" fill="#CCCCCC" />
                                            </svg>

                                        </div>
                                        <img className='INCApp_images' src='/assets/images/pbLearning/INCzomatoapp.png' alt='INC_zomato_app' />
                                    </div>
                                    <div className={`INCPBLearning_app_info_container bottomup-app ${isHovered === "second" ? 'fade' : null}`}
                                        onMouseEnter={(e) => handleMouseEnter("second")}
                                        onMouseLeave={handleMouseLeave}>
                                        <div className='INCPBLearning_app_info'>
                                            <div className='INCPBLearning_app_name_year'>
                                                <div className='INCPBLearning_app_time'>2015</div>
                                                <div className='INCPBLearning_app_name'>Phonepe</div>
                                            </div>
                                            <svg width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.23633 0.648438V51.5534" stroke="#CCCCCC" strokeWidth="0.876426" strokeLinecap="round" />
                                                <path d="M5.09758 62.5838C7.44043 62.5838 9.33969 60.6845 9.33969 58.3417C9.33969 55.9989 7.44043 54.0996 5.09758 54.0996C2.75473 54.0996 0.855469 55.9989 0.855469 58.3417C0.855469 60.6845 2.75473 62.5838 5.09758 62.5838Z" fill="#CCCCCC" />
                                            </svg>

                                        </div>
                                        <img className='INCApp_images' src='/assets/images/pbLearning/phonepe app.png' alt='INC_zomato_app' />
                                    </div>
                                    <div className={`INCPBLearning_app_info_container ${isHovered === "third" ? 'fade' : null}`}
                                        onMouseEnter={(e) => handleMouseEnter("third")}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <div className='INCPBLearning_app_info'>
                                            <div className='INCPBLearning_app_name_year'>
                                                <div className='INCPBLearning_app_time'>2006</div>
                                                <div className='INCPBLearning_app_name'>Redbus</div>
                                            </div>
                                            <svg width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.23633 0.648438V51.5534" stroke="#CCCCCC" strokeWidth="0.876426" strokeLinecap="round" />
                                                <path d="M5.09758 62.5838C7.44043 62.5838 9.33969 60.6845 9.33969 58.3417C9.33969 55.9989 7.44043 54.0996 5.09758 54.0996C2.75473 54.0996 0.855469 55.9989 0.855469 58.3417C0.855469 60.6845 2.75473 62.5838 5.09758 62.5838Z" fill="#CCCCCC" />
                                            </svg>
                                        </div>
                                        <img className='INCApp_images' src='/assets/images/pbLearning/Red bus app.png' alt='INC_zomato_app' />
                                    </div>
                                    <div className={`INCPBLearning_app_info_container bottomup-app ${isHovered === "fourth" ? 'fade' : null}`} onMouseEnter={(e) => handleMouseEnter("fourth")}
                                        onMouseLeave={handleMouseLeave}>
                                        <div className='INCPBLearning_app_info'>
                                            <div className='INCPBLearning_app_name_year'>
                                                <div className='INCPBLearning_app_time'>2007</div>
                                                <div className='INCPBLearning_app_name'>Myntra</div>
                                            </div>
                                            <svg width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.23633 0.648438V51.5534" stroke="#CCCCCC" strokeWidth="0.876426" strokeLinecap="round" />
                                                <path d="M5.09758 62.5838C7.44043 62.5838 9.33969 60.6845 9.33969 58.3417C9.33969 55.9989 7.44043 54.0996 5.09758 54.0996C2.75473 54.0996 0.855469 55.9989 0.855469 58.3417C0.855469 60.6845 2.75473 62.5838 5.09758 62.5838Z" fill="#CCCCCC" />
                                            </svg>

                                        </div>
                                        <img className='INCApp_images' src='/assets/images/pbLearning/mesho app.png' alt='INC_zomato_app' />
                                    </div>
                                    <div className={`INCPBLearning_app_info_container ${isHovered === "fifth" ? 'fade' : null}`} onMouseEnter={(e) => handleMouseEnter("fifth")}
                                        onMouseLeave={handleMouseLeave}>
                                        <div className='INCPBLearning_app_info'>
                                            <div className='INCPBLearning_app_name_year'>
                                                <div className='INCPBLearning_app_time'>2015</div>
                                                <div className='INCPBLearning_app_name'>Hotstar</div>
                                            </div>
                                            <svg width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.23633 0.648438V51.5534" stroke="#CCCCCC" strokeWidth="0.876426" strokeLinecap="round" />
                                                <path d="M5.09758 62.5838C7.44043 62.5838 9.33969 60.6845 9.33969 58.3417C9.33969 55.9989 7.44043 54.0996 5.09758 54.0996C2.75473 54.0996 0.855469 55.9989 0.855469 58.3417C0.855469 60.6845 2.75473 62.5838 5.09758 62.5838Z" fill="#CCCCCC" />
                                            </svg>
                                        </div>
                                        <img className='INCApp_images' src='/assets/images/pbLearning/hotstar app.png' alt='INC_zomato_app' />
                                    </div>
                                    <div className='INCCross_mobiles_holder'>
                                        <div className='INCCross_mobile_holder'>
                                            <div className={`INCMobileFrame ${isHovered}`}>
                                                <div className='INCPBLearning_app_info' style={{ opacity: "0" }}>
                                                    <div className='INCPBLearning_app_name_year' >
                                                        <div className='INCPBLearning_app_time' style={{ background: "rgba(238, 70, 97, 1)", border: "0.88px solid rgba(238, 70, 97, 1)" }}>2006</div>
                                                        <div className='INCPBLearning_app_name' style={{ color: 'rgba(238, 70, 97, 1)' }}>Zomato</div>
                                                    </div>
                                                    <svg width="10" height="63" viewBox="0 0 10 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.23633 0.648438V51.5534" stroke="#CCCCCC" strokeWidth="0.876426" strokeLinecap="round" />
                                                        <path d="M5.09758 62.5838C7.44043 62.5838 9.33969 60.6845 9.33969 58.3417C9.33969 55.9989 7.44043 54.0996 5.09758 54.0996C2.75473 54.0996 0.855469 55.9989 0.855469 58.3417C0.855469 60.6845 2.75473 62.5838 5.09758 62.5838Z" fill="#CCCCCC" />
                                                    </svg>
                                                </div>
                                                <img src='/assets/images/pbLearning/crossmobileframe.png' alt='mobile frame' />
                                            </div>
                                            <div className={`INCMobileFrame ${isHovered}`}>
                                                <div className='INCPBLearning_app_info'>
                                                    <div className='INCPBLearning_app_name_year'>
                                                        <div className='INCPBLearning_app_time' style={{ background: "rgba(238, 70, 97, 1)", border: "0.88px solid rgba(238, 70, 97, 1)" }}>{currentData.year}</div>
                                                        <div className='INCPBLearning_app_name' style={{ color: 'rgba(238, 70, 97, 1)' }}>{currentData.name}</div>
                                                    </div>
                                                    <svg width="9" height="63" viewBox="0 0 9 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.70703 0.479492V51.3845" stroke="#EE4661" strokeWidth="0.876426" strokeLinecap="round" />
                                                        <path d="M4.56828 62.4158C6.91114 62.4158 8.8104 60.5166 8.8104 58.1737C8.8104 55.8309 6.91114 53.9316 4.56828 53.9316C2.22543 53.9316 0.326172 55.8309 0.326172 58.1737C0.326172 60.5166 2.22543 62.4158 4.56828 62.4158Z" fill="#EE4661" />
                                                    </svg>

                                                </div>
                                                <img src={currentUrl}
                                                    className={`transition-image ${fadeClass}`} alt='mobile-app' />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> : <div className='INCPBLearning_tab_design_holder_container'>
                                {windowWidth < 541 ?
                                    <div className='INCPBLearning_app_info'>
                                        <div className='INCPBLearning_app_name_year'>
                                            <div className='INCPBLearning_app_time'>{app ? app.year : ""}</div>
                                            <div className='INCPBLearning_app_name'>{app ? app.appName : ""}</div>
                                        </div>
                                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.49992 9.16679C6.84278 9.16679 8.74204 7.26754 8.74204 4.9247C8.74204 2.58186 6.84278 0.682617 4.49992 0.682617C2.15707 0.682617 0.257812 2.58186 0.257812 4.9247C0.257812 7.26754 2.15707 9.16679 4.49992 9.16679Z" fill="#00C2FF" />
                                        </svg>

                                    </div>
                                    : null}

                                <div className='INCPBLearning_tab_design_holder'>
                                    <div className='INCPBLearning_tab_design_mobile_frame_holder'>
                                        <img className='mobile_frame' src="/assets/images/pbLearning/Subtractmobile_frame_png.png" alt="Slide 1" />
                                    </div>
                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={windowWidth > 540 ? -200 : 20}
                                        slidesPerView={windowWidth > 540 ? 5 : 1.9}
                                        centeredSlides={true}
                                        onSlideChange={(e) => {
                                            handleAppChange(e)
                                        }}
                                        loop={true}
                                        speed={1500}
                                        autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                                    >
                                        <SwiperSlide>
                                            <div className='INCPBLearning_tab_design_img_holder'>
                                                <img src="/assets/images/pbLearning/mesho app.png" alt="Slide 1" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCPBLearning_tab_design_img_holder'>
                                                <img src="/assets/images/pbLearning/hotstar app.png" alt="Slide 2" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCPBLearning_tab_design_img_holder'>
                                                <img src="/assets/images/pbLearning/Red bus app.png" alt="Slide 3" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCPBLearning_tab_design_img_holder'>
                                                <img src="/assets/images/pbLearning/INCzomatoapp.png" alt="Slide 4" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCPBLearning_tab_design_img_holder'>
                                                <img src="/assets/images/pbLearning/phonepe app.png" alt="Slide 5" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCPBLearning_tab_design_img_holder'>
                                                <img src="/assets/images/pbLearning/dream11 app.png" alt="Slide 5" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div></div>}
                </div>
            </div>
        </div >
    )
}

export default PBLearning

// const [isHovered, setIsHovered] = useState("first");

// const url = [{
//     first: "/assets/images/pbLearning/crossmobileZomato.png",
//     second: "/assets/images/pbLearning/hotstarCross mobile.png",
//     third: "/assets/images/pbLearning/meshoCross mobile.png",
//     fourth: "/assets/images/pbLearning/redbusCross mobile.png",
//     fifth: "/assets/images/pbLearning/phonepeCross mobile.png"
// }]

// return (
//     <img src={url[isHovered]}/>
//  )