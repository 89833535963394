import React, { useEffect, useRef } from 'react';
import "./styleFlutterPrqquisite.css"
import { setObserverElement } from '../../../incubStore/actions';
import { useDispatch, useSelector } from 'react-redux';

function FlutterPrequisite() {
    const dispatch = useDispatch();
    const resourceDivRef = useRef();
    const observerElement = useSelector((state) => state.data.observerElement);

    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp6 !== resourceDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                comp6: resourceDivRef.current.offsetTop,
            };
            dispatch(setObserverElement(updatedMap));
        }
    }, [dispatch, observerElement]);
    return (
        <div className='INCFlutterPrequisite' ref={resourceDivRef}>
            <div className='INCFlutterPrequisite_container'>
                <div className='INCFlutterPrequisite_container_holder'>
                    <div className='INCFLutterPrequisite_heading_container'>
                        <div className='INCFLutterPrequisite_heading'>Who can <span>Apply</span></div>
                        <div className='INCFLutterPrequisite_heading_text'>Open to anyone interested in mobile app development, with no prerequisites required.</div>
                    </div>
                    <div className='INCFLutterPrequisite_content_box_container'>
                        <div className='INCFLutterPrequisite_content_box'>
                            <div className='INCFLutterPrequisite_content_box_heading'>No <span>Experience</span> Necessary</div>
                            <div className='INCFLutterPrequisite_content_box_text'>This course is designed to be accessible to everyone, regardless of their programming background. Whether you're a complete beginner or an experienced developer looking to expand your skillset, I will guide you through each step of the process, ensuring you gain a deep understanding of the fundamentals and advanced concepts.</div>
                        </div>
                        <div className='INCFLutterPrequisite_content_box'>
                            <div className='INCFLutterPrequisite_content_box_heading'><span>No</span>  Prerequisites</div>
                            <div className='INCFLutterPrequisite_content_box_text'>
                                <div className='INCFLutterPrequisite_content_box_inner'>
                                    <div className='INCFLutterPrequisite_content_box_inner_text part1'>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.09766 13.014C10.8489 13.014 12.4844 11.4357 12.4844 7.6272C12.4844 11.4357 14.1085 13.014 17.8712 13.014C14.1085 13.014 12.4844 14.638 12.4844 18.4007C12.4844 14.638 10.8489 13.014 7.09766 13.014Z" stroke="#00C2FF" strokeWidth="1.38517" strokeLinejoin="round" />
                                            <path d="M2.48242 6.47319C4.89399 6.47319 5.94535 5.45857 5.94535 3.01025C5.94535 5.45857 6.98938 6.47319 9.40829 6.47319C6.98938 6.47319 5.94535 7.51721 5.94535 9.93612C5.94535 7.51721 4.89399 6.47319 2.48242 6.47319Z" stroke="#00C2FF" strokeWidth="1.38517" strokeLinejoin="round" />
                                        </svg>
                                        <div>Basic Programming Language Knowledge will Help But is Not Required.</div>
                                    </div>
                                    <div className='INCFLutterPrequisite_content_box_inner_text part2'>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.09766 13.014C10.8489 13.014 12.4844 11.4357 12.4844 7.6272C12.4844 11.4357 14.1085 13.014 17.8712 13.014C14.1085 13.014 12.4844 14.638 12.4844 18.4007C12.4844 14.638 10.8489 13.014 7.09766 13.014Z" stroke="#00C2FF" strokeWidth="1.38517" strokeLinejoin="round" />
                                            <path d="M2.48242 6.47319C4.89399 6.47319 5.94535 5.45857 5.94535 3.01025C5.94535 5.45857 6.98938 6.47319 9.40829 6.47319C6.98938 6.47319 5.94535 7.51721 5.94535 9.93612C5.94535 7.51721 4.89399 6.47319 2.48242 6.47319Z" stroke="#00C2FF" strokeWidth="1.38517" strokeLinejoin="round" />
                                        </svg>
                                        <div>No Prior Knowledge of Flutter or Dart is Needed.</div>
                                    </div>
                                    <div className='INCFLutterPrequisite_content_box_inner_text part3'>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.09766 13.014C10.8489 13.014 12.4844 11.4357 12.4844 7.6272C12.4844 11.4357 14.1085 13.014 17.8712 13.014C14.1085 13.014 12.4844 14.638 12.4844 18.4007C12.4844 14.638 10.8489 13.014 7.09766 13.014Z" stroke="#00C2FF" strokeWidth="1.38517" strokeLinejoin="round" />
                                            <path d="M2.48242 6.47319C4.89399 6.47319 5.94535 5.45857 5.94535 3.01025C5.94535 5.45857 6.98938 6.47319 9.40829 6.47319C6.98938 6.47319 5.94535 7.51721 5.94535 9.93612C5.94535 7.51721 4.89399 6.47319 2.48242 6.47319Z" stroke="#00C2FF" strokeWidth="1.38517" strokeLinejoin="round" />
                                        </svg>
                                        <div>No IOS (Swift/ObjectiveC) or Android (Java/Kotlin) Development Experience Required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlutterPrequisite
