import React, { useRef, useState, useEffect } from 'react';
import "./styleMobileNavbar.css";
import { useDispatch } from 'react-redux';
import { setIsCourseEnquiry, setIsForm } from '../../incubStore/actions';
import { useNavigate } from 'react-router-dom';

function MobileNavbar() {
    const menuRef = useRef(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpenMenu(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div className='INCMobile_navbar'>
            <div className='INCMobile_navbar-container'>
                <div className='INCMobile_navbar_logoContainer'>
                    <img src='/assets/images/landingPage/inc_logo.png' alt='Logo' onClick={() => {

                        window.location.href = "/";
                    }} />
                </div>
                <div className='INCNavbar_mobile_Content_Container'>
                    <div className='INCMobile_nav_burger' onClick={() => setIsOpenMenu(true)}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 20L33.3333 20" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.33203 9.33398H34.6654" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.33203 30.667H26.6654" stroke="white" strokeWidth="3.33333" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    {isOpenMenu && (
                        <div className='INCMobile_nav_content_holder' ref={menuRef}>
                            <div className='INCMbile_cross_cancel' onClick={() => setIsOpenMenu(false)}>
                                <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.50391 33.792L33.4151 7.8808" stroke="white" strokeWidth="3.29498" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M33.4141 33.792L7.50287 7.8808" stroke="white" strokeWidth="3.29498" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                            <div className='INCMobile_nav_content_items'>
                                <div className='INCMobile_nav_content_item' onClick={() => {
                                    navigate("/placement");
                                    setIsOpenMenu(false)
                                }}>Placement</div>
                                <div className='INCMobile_nav_content_item' onClick={() => {
                                    navigate("/about")
                                    setIsOpenMenu(false)
                                }
                                }>About Us</div>
                                <div className='INCMobile_nav_getintouch_btn' onClick={() => {
                                    dispatch(setIsForm(true))
                                    dispatch(setIsCourseEnquiry(''))
                                }}>
                                    Get in Touch
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.01562 13.0615L13.626 3.45117" stroke="white" strokeWidth="1.92207" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M4.01562 3.45117H13.626V13.0615" stroke="white" strokeWidth="1.92207" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MobileNavbar;
