import React, { useEffect, useState } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import "./stylePlacementPage.css";
import PlacementLandingPage from './PlacementLandingPage.js/PlacementLandingPage';
import PlacementFeature1 from './placementFeature/PlacementFeature1';
import PlacementFeature2 from './placementFeature/PlacementFeature2';
import PlacementFeature3 from './placementFeature/PlacementFeature3';
import PlacementFeature4 from './placementFeature/PlacementFeature4';
import PlacementFeature5 from './placementFeature/PlacementFeature5';
import PlacementFeature6 from './placementFeature/PlacementFeature6';
import PlacementFeature7 from './placementFeature/PlacementFeature7';
import PlacementFeature8 from './placementFeature/PlacementFeature8';
import BottomNavbar from '../../components/layouts/BottomNavbar';
import { useDispatch } from 'react-redux';
import { setLocation } from '../../incubStore/actions';

gsap.registerPlugin(ScrollTrigger);

function PlacementPage() {
    const component = useRef();
    const slider = useRef();
    const [activePanel, setActivePanel] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLocation("/placement"))
    }, [])
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useLayoutEffect(() => {
        if (windowWidth < 1025) {
            return
        }
        let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".panel");
            console.log(panels)
            gsap.to(panels, {
                xPercent: -100 * (panels.length - 1),
                ease: "none",
                animation: "none",
                scrollTrigger: {
                    trigger: slider.current,
                    pin: true,
                    scrub: 0,
                    snap: 1 / (panels.length - 1),
                    end: () => "+=" + slider.current.offsetWidth,
                    markers: false,
                    toggleClass: "stick",
                    onUpdate: (self) => {
                        let activeIndex = Math.round(self.progress * (panels.length - 1));
                        setActivePanel(activeIndex)
                    }
                }
            });

        }, component);
        return () => ctx.revert();
    }, []);

    return (
        <div className="INCPlacementPage">
            <div className="INCPlacementPage_scroll_container">
                <div className="INCPlacementPage_main_container" ref={component}>
                    <PlacementLandingPage />
                    <div ref={slider} className="container">
                        <svg className='INCPlacement_background_vector' width="874" height="432" viewBox="0 0 874 432" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g opacity="0.1">
                                <g opacity="0.25">
                                    <path d="M638.109 438.869L637.799 439.777L561.754 660.446L771.905 582.996L638.109 438.869ZM563.67 658.493L638.607 441.172L769.801 582.503L563.67 658.493Z" fill="white" />
                                    <path d="M384.851 249.566L362.004 416.246L363.171 416.397L386.042 249.706L384.851 249.566Z" fill="white" />
                                    <path d="M385.046 248.022L361.494 419.572L423.116 302.795L422.929 302.511L385.046 248.022ZM363.611 413.037L385.8 251.204L421.75 302.906L363.611 413.037Z" fill="white" />
                                    <path d="M636.894 439.33L770.87 583.659L881.586 453.394L636.894 439.33ZM770.842 581.881L639.757 440.728L879.18 454.46L770.842 581.881Z" fill="white" />
                                    <path d="M638.217 439.432L638.271 440.63L881.15 454.523L870.068 409.562L869.535 409.662L638.217 439.432ZM879.596 453.269L644.752 439.813L869.186 410.929L879.596 453.269Z" fill="white" />
                                    <path d="M955.01 301.67L868.963 410.14L869.068 410.433L880.185 455.663L955.01 301.67ZM870.256 410.376L949.192 310.939L880.561 452.166L870.256 410.376Z" fill="white" />
                                    <path d="M952.486 304.082L879.584 454.102L880.006 454.394L971.817 518.039L971.71 516.723L952.486 304.082ZM881.139 453.708L951.67 308.518L970.386 515.592L881.139 453.708Z" fill="white" />
                                    <path d="M22.364 446.549L46.7473 500.158L80.5686 423.657L22.364 446.549ZM46.7459 497.23L23.991 447.202L78.3377 425.809L46.7459 497.23Z" fill="white" />
                                    <path d="M659.405 357.166L636.267 445.522L672.431 372.646L659.405 357.166ZM640.362 434.563L660.01 359.705L670.945 372.811L640.362 434.563Z" fill="white" />
                                    <path d="M671.409 372.06L637.354 440.736L638.452 440.606L873.489 410.367L671.409 372.06ZM639.379 439.275L672.044 373.392L865.867 410.138L639.379 439.275Z" fill="white" />
                                    <path d="M884.849 14.3018L843.239 142.783L923.127 98.4589L884.849 14.3018ZM845.323 140.244L885.045 17.595L921.61 97.9836L845.323 140.244Z" fill="white" />
                                    <path d="M843.841 141.136L658.923 358.423L671.688 373.71L844.773 141.849L843.841 141.136ZM660.508 358.413L832.693 156.042L671.667 371.757L660.508 358.413Z" fill="white" />
                                    <path d="M844.741 139.935L670.646 373.135L870.375 410.977L870.303 410.185L844.741 139.935ZM672.747 372.35L843.861 143.149L868.994 409.53L672.747 372.35Z" fill="white" />
                                    <path d="M843.449 139.193L843.697 141.582L869.214 411.796L952.887 306.31L952.62 305.977L843.449 139.193ZM870.145 408.757L845.109 143.924L951.398 306.277L870.145 408.757Z" fill="white" />
                                    <path d="M884.362 15.8179L843.737 141.378L844.873 141.715L885.522 16.1431L884.362 15.8179Z" fill="white" />
                                    <path d="M505.915 183.213L384.55 249.444L422.422 303.91L505.915 183.213ZM386.283 249.822L502.08 186.606L422.42 301.807L386.283 249.822Z" fill="white" />
                                    <path d="M503.685 184.413L385.123 249.114L385.701 250.13L504.274 185.453L503.685 184.413Z" fill="white" />
                                    <path d="M78.6236 423.959L78.8763 424.896L142.139 648.662L142.871 648.045L336.322 467.954L78.6236 423.959ZM142.763 646.474L80.2953 425.473L333.762 468.748L142.763 646.474Z" fill="white" />
                                    <path d="M79.6196 424.154L79.3931 425.309L334.993 468.95L335.174 467.759L79.6196 424.154Z" fill="white" />
                                    <path d="M362.028 416.035L334.571 468.065L335.627 468.608L363.109 416.567L362.028 416.035Z" fill="white" />
                                    <path d="M362.591 414.956L334.343 468.426L334.565 468.723L461.615 644.999L362.591 414.956ZM335.768 468.318L362.494 417.729L456.176 635.392L335.768 468.318Z" fill="white" />
                                    <path d="M79.8608 422.366L46.1297 498.683L98.0781 612.895L79.8608 422.366ZM47.4221 498.664L79.0933 427.036L96.178 605.857L47.4221 498.664Z" fill="white" />
                                    <path d="M78.8691 424.772L96.5509 609.546L114.41 648.81L143.253 648.142L80.0651 424.54L78.8691 424.772ZM97.728 609.21L80.7227 431.263L141.707 646.969L115.191 647.602L97.728 609.21Z" fill="white" />
                                    <path d="M922.645 97.4072L841.629 142.302L939.636 134.763L922.645 97.4072ZM846.944 140.765L922.103 99.0753L937.862 133.723L846.944 140.765Z" fill="white" />
                                    <path d="M843.21 141.036L951.972 307.238L997.366 261.775L939.097 133.667L843.21 141.036ZM952.184 305.351L845.326 142.064L938.351 134.887L995.945 261.512L952.184 305.351Z" fill="white" />
                                    <path d="M659.231 185.443L659.245 185.92L659.144 360.078L660.186 358.809L845.923 140.524L659.231 185.443ZM660.33 356.838L660.469 186.387L842.676 142.541L660.33 356.838Z" fill="white" />
                                    <path d="M361.716 415.927L362.005 416.563L458.982 641.851L552.856 361.65L551.74 361.93L361.716 415.927ZM458.858 638.525L363.373 416.708L550.994 363.406L458.858 638.525Z" fill="white" />
                                    <path d="M551.62 361.484L551.348 362.347L457.443 642.733L639.143 439.985L551.62 361.484ZM460.389 637.64L552.15 363.602L637.459 440.097L460.389 637.64Z" fill="white" />
                                    <path d="M422.145 302.081L421.887 302.596L361.342 417.29L553.6 362.675L422.145 302.081ZM363.725 415.41L422.676 303.63L550.218 362.423L363.725 415.41Z" fill="white" />
                                    <path d="M503.221 184.323L503.423 185.084L551.7 364.016L552.385 362.851L660.843 185.345L503.221 184.323ZM552.133 361.09L504.812 185.533L658.724 186.536L552.133 361.09Z" fill="white" />
                                    <path d="M550.42 361.992L638.679 441.096L660.504 357.801L550.42 361.992ZM638 438.904L553.449 363.058L658.935 359.055L638 438.904Z" fill="white" />
                                    <path d="M660.417 183.788L659.304 185.602L550.844 363.166L551.984 363.131L660.281 359.014L660.306 358.434L660.417 183.788ZM552.999 361.93L659.198 188.038L659.097 357.875L552.999 361.93Z" fill="white" />
                                    <path d="M558.747 360.816C557.023 357.024 552.446 355.354 548.588 357.108C544.659 358.895 542.911 363.443 544.636 367.235C546.382 371.074 550.948 372.721 554.877 370.934C558.734 369.179 560.494 364.656 558.747 360.816Z" fill="white" />
                                    <path d="M667.209 182.315C665.463 178.475 660.886 176.805 656.981 178.581C653.124 180.336 651.375 184.883 653.121 188.722C654.857 192.538 659.423 194.185 663.28 192.431C667.186 190.654 668.945 186.13 667.209 182.315Z" fill="white" />
                                    <path d="M666.417 356.01C664.671 352.17 660.129 350.513 656.248 352.278C652.342 354.054 650.607 358.567 652.353 362.407C654.089 366.222 658.631 367.88 662.536 366.104C666.418 364.339 668.153 359.825 666.417 356.01Z" fill="white" />
                                    <path d="M875.718 405.95C873.972 402.111 869.406 400.464 865.524 402.229C861.643 403.995 859.884 408.519 861.63 412.358C863.366 416.174 867.953 417.868 871.834 416.102C875.716 414.337 877.454 409.766 875.718 405.95Z" fill="white" />
                                    <path d="M511.434 181.289C509.699 177.473 505.133 175.826 501.275 177.581C497.37 179.357 495.622 183.905 497.346 187.697C499.093 191.536 503.659 193.183 507.564 191.407C511.445 189.641 513.181 185.128 511.434 181.289Z" fill="white" />
                                    <path d="M370.748 413.474C369.002 409.634 364.425 407.964 360.544 409.729C356.639 411.506 354.914 416.042 356.66 419.882C358.396 423.697 362.938 425.355 366.843 423.579C370.725 421.813 372.484 417.289 370.748 413.474Z" fill="white" />
                                    <path d="M85.6922 420.751C83.9567 416.935 79.4146 415.277 75.5332 417.043C71.6279 418.819 69.8688 423.343 71.6043 427.159C73.3506 430.998 77.9273 432.668 81.8326 430.892C85.714 429.127 87.4385 424.59 85.6922 420.751Z" fill="white" />
                                    <path d="M429.735 300.054C427.989 296.214 423.423 294.567 419.541 296.333C415.66 298.098 413.912 302.646 415.647 306.461C417.383 310.277 421.959 311.948 425.841 310.182C429.722 308.417 431.471 303.869 429.735 300.054Z" fill="white" />
                                    <path d="M650.865 436.942C647.522 429.593 638.74 426.367 631.288 429.757C623.837 433.146 620.51 441.851 623.863 449.223C627.195 456.548 635.932 459.738 643.384 456.349C650.835 452.96 654.197 444.267 650.865 436.942Z" fill="white" />
                                    <path d="M859.118 138.98C855.775 131.631 847.003 128.429 839.552 131.818C832.101 135.207 828.75 143.923 832.092 151.272C835.435 158.621 844.196 161.799 851.647 158.41C859.099 155.021 862.46 146.328 859.118 138.98Z" fill="white" />
                                </g>
                                <path d="M925.898 104.534L838.352 228.525L973.058 208.217L925.898 104.534ZM925.677 106.909L971.316 207.247L840.958 226.942L925.677 106.909Z" fill="white" />
                                <path d="M1025.95 324.496L972.536 207.057L838.432 227.29L985.909 381.341L1025.95 324.496ZM971.822 208.348L1024.6 324.374L985.814 379.479L840.873 228.141L971.822 208.348Z" fill="white" />
                                <path d="M355.067 321.739L246.757 369.184L394.071 464.579L355.067 321.739ZM354.314 323.389L392.111 461.861L249.273 369.375L354.314 323.389Z" fill="white" />
                                <path d="M353.417 320.347L392.447 463.374L392.622 463.949L464.812 442.115L353.417 320.347ZM355.886 324.824L462.639 441.596L393.45 462.463L355.886 324.824Z" fill="white" />
                                <path d="M425.468 218.898L354.112 322.222L353.86 322.621L464.638 443.731L425.468 218.898ZM424.806 221.957L462.764 439.865L355.392 322.492L424.806 221.957Z" fill="white" />
                                <path d="M658.844 334.395L462.363 441.899L678.634 529.524L679.543 529.935L658.844 334.395ZM657.812 336.257L678.145 528.069L465.094 441.737L657.812 336.257Z" fill="white" />
                                <path d="M828.627 336.922L657.67 334.746L678.415 530.578L828.627 336.922ZM826.263 338.083L679.277 527.513L659.002 335.959L826.263 338.083Z" fill="white" />
                                <path d="M853.642 374.662L854.067 374.326L827.481 336.473L678.343 528.674L679.211 529.501L853.642 374.662ZM852.479 374.139L683.194 524.335L827.393 338.503L852.479 374.139Z" fill="white" />
                                <path d="M827.566 336.937L667.342 307.053L657.521 335.938L827.422 338.083L827.566 336.937ZM668.091 308.446L820.539 336.808L659.179 334.815L668.091 308.446Z" fill="white" />
                                <path d="M798.669 244.669L798.462 244.023L665.807 308L828.329 338.232L798.669 244.669ZM797.755 245.71L826.562 336.762L669.598 307.498L797.755 245.71Z" fill="white" />
                                <path d="M826.005 336.506L852.78 374.586L852.933 374.857L991.228 381.258L826.005 336.506ZM828.887 338.521L980.439 379.599L853.572 373.657L828.887 338.521Z" fill="white" />
                                <path d="M840.336 226.818L797.363 244.545L827.7 340.359L840.336 226.818ZM838.917 228.657L827.154 334.581L798.801 245.227L838.917 228.657Z" fill="white" />
                                <path d="M840.101 227.339L839.19 226.417L826.839 337.469L826.781 337.978L987.756 381.593L840.101 227.339ZM840.108 229.07L983.9 379.282L828.125 337.055L840.108 229.07Z" fill="white" />
                                <path d="M800.148 244.494L613.712 220.363L612.427 220.208L667.503 308.494L800.148 244.494ZM796.032 245.172L667.945 306.985L614.828 221.674L796.032 245.172Z" fill="white" />
                                <path d="M650.746 315.859L425.377 219.864L424.35 219.45L463.255 442.811L650.746 315.859ZM648.303 316.145L464.141 440.816L425.908 221.413L648.303 316.145Z" fill="white" />
                                <path d="M659.183 335.602L649.841 315.063L463.401 441.339L464.017 442.31L659.183 335.602ZM657.596 335.102L474.455 435.316L649.304 316.871L657.596 335.102Z" fill="white" />
                                <path d="M614.182 220.766L614.045 220.402L422.234 219.865L649.3 316.537L650.567 317.098L614.182 220.766ZM613.198 221.526L648.478 314.921L428.089 221.039L613.198 221.526Z" fill="white" />
                                <path d="M668.687 306.621L648.729 315.699L658.413 336.991L668.687 306.621ZM666.688 308.838L658.286 333.723L650.348 316.27L666.688 308.838Z" fill="white" />
                                <path d="M614.132 220.665L613.089 221.168L649.211 316.795L668.57 307.99L614.132 220.665ZM616.344 226.481L666.855 307.462L649.892 315.178L616.344 226.481Z" fill="white" />
                                <path d="M465.022 440.826L392.356 462.82L409.83 534.296L465.022 440.826ZM462.444 442.852L410.284 531.162L393.778 463.595L462.444 442.852Z" fill="white" />
                                <path d="M463.996 441.308L463.51 441.131L408.995 533.354L681.797 529.63L463.996 441.308ZM464.009 442.609L675.882 528.511L411.113 532.163L464.009 442.609Z" fill="white" />
                                <path d="M855.671 371.307L678.421 528.594L677.592 529.312L751.746 548.798L855.671 371.307ZM850.837 377.144L751.151 547.363L680.047 528.736L850.837 377.144Z" fill="white" />
                                <path d="M853.73 373.836L853.19 373.286L750.438 548.652L1040.64 570.725L853.73 373.836ZM853.386 375.243L1037.67 569.265L752.445 547.597L853.386 375.243Z" fill="white" />
                                <path d="M986.424 380.258L986.348 379.837L851.84 373.561L1038.73 570.345L1040.4 572.173L986.424 380.258ZM985.429 380.994L1037.94 567.778L854.788 374.893L985.429 380.994Z" fill="white" />
                                <path d="M150.188 474.522L100.108 443.376L107.125 458.803L149.684 475.576L150.188 474.522ZM102.873 446.467L143.992 472.025L108.059 457.867L102.873 446.467Z" fill="white" />
                                <path d="M250.752 367.193L100.273 444.876L150.012 475.78L250.752 367.193ZM245.251 371.373L149.798 474.229L102.677 444.948L245.251 371.373Z" fill="white" />
                                <path d="M247.893 368.557L148.436 475.707L394.91 463.746L247.893 368.557ZM248.072 370.095L391.126 462.738L151.266 474.363L248.072 370.095Z" fill="white" />
                                <path d="M393.486 462.604L145.411 474.668L410.841 533.565L393.486 462.604ZM392.54 463.83L409.209 531.948L154.302 475.399L392.54 463.83Z" fill="white" />
                                <path d="M411.186 532.391L150.035 474.447L148.523 474.111L283.159 668.654L411.186 532.391ZM408.888 533.124L283.301 666.742L151.227 475.923L408.888 533.124Z" fill="white" />
                                <path d="M679.388 528.43L407.137 532.16L680.188 645.638L681.007 645.976L679.388 528.43ZM678.218 529.673L679.817 644.187L412.97 533.288L678.218 529.673Z" fill="white" />
                                <path d="M475.235 435.365C477.839 441.088 475.203 447.944 469.357 450.603C463.511 453.262 456.635 450.733 454.031 445.009C451.396 439.215 454.029 432.417 459.875 429.758C465.721 427.099 472.6 429.571 475.235 435.365Z" fill="white" />
                                <path d="M257.035 364.281C259.114 368.851 256.999 374.247 252.351 376.361C247.727 378.464 242.246 376.522 240.168 371.953C238.079 367.36 240.183 361.94 244.831 359.826C249.455 357.723 254.935 359.665 257.035 364.281Z" fill="white" />
                                <path d="M359.82 319.667C361.277 322.871 359.841 326.707 356.559 328.2C353.252 329.704 349.441 328.254 347.984 325.051C346.516 321.824 347.949 318.045 351.255 316.541C354.514 315.059 358.352 316.441 359.82 319.667Z" fill="white" />
                                <path d="M807.811 241.619C809.889 246.188 807.821 251.621 803.149 253.746C798.525 255.849 793.057 253.873 790.968 249.28C788.89 244.711 790.969 239.302 795.617 237.188C800.289 235.062 805.733 237.049 807.811 241.619Z" fill="white" />
                                <path d="M847.555 224.717C849.312 228.579 847.553 233.103 843.696 234.858C839.791 236.634 835.225 234.987 833.468 231.124C831.732 227.309 833.491 222.785 837.397 221.008C841.254 219.254 845.82 220.901 847.555 224.717Z" fill="white" />
                                <path d="M860.514 372.528C862.25 376.344 860.491 380.868 856.609 382.633C852.728 384.399 848.162 382.752 846.427 378.936C844.68 375.097 846.439 370.573 850.321 368.807C854.202 367.042 858.757 368.665 860.514 372.528Z" fill="white" />
                                <path d="M621.619 219.066C623.344 222.858 621.617 227.452 617.76 229.207C613.855 230.983 609.256 229.265 607.532 225.473C605.785 221.634 607.544 217.11 611.45 215.334C615.296 213.556 619.873 215.226 621.619 219.066Z" fill="white" />
                                <path d="M673.663 305.425C675.399 309.241 673.64 313.765 669.782 315.519C665.877 317.295 661.311 315.648 659.575 311.833C657.829 307.993 659.578 303.446 663.483 301.67C667.34 299.915 671.917 301.586 673.663 305.425Z" fill="white" />
                                <path d="M662.091 333.228C663.098 335.442 662.065 338.129 659.789 339.165C657.489 340.211 654.856 339.191 653.849 336.977C652.821 334.716 653.804 332.108 656.104 331.062C658.38 330.027 661.063 330.967 662.091 333.228Z" fill="white" />
                                <path d="M652.59 313.233C653.618 315.494 652.598 318.147 650.298 319.193C648.022 320.228 645.352 319.254 644.324 316.992C643.317 314.778 644.337 312.126 646.613 311.09C648.913 310.044 651.583 311.019 652.59 313.233Z" fill="white" />
                                <path d="M833.156 332.614C834.903 336.454 833.168 340.967 829.286 342.732C825.381 344.508 820.839 342.85 819.093 339.011C817.346 335.172 819.071 330.635 822.976 328.859C826.857 327.094 831.41 328.775 833.156 332.614Z" fill="white" />
                                <path d="M440.029 215.66C443.372 223.009 440.044 231.714 432.569 235.114C425.07 238.525 416.346 235.302 413.003 227.953C409.661 220.604 412.988 211.899 420.463 208.499C427.938 205.099 436.687 208.312 440.029 215.66Z" fill="white" />
                            </g>
                        </svg>

                        {windowWidth > 1024 ? <div className='INCPlacementPage_dot_container'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > -1 ? "#8F7EE7" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 0 ? "#DA62AC" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 1 ? "#F15B50" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 2 ? "#F38A3F" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 3 ? "#F5CD47" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 4 ? "#94C748" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 5 ? "#4BCE97" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill={activePanel > 6 ? "#579DFF" : "rgba(53, 59, 67, 1)"} />
                            </svg>
                        </div> : null
                        }
                        <div className="description panel"><PlacementFeature1 /></div>
                        <div className="panel"><PlacementFeature2 /></div>
                        <div className="panel"><PlacementFeature3 /></div>
                        <div className="panel"><PlacementFeature4 /></div>
                        <div className="panel"><PlacementFeature5 /></div>
                        <div className="panel"><PlacementFeature6 /></div>
                        <div className="panel"><PlacementFeature7 /></div>
                        <div className="panel"><PlacementFeature8 /></div>
                    </div>
                    {/* <div className="lastContainer">Last Container</div> */}
                    <BottomNavbar />
                </div>
            </div>
        </div>
    );
}

export default PlacementPage;
