import React, { useEffect, useRef, useState } from 'react';
import "./styleLandingPage.css";
import { Typewriter } from 'react-simple-typewriter';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCourseEnquiry, setIsForm, setIsNavColor } from '../../../incubStore/actions';

import { useNavigate } from 'react-router-dom';



function LandingPage() {
    const [showDiv, setShowDiv] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const scrollableInnerDivRef = useRef(null);
    const scrollPos = useSelector((state) => state.data.scrollPos);
    const dispatch = useDispatch();
    const navigate = useNavigate();



    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDiv(true);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (scrollPos > scrollableInnerDivRef.current.clientHeight) {
            dispatch(setIsNavColor(true))
        } else {
            dispatch(setIsNavColor(false))
        }
    }, [scrollPos])
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className='INCLanding_page' ref={scrollableInnerDivRef}>
            <div className='INCLanding_page_gradient'>
                <div className='INCLanding_container'>
                    <div className='INCLanding_content_container'>{showDiv ?
                        <div className='INCLanding_text_Container'>
                            <div className='INCLanding_text'>
                                <p className='INCLanding_tagline'>Are you ready to <span>Enhance</span> your skills?
                                    <svg className='INCLandingPage_text_bigStar' width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.53 0.355227L19.3536 11.8883L27.9939 19.5295L16.4608 19.3531L8.81956 27.9934L8.99599 16.4603L0.35571 8.81908L11.8888 8.9955L19.53 0.355227Z" fill="url(#paint0_linear_2405_13641)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_2405_13641" x1="19.53" y1="0.355227" x2="8.81956" y2="27.9934" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#00C2FF" />
                                                <stop offset="1" stopColor="#0083CC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <svg className='INCLandingPage_text_smallStar' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.6038 0.00443714L9.8497 5.20315L17.211 4.60486L12.0123 9.85077L12.6105 17.212L7.36464 12.0133L0.00337536 12.6116L5.20209 7.3657L4.6038 0.00443714Z" fill="url(#paint0_linear_2405_13640)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_2405_13640" x1="4.6038" y1="0.00443714" x2="12.6105" y2="17.212" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#00C2FF" />
                                                <stop offset="1" stopColor="#0083CC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                </p>
                                <div className='INCLanding_offer'>Enroll in our intensive</div>
                                <div className='INCLanding_moto'><Typewriter
                                    words={['Training & Internship']}
                                    loop={true}
                                    cursor
                                    cursorStyle=''
                                    typeSpeed={200}
                                    deleteSpeed={0}
                                    delaySpeed={1000}
                                /></div>
                                <div className='INCLanding_tagline_paraEnd'>journeys<span>.</span></div>
                            </div>
                        </div> : null
                    }
                        {showDiv ? (windowWidth > 600 ? <div className='INCLanding_kidImage_Container'>
                            <div className='INCLanding_kidImage_outerLayer'>
                                <img className='INCLanding_kidImage_outerLayer_containerImage' src='/assets/images/landingPage/image 17.png' alt='/' />
                                <div className='INCLanding_kidImage_overlayer-container'>
                                    <div className='INCLanding_kidImage_overlayer'>
                                        <img src='/assets/images/landingPage/INC_boy_img.png' className='INCLanding_boy_image' alt='/' />
                                    </div>
                                    <img src='/assets/images/landingPage/INC_boy_head.png' className='INCLanding_boy_image INCLanding_boy_image_head' alt='/' />
                                </div>
                                <img className='INCLanding_logoImages' src='/assets/images/landingPage/INCLanding_logoiImages.png' alt='/' />
                            </div>
                        </div> : <div className='INCLanding_kidImage_Container'>
                            <img className='INCMobileBoyImg' src='/assets/images/landingPage/mobileLandingBoyImg.png' alt='/' />
                        </div>) : null
                        }
                    </div>
                    <div className='INCLanding_bottomgredient_container'>

                    </div>
                </div>
            </div>{
                showDiv ?
                    <div className='INCPrograms_container'>

                        <div className='INCLandingswiper_holder'>
                            {
                                windowWidth > 1025 ?

                                    <Swiper
                                        modules={[Autoplay]}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        loop={true}
                                        autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
                                        speed={1500}
                                    ><SwiperSlide>
                                            <div className='INCProgram_info_holder'>
                                                <div className='INCProgram_info_card'>
                                                    <div className='INCProgram_templete_part'>
                                                        <img className='INCProgram_templete_thumbnail_img' src='/assets/images/landingPage/flutterdevMobile.png' alt='' />
                                                    </div>
                                                    <div className='INCProgram_templete_part2'>
                                                        <div className='INCProgram_heading'>
                                                            <div className='INCProgram_techLogo'>
                                                                <img src='/assets/images/landingPage/INCFlutterIcon.png' alt='/' />
                                                                <img src='/assets/images/landingPage/INCDartIcon.png' alt='/' />
                                                            </div>
                                                            <div className='INCProgram_techHead'>Flutter App Development</div>
                                                        </div>
                                                        <div className='INCProgram_moto'>
                                                            Develop Flutter skills, UI/UX design, and app development portfolio featuring real-world projects
                                                        </div>
                                                        <div className='INCProgram_content'>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    10+<span>Live Projects</span>
                                                                </div>
                                                            </div>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    175 <span>Assignments</span>
                                                                </div>
                                                            </div>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    50 <span>Quiz / Notes</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='INCProgram_btn'>
                                                            <div className='INCProgram_btn_text_holder' onClick={() => {
                                                                navigate("/programs/flutter")
                                                                window.sessionStorage.setItem("flutterPagePos", 0)
                                                            }}>
                                                                <div className='INCBackground_hover'></div>
                                                                <div className='INCProgram_btn_text'>Explore
                                                                    <svg width="44" height="12" viewBox="0 0 44 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M38.9051 0.889465C38.6462 0.630595 38.2265 0.630595 37.9676 0.889465C37.7087 1.14834 37.7087 1.56805 37.9676 1.82692L41.4761 5.33537L0.936317 5.33537C0.570219 5.33537 0.273437 5.63215 0.273438 5.99825C0.273438 6.36435 0.570219 6.66113 0.936317 6.66113L41.4763 6.66113L37.9676 10.1698C37.7087 10.4286 37.7087 10.8484 37.9676 11.1072C38.2265 11.3661 38.6462 11.3661 38.9051 11.1072L43.5452 6.46707C43.8041 6.2082 43.8041 5.78849 43.5452 5.52962L38.9051 0.889465Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCProgram_info_holder'>
                                                <div className='INCProgram_info_card INCUpcommingProgram'>
                                                    <div className='INCProgram_templete_part'>
                                                        <img className='INCProgram_templete_thumbnail_img' src='/assets/images/landingPage/webDevProgramThumb.png' alt='' />
                                                    </div>
                                                    <div className='INCProgram_templete_part2'>
                                                        <div className='INCProgram_heading INCProgram_heading_upcomming'>
                                                            <div className='INCProgram_techLogo'>
                                                                <img src='/assets/images/landingPage/reactLogo.png' alt='/' />
                                                                <img src='/assets/images/landingPage/htmlLogo.png' alt='/' />
                                                                <img src='/assets/images/landingPage/cssLogo.png' alt='/' />
                                                                <img src='/assets/images/landingPage/jsLogo.png' alt='/' />
                                                            </div>
                                                            <div className='INCProgram_techHead'>Web Development</div>
                                                        </div>
                                                        <div className='INCProgram_moto'>
                                                            Elevate your frontend expertise with ReactJS hands-on internship
                                                        </div>
                                                        <div className='INCProgram_content'>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    10+<span>Live Projects</span>
                                                                </div>
                                                            </div>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    175 <span>Assignments</span>
                                                                </div>
                                                            </div>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    50 <span>Quiz / Notes</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='INCProgram_btn' onClick={() => {
                                                            dispatch(setIsForm(true))
                                                            dispatch(setIsCourseEnquiry('frontend'))
                                                        }}>
                                                            <div className='INCProgram_btn_text_holder'>
                                                                <div className='INCBackground_hover'></div>
                                                                <div className='INCProgram_btn_text'>Drop Inquiry
                                                                    <svg width="44" height="12" viewBox="0 0 44 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M38.9051 0.889465C38.6462 0.630595 38.2265 0.630595 37.9676 0.889465C37.7087 1.14834 37.7087 1.56805 37.9676 1.82692L41.4761 5.33537L0.936317 5.33537C0.570219 5.33537 0.273437 5.63215 0.273438 5.99825C0.273438 6.36435 0.570219 6.66113 0.936317 6.66113L41.4763 6.66113L37.9676 10.1698C37.7087 10.4286 37.7087 10.8484 37.9676 11.1072C38.2265 11.3661 38.6462 11.3661 38.9051 11.1072L43.5452 6.46707C43.8041 6.2082 43.8041 5.78849 43.5452 5.52962L38.9051 0.889465Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='INCProgram_info_holder'>
                                                <div className='INCProgram_info_card INCUpcommingProgram'>
                                                    <div className='INCProgram_templete_part'>
                                                        <img className='INCProgram_templete_thumbnail_img' src='/assets/images/landingPage/backDevProgramThumb.png' alt='' />
                                                    </div>
                                                    <div className='INCProgram_templete_part2'>
                                                        <div className='INCProgram_heading INCProgram_heading_upcomming'>
                                                            <div className='INCProgram_techLogo'>
                                                                <img src='/assets/images/landingPage/SpringLogo.png' alt='/' />
                                                                <img src='/assets/images/landingPage/javalogo.png' alt='/' />
                                                            </div>
                                                            <div className='INCProgram_techHead'>Backend Development</div>
                                                        </div>
                                                        <div className='INCProgram_moto'>
                                                            Level up your backend skills with Spring Boot in our hands-on internship
                                                        </div>
                                                        <div className='INCProgram_content'>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    10+<span>Live Projects</span>
                                                                </div>
                                                            </div>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    175 <span>Assignments</span>
                                                                </div>
                                                            </div>
                                                            <div className='INCContent_count_holder'>
                                                                <div className='vl'></div>
                                                                <div className='INCContent_count'>
                                                                    50 <span>Quiz / Notes</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='INCProgram_btn' onClick={() => {
                                                            dispatch(setIsForm(true))
                                                            dispatch(setIsCourseEnquiry('backend'))
                                                        }}>
                                                            <div className='INCProgram_btn_text_holder'>
                                                                <div className='INCBackground_hover'></div>
                                                                <div className='INCProgram_btn_text'>Drop Inquiry
                                                                    <svg width="44" height="12" viewBox="0 0 44 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M38.9051 0.889465C38.6462 0.630595 38.2265 0.630595 37.9676 0.889465C37.7087 1.14834 37.7087 1.56805 37.9676 1.82692L41.4761 5.33537L0.936317 5.33537C0.570219 5.33537 0.273437 5.63215 0.273438 5.99825C0.273438 6.36435 0.570219 6.66113 0.936317 6.66113L41.4763 6.66113L37.9676 10.1698C37.7087 10.4286 37.7087 10.8484 37.9676 11.1072C38.2265 11.3661 38.6462 11.3661 38.9051 11.1072L43.5452 6.46707C43.8041 6.2082 43.8041 5.78849 43.5452 5.52962L38.9051 0.889465Z" fill="white" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    :
                                    <div className='INCTab_program_info_container'>
                                        <div className='INCTabprogram_info_background_holder'>
                                            <div className='INCTabprogram_info_content_holder'>
                                                <div className='INCProgram_heading INCProgram_heading_upcomming'>
                                                    <div className='INCProgram_techLogo'>
                                                        <img src='/assets/images/landingPage/INCFlutterIcon.png' alt='/' />
                                                        <img src='/assets/images/landingPage/INCDartIcon.png' alt='/' />
                                                    </div>
                                                    <div className='INCProgram_techHead'>Flutter App Development</div>
                                                </div>
                                                <div className='INCProgram_moto'>
                                                    Develop Flutter skills, UI/UX design, & app develop ment portfolio featuring real-world projects
                                                </div>
                                                <div className='INCProgram_content'>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            10+<span>Live Projects</span>
                                                        </div>
                                                    </div>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            175 <span>Assignments</span>
                                                        </div>
                                                    </div>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            50 <span>Quiz / Notes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='INCProgram_btn'>
                                                    <div className='INCProgram_btn_text_holder' onClick={() => {
                                                        navigate('/programs/flutter')
                                                        window.sessionStorage.setItem("flutterPagePos", 0)
                                                    }}>
                                                        <div className='INCBackground_hover'></div>
                                                        <div className='INCProgram_btn_text'>Explore
                                                            <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7237 1.11046C16.5296 0.916306 16.2148 0.916306 16.0206 1.11046C15.8265 1.30461 15.8265 1.61939 16.0206 1.81355L18.652 4.44489L0.747159 4.44489C0.472585 4.44489 0.25 4.66747 0.25 4.94205C0.25 5.21662 0.472585 5.43921 0.747159 5.43921L18.6521 5.4392L16.0206 8.07069C15.8265 8.26484 15.8265 8.57962 16.0206 8.77378C16.2148 8.96793 16.5296 8.96793 16.7237 8.77378L20.2038 5.29366C20.398 5.09951 20.398 4.78473 20.2038 4.59057L16.7237 1.11046Z" fill="white" />
                                                            </svg>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='INCTabprogram_info_background_holder'>
                                            <div className='INCTabprogram_info_content_holder INCTabprogram_info_content_holder_upcomming'>
                                                <div className='INCProgramtags'>Upcoming</div>
                                                <div className='INCProgram_heading INCProgram_heading_upcomming'>
                                                    <div className='INCProgram_techLogo'>
                                                        <img src='/assets/images/landingPage/reactLogo.png' alt='/' />
                                                        <img src='/assets/images/landingPage/htmlLogo.png' alt='/' />
                                                        <img src='/assets/images/landingPage/cssLogo.png' alt='/' />
                                                        <img src='/assets/images/landingPage/jsLogo.png' alt='/' />
                                                    </div>
                                                    <div className='INCProgram_techHead'>Web Development</div>
                                                </div>
                                                <div className='INCProgram_moto'>
                                                    Elevate your frontend expertise with ReactJS hands-on internship
                                                </div>
                                                <div className='INCProgram_content'>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            10+<span>Live Projects</span>
                                                        </div>
                                                    </div>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            175 <span>Assignments</span>
                                                        </div>
                                                    </div>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            50 <span>Quiz / Notes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='INCProgram_btn' onClick={() => {
                                                    dispatch(setIsForm(true))
                                                    dispatch(setIsCourseEnquiry('frontend'))
                                                }}>
                                                    <div className='INCProgram_btn_text_holder'>
                                                        <div className='INCBackground_hover'></div>
                                                        <div className='INCProgram_btn_text'>Drop Inquiry
                                                            <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7237 1.11046C16.5296 0.916306 16.2148 0.916306 16.0206 1.11046C15.8265 1.30461 15.8265 1.61939 16.0206 1.81355L18.652 4.44489L0.747159 4.44489C0.472585 4.44489 0.25 4.66747 0.25 4.94205C0.25 5.21662 0.472585 5.43921 0.747159 5.43921L18.6521 5.4392L16.0206 8.07069C15.8265 8.26484 15.8265 8.57962 16.0206 8.77378C16.2148 8.96793 16.5296 8.96793 16.7237 8.77378L20.2038 5.29366C20.398 5.09951 20.398 4.78473 20.2038 4.59057L16.7237 1.11046Z" fill="white" />
                                                            </svg>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='INCTabprogram_info_background_holder'>
                                            <div className='INCTabprogram_info_content_holder INCTabprogram_info_content_holder_upcomming'>
                                                <div className='INCProgramtags'>Upcoming</div>
                                                <div className='INCProgram_heading INCProgram_heading_upcomming'>
                                                    <div className='INCProgram_techLogo'>
                                                        <img src='/assets/images/landingPage/SpringLogo.png' alt='/' />
                                                        <img src='/assets/images/landingPage/javalogo.png' alt='/' />
                                                    </div>
                                                    <div className='INCProgram_techHead'>Backend Development</div>
                                                </div>
                                                <div className='INCProgram_moto'>
                                                    Level up your backend skills with Spring Boot in our hands-on internship
                                                </div>
                                                <div className='INCProgram_content'>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            10+<span>Live Projects</span>
                                                        </div>
                                                    </div>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            175 <span>Assignments</span>
                                                        </div>
                                                    </div>
                                                    <div className='INCContent_count_holder'>
                                                        <div className='vl'></div>
                                                        <div className='INCContent_count'>
                                                            50 <span>Quiz / Notes</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='INCProgram_btn' onClick={() => {
                                                    dispatch(setIsForm(true))
                                                    dispatch(setIsCourseEnquiry('backend'))
                                                }}>
                                                    <div className='INCProgram_btn_text_holder'>
                                                        <div className='INCBackground_hover'></div>
                                                        <div className='INCProgram_btn_text' >Drop Inquiry
                                                            <svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.7237 1.11046C16.5296 0.916306 16.2148 0.916306 16.0206 1.11046C15.8265 1.30461 15.8265 1.61939 16.0206 1.81355L18.652 4.44489L0.747159 4.44489C0.472585 4.44489 0.25 4.66747 0.25 4.94205C0.25 5.21662 0.472585 5.43921 0.747159 5.43921L18.6521 5.4392L16.0206 8.07069C15.8265 8.26484 15.8265 8.57962 16.0206 8.77378C16.2148 8.96793 16.5296 8.96793 16.7237 8.77378L20.2038 5.29366C20.398 5.09951 20.398 4.78473 20.2038 4.59057L16.7237 1.11046Z" fill="white" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>}
                        </div>
                    </div> : null}
        </div>
    )
}

export default LandingPage
