import React, { useEffect, useRef, useState } from 'react';
import "./styleFlutterFaq.css";
import { useDispatch, useSelector } from 'react-redux';
import { setObserverElement } from '../../../incubStore/actions';

function FlutterFAQ() {
    const [onIndex, setOnIndex] = useState('faq1')
    const dispatch = useDispatch();
    const resourceDivRef = useRef();
    const observerElement = useSelector((state) => state.data.observerElement);

    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp10 !== resourceDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                comp10: resourceDivRef.current.offsetTop,
            };
            dispatch(setObserverElement(updatedMap));
        }
    }, [dispatch, observerElement]);
    return (
        <div className='INCFlutterFAQ' ref={resourceDivRef}>
            <div className='INCFlutterFAQ_outer_layer'>
                <div className='INCFlutterFAQ_container'>
                    <div className='INCFlutterFAQ_heading_Container'>
                        <p>MORE INFO</p>
                        <h1>Fequently Asked Questions</h1>
                    </div>
                    <div className='INCFlutterFAQ_text_content_container'>
                        <div className='INCFlutterFAQ_text_content_section'>
                            <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq1' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        What is Flutter?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Flutter is an open-source UI software development kit created by Google for crafting natively compiled applications for mobile, web, and desktop from a single codebase.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq1' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq1") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq1");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq1");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq1' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div>
                            <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq2' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        What will I learn during the Flutter internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        You will learn the fundamentals of Dart programming language, Flutter framework, UI/UX design principles, app development best practices, and more. Our curriculum covers everything you need to know to become proficient in Flutter development.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq2' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq2") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq2");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq2");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq2' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div>
                            {/* <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq3' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        Do I need to have my own laptop for the internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Yes, you will need a laptop or desktop computer to participate in the internship. You will also need to install the Flutter SDK and necessary development tools on your machine as part of the setup process.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq3' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq3") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq3");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq3");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq3' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div> */}
                            {/* <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq4' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        Will there be opportunities for networking and collaboration with other interns?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Yes, we encourage collaboration and networking among interns. You will have the chance to interact with your peers, participate in group projects, and engage in discussions through our dedicated communication channels.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq4' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq4") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq4");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq4");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq4' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div> */}
                            <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq5' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        What are the career prospects after completing the Flutter internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Completing our Flutter internship program will equip you with the skills and knowledge needed to pursue a career as a Flutter developer or mobile app developer. You will be well-prepared to apply for entry-level positions in software development or continue your learning journey in the field.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq5' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq5") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq5");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq5");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq5' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div>

                        </div>
                        <div className='INCFlutterFAQ_text_content_section'>
                            <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq6' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        Do I need prior experience in mobile app development to join the Flutter internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        No prior experience is required. Our internship program is designed to accommodate individuals with varying levels of experience, from beginners to those with some programming background.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq6' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq6") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq6");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq6");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq6' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div>
                            {/* <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq7' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        Will I work on real projects during the internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Yes, absolutely! Our internship program emphasizes hands-on learning through real-world projects. You will have the opportunity to work on practical assignments and develop fully functional Flutter applications under the guidance of our mentors.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq7' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq7") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq7");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq7");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq7' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div> */}
                            {/* <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq8' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        What kind of support will I receive during the internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Throughout the internship, you will receive support from our team of experienced mentors who will provide guidance, answer your questions, and provide feedback on your projects. Additionally, we offer regular check-ins and office hours to address any concerns you may have.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq8' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq8") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq8");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq8");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq8' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div> */}
                            {/* <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq9' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        Is there any certification provided upon completion of the internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        Yes, upon successful completion of the internship program, you will receive a certificate acknowledging your participation and achievements. This certificate can be a valuable addition to your resume and portfolio.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq9' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq9") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq9");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq9");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq9' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div> */}
                            <div className={`INCFlutterFAQ_text_content ${onIndex === 'faq10' ? 'INCFlutterFAQ_onIndex' : null}`}>
                                <div className='INCFlutterFAQ_text_container '>
                                    <div className='INCFlutterFAQ_Q'>
                                        How do I apply for the Flutter internship?
                                    </div>
                                    <div className='INCFlutterFAQ_qdescription'>
                                        To apply for the Flutter internship, please visit our website and fill out the application form. Make sure to provide accurate information and highlight your interest in Flutter development. We will reach out to you with further instructions.
                                    </div>
                                </div>
                                <div className='INCFlutterFAQ_svg_container'>
                                    {onIndex === 'faq10' ?
                                        <svg onClick={() => {
                                            if (onIndex === "faq10") {
                                                setOnIndex(null);
                                            } else {
                                                setOnIndex("faq10");
                                            }
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.621094" width="41.5" height="41.5" rx="8" fill="url(#paint0_linear_3621_2847)" />
                                            <path d="M13.3711 19.6C12.736 19.6 12.2211 20.1149 12.2211 20.75C12.2211 21.3851 12.736 21.9 13.3711 21.9H29.3711C30.0062 21.9 30.5211 21.3851 30.5211 20.75C30.5211 20.1149 30.0062 19.6 29.3711 19.6H13.3711Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_3621_2847" x1="21.3711" y1="0" x2="21.3711" y2="41.5" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#00C2FF" />
                                                    <stop offset="1" stopColor="#0083CC" />
                                                </linearGradient>
                                            </defs>
                                        </svg> : <svg onClick={() => {
                                            setOnIndex("faq10");
                                        }} width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.53125" y="0.386719" width="41.5" height="41.5" rx="8" fill="white" fillOpacity="0.1" />
                                            <path d="M21.2812 11.9867C20.6461 11.9867 20.1312 12.5016 20.1312 13.1367V29.1367C20.1312 29.7718 20.6461 30.2867 21.2812 30.2867C21.9164 30.2867 22.4313 29.7718 22.4313 29.1367V13.1367C22.4313 12.5016 21.9164 11.9867 21.2812 11.9867Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                            <path d="M13.2812 19.9867C12.6461 19.9867 12.1312 20.5016 12.1312 21.1367C12.1312 21.7718 12.6461 22.2867 13.2813 22.2867H29.2812C29.9164 22.2867 30.4312 21.7718 30.4312 21.1367C30.4312 20.5016 29.9164 19.9867 29.2812 19.9867H13.2812Z" fill="white" stroke="white" strokeWidth="0.3" strokeLinecap="round" />
                                        </svg>
                                    }


                                </div>
                                <hr className={`INCFlutterFAQ_hr ${onIndex === 'faq10' ? 'INCFlutterFAQ_onIndex' : null}`} />
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlutterFAQ
