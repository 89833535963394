import React from 'react';
import './styleProjectDetail.css';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectData } from '../../../incubStore/actions';

function ProjectDetail() {
    const projectData = useSelector((state) => state.data.projectData)
    const dispatch = useDispatch()
    return (
        <div className='INCProjectDetail'>
            <div className='INCProjectDetail_container'>
                <div className='INCProjectDetail_holder'>
                    <svg onClick={() => dispatch(setProjectData(null))} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.5 7.5L7.5 22.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.5 7.5L22.5 22.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                    <div className='INCProjectDetail_thumbnail_holder'>
                        <img src={projectData.thumbnail} alt='project_thumbnail' />
                    </div>
                    <div className='INCProjectDetail_text_content_holder'>
                        <div className='INCProjectDetail_title_holder'>
                            <div className='INCFlutterProject_name'>{projectData.pName}</div>
                            <div className='INCFlutterProject_logo_similar'>
                                <img src={projectData.pImg1} alt='logo1' />
                                <img src={projectData.pImg2} alt='logo2' />
                            </div>
                        </div>
                        <div className='INCProjectDetail_description'>{projectData.pDescriptrion}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetail
