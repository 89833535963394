import React, { useCallback, useEffect, useRef } from 'react';
import "./styleFlutterPage.css"
import FlutterLanding from './FlutterLanding/FlutterLanding';
import FlutterResources from './FlutterResources/FlutterResources';
import FlutterFeature from './FlutterFeature/FlutterFeature';
import FlutterRoadmap from './FlutterRoadmap/FlutterRoadmap';
import FlutterSyllabus from './syllabus/FlutterSyllabus';
import FlutterPrequisite from './FlutterPrerequisite/FlutterPrequisite';
import FlutterProjects from './FlutterProjects/FlutterProjects';
import FlutterYoutube from './FlutterYoutube/FlutterYoutube';
import FlutterCertificate from './FlutterCertificate/FlutterCertificate';
import FlutterFAQ from './FlutterFaq/FlutterFAQ';
import FlutterGetInTouch from './flutterGetInTouch/FlutterGetInTouch';
import BottomNavbar from '../../components/layouts/BottomNavbar';
import { useDispatch, useSelector } from 'react-redux';
import { setCourseScrollPos, setFlutterScrollTo, setLocation, setMainCourseScrollPos, setScrollPos } from '../../incubStore/actions';
import debounce from 'lodash.debounce';
function FlutterPage() {
    const dispatch = useDispatch();
    const location = useSelector((state) => state.data.location);
    const scrollableDivRef = useRef(null);
    const flutterScrollTo = useSelector((state) => state.data.flutterScrollTo);
    const flutterScrollDivRef = useSelector((state) => state.data.flutterScrollDivRef);
    const courseScrollPos = useSelector((state) => state.data.courseScrollPos);
    const observerElement = useSelector((state) => state.data.observerElement)


    useEffect(() => {
        dispatch(setLocation('/programs/flutter'));
        if (scrollableDivRef) {
            scrollableDivRef.current.scrollTop = window.sessionStorage.getItem('flutterPagePos');
        }
    }, [location]);

    useEffect(() => {

        if (flutterScrollDivRef && flutterScrollDivRef.scrollDirection > 0) {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollTop + 60;

        } else {
            scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollTop + -60;
        }
        if (courseScrollPos) {
            dispatch(setCourseScrollPos(null))
        }

    }, [flutterScrollDivRef && flutterScrollDivRef.scrollPos])


    // Function to handle scroll and update redux state
    const handleScroll = () => {
        const scrollTop = scrollableDivRef.current.scrollTop;
        dispatch(setScrollPos(scrollTop));
        dispatch(setFlutterScrollTo(scrollableDivRef.current.scrollTop))
        if (courseScrollPos) {
            dispatch(setCourseScrollPos(null))
        }
        if (scrollTop < 800) {
            dispatch(setMainCourseScrollPos("box1"))
        }
    };



    // Function to smoothly scroll to the specified position
    const scrollToPosition = () => {
        if (scrollableDivRef.current && courseScrollPos !== null) {
            scrollableDivRef.current.scrollTo({
                top: courseScrollPos,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        // Scroll to position when flutterScrollTo changes
        if (courseScrollPos !== null) {
            scrollToPosition();
        }
    }, [courseScrollPos]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            sessionStorage.setItem('is_refreshed', true);

        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        if (sessionStorage.getItem('is_refreshed')) {
            console.log('Page was refreshed');
            window.sessionStorage.setItem('flutterPagePos', 0);
            sessionStorage.removeItem('is_refreshed')
            scrollableDivRef.current.scrollTop = 0;
        }

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        window.sessionStorage.setItem('flutterPagePos', scrollableDivRef.current.scrollTop)
    }, [scrollableDivRef && scrollableDivRef.current && scrollableDivRef.current.scrollTop])
    return (
        <div className='INCFlutterPage' ref={scrollableDivRef} onScroll={handleScroll} >
            <FlutterLanding />
            <FlutterResources />
            <FlutterFeature />
            <FlutterRoadmap />
            <FlutterSyllabus />
            <FlutterPrequisite />
            <FlutterProjects />
            <FlutterYoutube />
            <FlutterCertificate />
            <FlutterFAQ />
            <FlutterGetInTouch />
            <BottomNavbar />
        </div>
    );
}

export default FlutterPage;







