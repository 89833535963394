import React from 'react'
import "./styleEventGallary.css"
function EventGallary() {
    return (
        <div className='INCEventGallary'>
            <div className='INCEventGallary_container'>
                <div className='INCEventGallary_images_container'>
                    <div className='INCEventGallary_images_img1_container'>
                        <div className='INCEventGallary_images_faint_background1'></div>
                        <img className='INCEventGallary_images_img' src='/assets/images/about/eventGallary/image1.png' alt='/' />
                    </div>
                    <div className='INCEventGallary_images_img2_container'>
                        <div className='INCEventGallary_images_faint_background2'></div>
                        <img className='INCEventGallary_images_img' src='/assets/images/about/eventGallary/image2.png' alt='/' />
                    </div>
                    <div className='INCEventGallary_images_img3_container'>
                        <div className='INCEventGallary_images_faint_background3'></div>
                        <img className='INCEventGallary_images_img' src='/assets/images/about/eventGallary/image3.png' alt='/' />
                    </div>
                    <div className='INCEventGallary_images_img4_container'>
                        <div className='INCEventGallary_images_faint_background4'></div>
                        <img className='INCEventGallary_images_img' src='/assets/images/about/eventGallary/image4.png' alt='/' />
                    </div>
                    <div className='INCEventGallary_images_img5_container'>
                        <div className='INCEventGallary_images_faint_background5'></div>
                        <img className='INCEventGallary_images_img' src='/assets/images/about/eventGallary/image5.png' alt='/' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventGallary
