import React from 'react'
import { useSelector } from 'react-redux';
function Feature4() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky);
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                    </div>

                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box feature4'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#F38A3F" />
                            </svg>


                        </div>
                    </div>

                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container'>
                            <div className='INCFeature_text_content feature2'>
                                <div className='INCFeature_text_content_heading'><span>One-to-One <br /></span>Mentorship</div>
                                <div className='INCFeatureList_litext_feature2'>
                                    Get personalized guidance through our one-to-one mentorship program. Our dedicated mentors, industry professionals themselves, provide tailored support and feedback to ensure your success throughout the internship.
                                </div>
                            </div>
                            <div className='INCFeature_asset_container '>
                                <img src='/assets/images/features/Gif 1 Final.gif' alt='feature1'></img>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature4
