import React from 'react'
import { useSelector } from 'react-redux'
function Feature2() {
    const isActiveSticky = useSelector((state) => state.data.isActiveSticky)
    return (
        <div className='INCFeatures INCFeature1' style={{ position: !isActiveSticky ? "sticky" : "relative" }}>
            <div className='INCFeatures_container'>
                <div className='INCFeature_heading'>
                    <div className='INCFeature_heading_container'>
                    </div>

                </div>
                <div className='INCFeature_container'>
                    <div className='INCFeature_dot_container'>
                        <div className='INCFeature_dot_box feature2'>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="6" cy="6" r="6" fill="#DA62AC" />
                            </svg>

                        </div>
                    </div>

                    <div className='INCFeature_info_container'>
                        <div className='INCFeature_content_container'>
                            <div className='INCFeature_text_content feature2'>
                                <div className='INCFeature_text_content_heading'><span>No prerequisite</span> followed by No prior development knowledge</div>
                                <div className='INCFeatureList_litext_feature2'>
                                    Join our inclusive internship program—Designed for anyone who dare to think out-of-the-box/wish to build development career. Dive into Flutter, Spring Boot, and ReactJS with expert guidance and real projects to unlock your potential in software development.
                                </div>
                            </div>
                            <div className='INCFeature_asset_container feature2'>
                                {/* <img className='INC_girl_animate_img' src='/assets/images/features/feature2 asset.png' alt='feature1'></img> */}
                                <div className='INCFeatures_animated_girlImg_holder'>
                                    <img className='INC_girl_img1' src='/assets/images/features/No Academics Background Restriction.png' alt='feature1' />
                                    <img className='INC_girl_img2' src='/assets/images/features/No Academics Background Restriction2.png' alt='feature1' />
                                    <img className='INC_girl_img3' src='/assets/images/features/No Academics Background Restriction1.png' alt='feature1' />
                                    <img className='INC_girl_img4' src='/assets/images/features/No Academics Background Restriction4.png' alt='feature1' />
                                    <img className='INC_girl_img5' src='/assets/images/features/No Academics Background Restriction5.png' alt='feature1' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feature2
