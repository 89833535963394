// actions.js

import { type } from "@testing-library/user-event/dist/type";
import { ISCOURSENAVCOLOR, ISFORM, ISNAVCOLOR, LOCATION, SCROLLPOS, SETCOURSESCROLLPOS, SETFLUTTERSCROLLDIV, SETFLUTTERSCROLLTO, SETHOMEPAGEPOS, SETISACTIVESTICKY, SETISCOURSEENQUIRY, SETISSTICKYSCROLL, SETMAINCOURSESCROLL, SETOBSERVERELEMENT, SETPROJECTDATA } from "./actionTypes";

export const setScrollPos = (pos) => ({
  type: SCROLLPOS,
  payload: pos,
})

export const setIsForm = (data) => ({
  type: ISFORM,
  payload: data,
})
export const setIsNavColor = (data) => ({
  type: ISNAVCOLOR,
  payload: data,
})

export const setLocation = (data) => ({
  type: LOCATION,
  payload: data,
})
export const setIsCourseNavColor = (data) => ({
  type: ISCOURSENAVCOLOR,
  payload: data,
})

export const setProjectData = (data) => ({
  type: SETPROJECTDATA,
  payload: data,
})
export const setIsCourseEnquiry = (data) => ({
  type: SETISCOURSEENQUIRY,
  payload: data,
})
export const setObserverElement = (data) => ({
  type: SETOBSERVERELEMENT,
  payload: data,
})
export const setFlutterScrollTo = (data) => ({
  type: SETFLUTTERSCROLLTO,
  payload: data,
})


export const setIsStickyScroll = (data) => ({
  type: SETISSTICKYSCROLL,
  payload: data,
})

export const setIsActiveSticky = (data) => ({
  type: SETISACTIVESTICKY,
  payload: data,
})

export const setHomePagePos = (data) => ({
  type: SETHOMEPAGEPOS,
  payload: data,
})
export const setFlutterScrollDiv = (data) => ({
  type: SETFLUTTERSCROLLDIV,
  payload: data,
})
export const setCourseScrollPos = (data) => ({
  type: SETCOURSESCROLLPOS,
  payload: data
})
export const setMainCourseScrollPos = (data) => ({
  type: SETMAINCOURSESCROLL,
  payload: data
})
