import React from 'react';
import ScrollFramer from './ScrollFramer';
function CampusTour() {

    return (
        <div className='INCCampusTour'>
            <div className='INCCampusTour_container'>
                <div className='INCCampusTour_heading_container'>Life at
                    <span>  Incubators!</span></div>
                <div className='INCCampusTour_swiper_image_container'>
                    <ScrollFramer />

                </div>
            </div>
        </div>
    )
}

export default CampusTour
