import React, { useEffect, useRef } from 'react'
import "./styleFlutterRoadmap.css"
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setObserverElement } from '../../../incubStore/actions';
function FlutterRoadmap() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const resourceDivRef = useRef();
    const observerElement = useSelector((state) => state.data.observerElement);

    useEffect(() => {
        // Only dispatch the action on component mount
        // or when specific conditions change
        if (observerElement.comp4 !== resourceDivRef.current.offsetTop) {
            const updatedMap = {
                ...observerElement,
                comp4: resourceDivRef.current.offsetTop,
            };
            dispatch(setObserverElement(updatedMap));
        }
    }, [dispatch, observerElement]);
    return (
        <div className='INCFlutterRoadmap' ref={resourceDivRef}>
            <div className='INCFlutterRoadmap_container'>
                <div className='INCFlutterRoadmap_part_holder'>
                    <div className='INCFlutterRoadmap_part part1'>
                        <img src='/assets/images/flutter/flutterRoadmap/Objects.png' alt='roadmap-asset' />
                    </div>
                    <div className='INCFlutterRoadmap_part part2'>
                        <div className='INCFlutterRoadmap_heading_container' >
                            <p>Roadmap</p>
                            <h1>Participants are guided through a <span>Roadmap</span> created to succeed in coding</h1>
                            <button onClick={() => navigate("/programs/flutter/roadmap")} className='INCRoadmap_button'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 6V22L8 18L16 22L23 18V2L16 6L8 2L1 6Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 2V18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M16 6V22" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg> &nbsp; &nbsp; Get Roadmap</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FlutterRoadmap
