import React, { useEffect, useState } from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import "./styleAbout.css"
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from '../../incubStore/actions';
import PlacementFeature1 from '../placementpage/placementFeature/PlacementFeature1';
import PlacementFeature2 from '../placementpage/placementFeature/PlacementFeature2';
import PlacementFeature3 from '../placementpage/placementFeature/PlacementFeature3';
import PlacementFeature4 from '../placementpage/placementFeature/PlacementFeature4';
import PlacementFeature5 from '../placementpage/placementFeature/PlacementFeature5';
import PlacementFeature7 from '../placementpage/placementFeature/PlacementFeature7';
import PlacementFeature8 from '../placementpage/placementFeature/PlacementFeature6';
import BottomNavbar from '../../components/layouts/BottomNavbar';
import PlacementLandingPage from '../placementpage/PlacementLandingPage.js/PlacementLandingPage';
import WhoWeAre from './whoweare/WhoWeAre';
import WhatIsIncubator from './whatisincubator/WhatIsIncubator';
import CampusTour from './campustour/CampusTour';
import TeamInfo from './teamInfo/TeamInfo';
import EventGallary from './eventGallary/EventGallary';
import AboutusLandingPage from './aboutLanding/AboutusLandingPage';

gsap.registerPlugin(ScrollTrigger);

function AboutLanding() {
    const component = useRef();
    const slider = useRef();
    const [activePanel, setActivePanel] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLocation("/about"))
    }, [])
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useLayoutEffect(() => {
        if (windowWidth < 1025) {
            return
        }
        console.log(windowWidth > 1440)
        console.log(windowWidth > 1440 ? 115 : 150)
        let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".AboutPanel");
            gsap.to(panels, {
                xPercent: -(((panels[0].clientWidth / window.innerWidth) * 100)) + (windowWidth > 1537 ? 115 : 105),
                ease: "none",
                animation: "none",
                scrollTrigger: {
                    trigger: slider.current,
                    pin: true,
                    scrub: 0,
                    // snap: 1 / (panels.length - 1),
                    end: () => "+=" + (panels[0].offsetWidth / 2),
                    markers: false,
                    toggleClass: "stick",
                    onUpdate: (self) => {
                        let activeIndex = Math.round(self.progress * (panels.length - 1));
                        setActivePanel(activeIndex)
                    }
                }
            });

        }, component);
        return () => ctx.revert();
    }, []);
    // className = 'Aboutline'
    return (
        <div className="INCAboutPage">
            <div className="INCPlacementPage_scroll_container">
                <div className="INCPlacementPage_main_container" ref={component}>
                    <div ref={slider} className="container INCAbout_container">
                        <div className='INCLanding_heading'>
                            <img className='img1' src='/assets/images/about/landingPage/incubatorsText.png' alt='incubators text' />

                        </div>
                        <div className="description AboutPanel"><AboutusLandingPage /></div>
                        {/* <div className="AboutPanel"><PlacementFeature2 /></div> */}
                    </div>
                    {/* <div className="lastContainer">Last Container</div> */}
                    <WhoWeAre />
                    <WhatIsIncubator />
                    <CampusTour />
                    <TeamInfo />
                    <EventGallary />
                    <BottomNavbar />
                </div>
            </div>
        </div>
    );


}

export default AboutLanding;
