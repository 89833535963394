import React from 'react';
import './styleLoadingNavbar.css'

function LoadingNavbar() {
    return (
        <div className='INC_loadbackground'>
            <div className='INC_blurBack_container'></div>
            <div className='incub_navbar_load'>
                <div className='inc_navbar_container_load'>
                    <div className='inc_navbar_logo_load'>
                        {/* <img src='/assets/images/landingPage/incubatorNavLogo.png' alt='/' /> */}
                        <img className='inc_navbar_logo_img' src='/assets/images/landingPage/inc_logo.png' alt='/' onClick={() => {

                            window.location.href = "/";
                        }} />
                    </div>
                    <div className='inc_navbar_menu_load'>
                        <ul className="inc_navbar_items" type='none'>
                            <li>Placement</li>
                            <li >About Us</li>
                        </ul>
                    </div>
                    <div className='inc_navbar_menu_load'>
                        <div className='INCGetintouch_btn'>
                            Get in Touch
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.00391 11.3333L11.3372 3" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M3.00391 3H11.3372V11.3333" stroke="white" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingNavbar
